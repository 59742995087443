import { RenderHTML } from '@dmm/react-common-components';
import classnames from 'classnames';
import React, { memo, useEffect, useRef, useState } from 'react';
import { getRenderedHeight, isMobileResolution } from '../../../../utils/commonHelper';
import './styles.css';

const DataHTML = ({ data, isMultiItem = false, widthSelector, setCollapseContentTracking}) => {
  const containerRef = useRef(null);
  const [showMoreToggle, setShowMoreToggle] = useState(false);
  const [renderedHeight, setRenderedHeight] = useState(0);

  useEffect(() => {
    if (!isMultiItem && widthSelector) {
      setRenderedHeight(getRenderedHeight(data, widthSelector));
    }
  }, []);

  const handleShowMoreToggle = (value) => {
    setShowMoreToggle(value);

    if (showMoreToggle) {
      const titleOffset = isMobileResolution() ? 120 : 80;
      window.scrollTo({
        top: containerRef.current.offsetTop - titleOffset,
        left: 0,
        behavior: 'smooth'
      });
    }
  };

  return (
    <div className="data-html" data-e2e="data-html" ref={containerRef} >
      {isMultiItem
        ? data.map((prop, index) => (
          <div key={index}>
            {prop.title && <h4>{prop.title}</h4>}
            <RenderHTML html={prop.value} />
          </div>
        ))
        : (
          <div className={classnames(
            'data-html-inner-wrapper',
            { 'show-more': showMoreToggle }
          )} data-e2e={classnames(
            'data-html-inner-wrapper',
            { 'show-more': showMoreToggle }
          )}>
            <RenderHTML html={data} />
            {renderedHeight > 170 && (
              <div className="show-more-less-interaction" data-e2e="show-more-less-interaction">
                <button data-e2e="show-more-less" onClick={(e) => {
                  e.stopPropagation();
                  handleShowMoreToggle((prev) => !prev);
                  setCollapseContentTracking({
                    content: 'boat-description',
                    action: showMoreToggle ? 'close' : 'open',
                    label: 'show ' + (showMoreToggle ? 'less' : 'more')
                  });
                }}>
                  {!showMoreToggle ? 'Show More' : 'Show Less'}
                </button>
              </div>
            )}
          </div>
        )
      }
    </div>
  );
};

export default memo(DataHTML);
