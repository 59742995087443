import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import ServicesItem from '../../../components/ServicesItem';
import { Collapsible, CollapsibleContent, CollapsibleHeader } from '../../../components/Collapsible';
import { setCollapseContentTracking } from '../../../store/actions/dataLayer';
import ServerAd from '../../../components/ServerAd';
import {
  DOCUMENTATION_SERVICE,
  YACHTCLOSER_PROVIDER
} from '../../../constants/services';

import './styles.css';


const Services = ({serverAds}) => {
  const dispatch = useDispatch();
  const getIsOpen = (key) => openStates[key] ? 'close' : 'open';
  const toggleOpenState = (key) => {
    setOpenStates((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };
  const [openStates, setOpenStates] = useState({
    'services': true
  });
  const collapseTracking = (content) => {
    return {
      content,
      action: getIsOpen(content),
      label: ('accordion ' + (getIsOpen(content)))
    };
  };

  return (
    <CollapsibleContent initialState="open" classOverride="details services-collapsible">
      <CollapsibleHeader onClick={() => {
        dispatch(setCollapseContentTracking(collapseTracking('services')));
        toggleOpenState('services');
      }}>
        Other Services
      </CollapsibleHeader>
      <Collapsible type="no-pad">
        <div className="services">
          {serverAds && <>
            <ServerAd ad={serverAds['boat-details_service-button-1']} className={'service-item'} />
            <ServerAd ad={serverAds['boat-details_service-button-2']} className={'service-item'} />
          </>}
          <ServicesItem
            link="/services/boat-documentation/"
            serviceTitle="Boat Documentation"
            serviceIcon="documentation"
            serviceType={DOCUMENTATION_SERVICE}
            serviceName={YACHTCLOSER_PROVIDER}
          />
          {serverAds && <>
            <ServerAd ad={serverAds['boat-details_service-button-3']} className={'service-item'}/>
            <ServerAd ad={serverAds['boat-details_service-button-4']} className={'service-item'}/>
            <ServerAd ad={serverAds['boat-details_service-button-5']} className={'service-item'}/>
          </>}
        </div>
      </Collapsible>
    </CollapsibleContent>
  );
};
export default Services;
