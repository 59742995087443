import React, { useState, useEffect, useMemo } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
  formatPrice,
  formatPriceWithDecimal,
} from '@dmm/lib-common/lib/formatting';
import {
  CALCULATOR_TRIDENT_API_URL,
  MIN_PRICE_TO_FINANCE,
  RATES_CALCULATOR_SMALL_PRINT,
} from '../../../../constants/BoatLoans';
import './styles.css';
import { ApplyNowButton } from '../ApplyNowButton';

export const calculateTridentAPI = async (
  loanType,
  loanAmount,
  boatYear,
  ficoRange
) => {
  return await axios.get(
    `${CALCULATOR_TRIDENT_API_URL}/?loanType=${loanType}&loanAmount=${loanAmount}&modelYear=${boatYear}&ficoScoreRange=${ficoRange}`
  );
};

const maxBoatYear = new Date().getFullYear() + 1;

export const Calculator = ({ dataTestId = 'boat-loan-rates-calculator-component' }) => {
  const now = new Date();
  const [calculator, setCalculator] = useState({
    formFields: {
      loanAmount: '$25,000',
      loanType: 'new',
      boatYear: now.getFullYear().toString(),
      ficoScore: '800-850',
    },
    result: {
      loanAmount: '$0',
      interestRate: '0%',
      termLength: '0',
      monthlyPayment: '$0',
    },
  });

  const [apiStatus, setApiStatus] = useState('completed');
  const [loanTypeName, setLoanTypeName] = useState('New Boat Loan');
  const [badInputAmount, setBadInputAmount] = useState(false);
  const [isCallRate, setIsCallRate] = useState(false);
  const [timeoutID, setTimeoutID] = useState();

  const minBoatYear = maxBoatYear - 20;

  const setLoanTerms = (loanTerms) => {
    const {monthlyPayment, term, rate, phone, minLoanAmount, maxLoanAmount, minModelYear, typeError } = loanTerms.data;
    const userMustCallForRate = loanTerms.data?.monthlyPayment.toLowerCase() === 'call';
    setCalculator({
      ...calculator,
      result: {
        loanAmount,
        interestRate: rate,
        termLength: userMustCallForRate ? 'Call' : parseInt(term),
        monthlyPayment: userMustCallForRate ? 'Call' : formatPriceWithDecimal(monthlyPayment.replace(/[^0-9.]/g, ''), 'USD', 'en-US', 2),
        phoneToCall: phone,
        minLoanAmount,
        maxLoanAmount,
        minModelYear,
        typeError
      },
    });
    setIsCallRate(userMustCallForRate);
  };

  const calculateResult = async () => {
    const { loanType, loanAmount, boatYear, ficoScore } = calculator.formFields;
    if (isValidForm()) {
      setApiStatus('pending');
      try {
        const loanTerms = await calculateTridentAPI(loanType, loanAmount.replace(/[^0-9.]/g, ''), boatYear, ficoScore);
        setLoanTerms(loanTerms);
        setApiStatus('completed');
      }
      catch (err) {
        // eslint-disable-next-line no-console
        console.error('err', err);
        setApiStatus('failed');
      }
    }
  };

  useEffect(() => {
    calculateResult();
  }, [  // eslint-disable-line react-hooks/exhaustive-deps
    calculator.formFields.loanType,
    calculator.formFields.boatYear,
    calculator.formFields.ficoScore
  ]);

  useEffect(() => {
    clearTimeout(timeoutID);
    if (apiStatus !== 'pending'){
      setTimeoutID(setTimeout(calculateResult, 1000));
    }
  }, [calculator.formFields.loanAmount]); // eslint-disable-line react-hooks/exhaustive-deps

  const isValidForm = () => {
    const {
      formFields: { loanAmount, loanType, boatYear, ficoScore },
    } = calculator;

    const loanAmountInt = parseInt(loanAmount.replace(/[^0-9.]/g, ''));
    const isValidLoanAmount =
      loanAmount && !isNaN(loanAmountInt)
        ? loanAmountInt >= MIN_PRICE_TO_FINANCE && loanAmountInt <= 10000000
        : false;
    const isValidBoatYear =
      boatYear && boatYear >= minBoatYear && boatYear <= maxBoatYear + 1;
    const isValidLoanType =
      loanType && ['new', 'used', 'refinance'].includes(loanType);
    const isValidFicoScore =
      ficoScore &&
      [
        '800-850',
        '760-799',
        '740-759',
        '700-739',
        '680-699',
        '640-679',
        '600-639',
      ].includes(ficoScore);

    return (
      isValidLoanAmount &&
      isValidLoanType &&
      isValidBoatYear &&
      isValidFicoScore
    );
  };

  const setCalculatorFormValue = (
    name,
    value,
    preProcess,
    loanAmount = false
  ) => {
    setCalculator({
      ...calculator,
      formFields: {
        ...calculator.formFields,
        [name]: loanAmount
          ? formatPrice(value.replace(/[^0-9.]/g, ''), 'USD', 'en-US')
          : preProcess
            ? value.replace(/[^0-9.]/g, '')
            : value,
      },
    });
  };

  const handleInputChange = async (event) => {
    const { name, value, selectedIndex } = event.target;

    if (name === 'loanAmount') {
      const rawAmount = parseInt(value.replace(/[^0-9.]/g, ''));
      setBadInputAmount(rawAmount < MIN_PRICE_TO_FINANCE || rawAmount > 10000000);
      setCalculatorFormValue(name, value, true, true);
    } else if (name === 'boatYear') {
      setCalculatorFormValue(name, value, true);
    } else {
      setCalculatorFormValue(name, value, false);
    }
    if (name === 'loanType'){
      setLoanTypeName(event.target[selectedIndex].text);
    }
  };

  const {
    formFields: { loanAmount: loanAmountField, boatYear, loanType, ficoScore },
    result: {
      loanAmount,
      interestRate,
      termLength,
      monthlyPayment,
      phoneToCall,
      minLoanAmount,
      maxLoanAmount,
      minModelYear,
      typeError
    },
  } = calculator;

  return (
    <div className="rates-calculator" data-testid={dataTestId}>
      <div className="calculator-body">
        <CalculatorForm
          handleInputChange={handleInputChange}
          loanAmount={loanAmountField}
          loanType={loanType}
          boatYear={boatYear}
          ficoScore={ficoScore}
          badInputAmount={badInputAmount}
          apiStatus={apiStatus}
        />
        <CalculatorResult
          loanAmount={loanAmountField}
          loanType={loanTypeName}
          interestRate={interestRate}
          termLength={termLength}
          monthlyPayment={monthlyPayment}
          apiStatus={apiStatus}
          isCallRate={isCallRate}
          phoneToCall={phoneToCall}
          minLoanAmount={minLoanAmount}
          maxLoanAmount={maxLoanAmount}
          minModelYear={minModelYear}
          typeError={typeError}
        />
      </div>
    </div>
  );
};

const CalculatorForm = ({
  handleInputChange,
  loanAmount,
  loanType,
  boatYear,
  ficoScore,
  badInputAmount,
}) => {
  const lastTwentyYears = useMemo(() => {
    let currentYear = maxBoatYear;
    const options = [<option key="Select Year" value="none" hidden>Select Year</option>];

    for (let i = 0; i <= 20; i++) {
      options.push(<option key={currentYear} value={currentYear}>{currentYear}</option>);
      currentYear -= 1;
    }
    return options;
  }, []);

  return (
    <div className="calculator-form">
      <div className="calculator-form-title">Boat Loan Rate Estimator</div>
      <div className="calculator-form-subtitle">Estimate your monthly payment based on the boat you want to buy and your specific loan need.</div>
      <form>
        <fieldset className="form-fieldset">
          <label className="calc-form-label" htmlFor="loan-amount">Loan Amount</label>
          <input
            className="form-data input"
            type="text"
            id="loan-amount"
            name="loanAmount"
            placeholder="Min. $10,000 - Max. $2,000,000"
            onChange={handleInputChange}
            value={loanAmount}
            data-testid="loan-amount-form"
          />
          <div
            className={classnames('free-text-input-error', {
              hide: !badInputAmount,
            })}
            data-testid="amount-input-error"
          >
            * $10,000 - $10,000,000
          </div>
          <label className="calc-form-label" htmlFor="loan-type">Loan Type</label>
          <select
            className="form-data dropdown"
            id="loan-type"
            name="loanType"
            data-testid="loan-type-form"
            onChange={handleInputChange}
            value={loanType}
          >
            <option value="none" hidden>Select Type</option>
            <option value="new">New Boat Loan</option>
            <option value="used">Used Boat Loan</option>
            <option value="refinance">Refinance</option>
          </select>
          <label className="calc-form-label" htmlFor="boat-year">Boat Year</label>
          <select
            className="form-data dropdown"
            id="boat-year"
            name="boatYear"
            data-testid="boat-year-form"
            onChange={handleInputChange}
            value={boatYear}
          >
            {lastTwentyYears}
          </select>
          <label className="calc-form-label" htmlFor="fico-score">Credit Score (FICO)</label>
          <select
            className="form-data dropdown"
            id="fico-score"
            name="ficoScore"
            data-testid="fico-score-form"
            onChange={handleInputChange}
            value={ficoScore}
          >
            <option value="none" hidden>Select Score</option>
            <option value="800-850">800-850</option>
            <option value="760-799">760-799</option>
            <option value="740-759">740-759</option>
            <option value="700-739">700-739</option>
            <option value="680-699">680-699</option>
            <option value="640-679">640-679</option>
            <option value="600-639">600-639</option>
          </select>
        </fieldset>
      </form>
    </div>
  );
};

CalculatorForm.propTypes = {
  handleInputChange: PropTypes.func.isRequired,
  loanAmount: PropTypes.string.isRequired,
  loanType: PropTypes.string.isRequired,
  boatYear: PropTypes.string.isRequired,
  ficoScore: PropTypes.string.isRequired,
  badInputAmount: PropTypes.bool.isRequired,
  apiStatus: PropTypes.string,
};

const CalculatorResult = ({
  loanAmount,
  loanType,
  interestRate,
  termLength,
  monthlyPayment,
  apiStatus,
  phoneToCall,
  minLoanAmount,
  maxLoanAmount,
  minModelYear,
  typeError
}) => {

  let useCase;
  let boundaryAmount;

  if (typeError){
    if (typeError.includes('loan amount')) {
      const loanAmountFloat = parseFloat(loanAmount.replace(/[^0-9.]/g, ''));
      useCase = `${loanAmountFloat < minLoanAmount ? 'minimum' : 'maximum'} Loan Amount`;
      boundaryAmount = formatPriceWithDecimal(loanAmountFloat < minLoanAmount ? minLoanAmount : maxLoanAmount, 'USD', 'en-US', 2);
    } else {
      useCase = 'minimum Model Year';
      boundaryAmount = minModelYear;
    }
  }

  return (
    <div className="calculator-summary">
      <div className="summary-wrapper">
        { termLength !== 'Call' &&
          <div className="summary-data">
            <div className="summary-data-title">Here is Your Estimated Rate</div>
            <div className="summary-data-monthly-payment" data-testid="monthly-payment">{monthlyPayment}<span className="month-label">/ mo</span></div>
            <div className="summary-data-apr" data-testid="interest-rate">{`${interestRate.replace(/†/, '')}`}</div>
          </div>
        }
        { termLength === 'Call' &&
          <div className="summary-data">
            <div className="summary-data-title">{!typeError ? 'Here is Your Estimated Rate' : 'Uh Oh!'}</div>
            {phoneToCall && <div className="summary-data-monthly-call" data-testid="monthly-payment">{monthlyPayment}</div>}
            <div className="summary-data-call-message" data-testid="call-message">
              {
                !typeError ?
                  <>Please <a className="contactus-label" href={`tel:${phoneToCall}`}>call us</a> at {phoneToCall} for more information.</> :
                  <>The {useCase} for {loanType} is {boundaryAmount}</>
              }
            </div>
          </div>
        }
        <div className="summary-apply-button">
          <ApplyNowButton
            text="Get Pre-Qualified"
            type="button"
            utmCampaign="bt-rates-calc-pg"
            utmContent="bt-rates-calc-pg"
            trackingEvent={{
              'action_label': 'boat loan rate estimator'
            }}
          />
        </div>
        <div className="summary-small-print">{RATES_CALCULATOR_SMALL_PRINT}</div>
      </div>
      <div
        className={classnames('api-call-status', {
          hide: apiStatus !== 'failed',
        })}
        data-testid="api-status"
      >
        {apiStatus !== 'failed'
          ? apiStatus
          : '** Something went wrong. Please try again.'}
      </div>
    </div>
  );
};

CalculatorResult.propTypes = {
  loanAmount: PropTypes.string.isRequired,
  interestRate: PropTypes.string.isRequired,
  termLength: PropTypes.string.isRequired,
  monthlyPayment: PropTypes.string.isRequired,
  apiStatus: PropTypes.string,
  isCallRate: PropTypes.bool,
  phoneToCall: PropTypes.string,
  loanType: PropTypes.string,
  minLoanAmount: PropTypes.number,
  maxLoanAmount: PropTypes.number,
  minModelYear: PropTypes.number,
  typeError: PropTypes.string,
};
