import React, { useEffect } from 'react';
import { User, Amplify } from '@dmm/lib-react-userflow-components';
import DataLayer from '../../components/DataLayer';
import { redirectAuthenticatedUser } from './utils/redirectAuthenticatedUser';
import DefaultLayout from '../../layouts/Default';
import '@dmm/lib-react-userflow-components/dist/cjs/index.css';
import './styles.css';

const authConfig = {
  region: process.env.REACT_APP_COGNITO_CONFIG_REGION,
  userPoolId: process.env.REACT_APP_COGNITO_CONFIG_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_COGNITO_CONFIG_USER_POOL_CLIENT_ID,
  oauth: {
    domain: process.env.REACT_APP_COGNITO_CONFIG_OAUTH_DOMAIN,
    redirectSignIn: process.env.REACT_APP_COGNITO_CONFIG_OAUTH_REDIRECT_SIGNIN,
    redirectSignOut: process.env.REACT_APP_COGNITO_CONFIG_OAUTH_REDIRECT_SIGNIN
  },
  cookieStorage: {
    domain: process.env.REACT_APP_COGNITO_CONFIG_COOKIE_STORAGE_DOMAIN,
    path: '/',
    expires: 365,
    sameSite: 'strict',
    secure: true
  }
};

const options = {
  defaultView: {
    autoOpen: true,
    title: 'Welcome!',
    signup: true,
    shouldRedirectToHomePage: true
  },
  navBarMenu: {
    login: false,
    signup: false
  },
  close: {
    button: false,
    keyboard: false
  },
  socialAuthentication: {
    apple: true,
    google: true
  }
};

const LoginLanding = (props) => {
  useEffect(async () => { // eslint-disable-line react-hooks/exhaustive-deps
    const isAuthenticated = await Amplify.getCurrentSession();
    redirectAuthenticatedUser(isAuthenticated);
  }, []);

  return (
    <DefaultLayout {...props} pageType="login-landing" hideAuth={true} noIndex={true}>
      <div className="login-landing-container">
        <div id="modal-root"></div>
        <DataLayer pageType="login-landing" />
        <User
          cognitoConfig={authConfig}
          options={options}
        />
      </div>
    </DefaultLayout>
  );
};

export default LoginLanding;
