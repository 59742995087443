
import { setSessionItem, removeSessionItem} from '../storage';
import { buyerAccountLoginExperiment, isUserInLoginExperiment } from './experiments';

const CONTACT_SELLER_SESSION = 'fsbo.contactSellerSession';

/**
   * Opens the login modal and temporarily stores the contact form values in session storage.
   * This ensures that the user's input is retained and can be retrieved after authentication.
   * This is necessary for the "Message Before Login" experiment.
   * See: https://boats-group.atlassian.net/browse/PS-1927
 */
export const openLoginModalAndStoreContactSession = (userRef, contactSellerSession) => {
  const { name, email, phone, zip, message } = contactSellerSession;

  userRef?.current?.onOpenModal();
  setSessionItem(CONTACT_SELLER_SESSION, JSON.stringify({
    name,
    email,
    phone,
    zip,
    message
  }));
};

export const isOpenModalBeforeContact = (abTestConfiguration, listing, inExperimentAndLoggedIn) => {
  const isMessageBeforeLoginExperiment = buyerAccountLoginExperiment.isMessageBeforeLoginExperiment(abTestConfiguration, listing);
  const isUserLoginExperiment = isUserInLoginExperiment(abTestConfiguration, listing);
  return isMessageBeforeLoginExperiment && isUserLoginExperiment && !inExperimentAndLoggedIn;
};

export const isOpenLoginModal = (userRef, contactSellerSession, handleClose, openLoginModalBeforeContact) => {
  if (openLoginModalBeforeContact) {
    openLoginModalAndStoreContactSession(userRef, contactSellerSession);
    handleClose();
    return true;
  }

  removeSessionItem(CONTACT_SELLER_SESSION);
  return false;
};


