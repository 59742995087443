import React, { useState } from 'react';
import classnames from 'classnames';
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';
import { formatNumber } from '@dmm/lib-common/lib/formatting';
import { ToolSetTextInput } from '../../ToolSet';
import {compareNormalizations, typeAheadMatchesValue} from '../../../utils/typeAheadHelper';
import {nil} from '../../../utils/runOnceHelper';

const FilterTypeAhead = (props) => {
  const [typeAheadText, setTypeAheadText] = useState('');
  const [showAll, setShowAll] = useState(props.showAll || false);

  const handleTypeAhead = (value) => {
    setTypeAheadText(value);
  };
  const handleShowAll = () => {
    setShowAll(true);
  };
  const handleSelection = () => {
    setTypeAheadText('');
  };

  const onClickFilterMore = (e) => {
    handleShowAll();
    e.preventDefault();
  };

  const { items = [], selectedItems = [], render, id, name, placeholder, max, url } = props;
  const topItems = items
    .filter((item, index) => index < max && !typeAheadText);
  const selectedItemsNotTopItems = items
    .filter(item => !typeAheadText
      // to be 100% sure that normalized and sluggified values are correct
      // we should perform data validation when we input data on any persistence layer.
      && compareNormalizations(selectedItems, item.value)
      && !find(topItems, { value: item.value }));
  const filteredItems = items
    .filter(item => typeAheadText
      && typeAheadMatchesValue(typeAheadText, item.value))
    .slice(0, max);
  const otherItems = sortBy(items
    .filter(item =>
      typeAheadText ? !typeAheadMatchesValue(typeAheadText, item.value) : true)
    .filter((item, index) => index >= max), 'value');

  return <div className="filter-typeahead" data-e2e="srp-seach-filter-make">
    <ToolSetTextInput id={id} name={id} icon={true} placeholder={placeholder} value={typeAheadText} onChange={handleTypeAhead} />
    { !!selectedItemsNotTopItems.length && render(selectedItemsNotTopItems, (value) => handleSelection(value)) }
    { !!filteredItems.length && render(filteredItems, (value) => handleSelection(value)) }
    { !!topItems.length && render(topItems, (value) => handleSelection(value)) }
    {
      <div className={classnames('show-more', { show: showAll })}>
        {
          showAll && render(otherItems, nil, false)
        }
      </div>
    }
    <div className="filter-more">{ !!otherItems.length && !showAll &&
        <a href={url} onClick={onClickFilterMore}>{formatNumber(otherItems.length, 'en-US')} more {name}...</a>
    }</div>
  </div>;
};


export default FilterTypeAhead;
