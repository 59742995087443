import React from 'react';
import { useSelector } from 'react-redux';
import './styles.css';

const CDN_URL = process.env.REACT_APP_CDN_URL;
const CONTACT_MESSAGES = {
  CONTACT: {
    TITLE: 'Connect with confidence. ',
    MESSAGE: 'Schedule time to view the boat and make an offer.',
  },
  FINANCE: {
    TITLE: 'Easy sale. ',
    MESSAGE: 'Financing, closing services, warranty, and GAP insurance available.',
  },
  BUTTON: {
    TITLE: 'Contact Seller'
  },
  SUCCESS: {
    TITLE: 'Your email has been sent',
    MESSAGE: ' We have sent your email directly to the seller.',
    NEXT: 'Next, get pre-qualified and on the water faster.'
  }
};

const ContactSuccessNotification = () => {
  return <div className="inline-contact-success">
    <div className="inline-contact-success-info">
      <div className="inline-contact-title">{CONTACT_MESSAGES.SUCCESS.TITLE}</div>
    </div>
    <div className="inline-contact-text">
      {CONTACT_MESSAGES.SUCCESS.MESSAGE}
      <br />
      <b>{CONTACT_MESSAGES.SUCCESS.NEXT}</b>
    </div>
  </div>;
};

const ContactSellerButton = ({ handleLoginContactButtonClick }) => {
  return <button
    id="contact-seller-button"
    data-testid="contact-seller-button"
    className="contact-button"
    onClick={(e) => handleLoginContactButtonClick(e, 'contact seller')}
  >
    {CONTACT_MESSAGES.BUTTON.TITLE}
  </button>;
};

const ContactInstructions = ({ showFinanceMessage = true }) => (
  <div className="contact-instructions-description-container">
    <p>
      <b>{CONTACT_MESSAGES.CONTACT.TITLE}</b>
      <span>{CONTACT_MESSAGES.CONTACT.MESSAGE}</span>
      {showFinanceMessage && (
        <>
          <br />
          <br />
          <b>{CONTACT_MESSAGES.FINANCE.TITLE}</b>
          <span>{CONTACT_MESSAGES.FINANCE.MESSAGE}</span>
        </>
      )}
    </p>
  </div>
);

const PrivateSellerContact = ({url, disableRequestInfo, showDetailsSuccess, handleLoginContactButtonClick}) => {
  return <div className="contact-container">
    <div className="contact-instructions-title">
      <img alt="private seller connect logo" src={`${url}/boat-services/private-seller-connect-logo.svg`}/>
    </div>
    <span id="for-sale-by-subtitle">For sale by Private Seller</span>

    {(!disableRequestInfo || !showDetailsSuccess) &&
        <ContactSellerButton handleLoginContactButtonClick={handleLoginContactButtonClick}/>
    }

    <ContactInstructions />
  </div>;
};

const PrivateSellerContactSimplified = ({disableRequestInfo, handleLoginContactButtonClick}) => {
  return <div className="contact-container">

    {(!disableRequestInfo) &&
      <ContactSellerButton handleLoginContactButtonClick={handleLoginContactButtonClick}/>
    }

    <ContactInstructions showFinanceMessage={false} />
  </div>;
};

export const PrivateSellerConnect = ({ disableRequestInfo, handleLoginContactButtonClick, isConnectSimplifiedExperiment }) => {
  const showDetailsSuccess = useSelector((state) => state.app.leadSuccess);
  const commonPrivateSellerProps = {
    disableRequestInfo,
    handleLoginContactButtonClick,
  };

  return (
    <>
      {(showDetailsSuccess || disableRequestInfo) && <ContactSuccessNotification/>}
      {!showDetailsSuccess &&
        (isConnectSimplifiedExperiment ?
          <PrivateSellerContactSimplified {...commonPrivateSellerProps} />
          :
          <PrivateSellerContact url={CDN_URL} {...commonPrivateSellerProps} />
        )
      }
    </>
  );
};
