/* eslint-disable camelcase */
import { EmailLeadForm } from '@dmm/lib-react-ui-components';
import get from 'lodash/get';
import React, { useRef, useState } from 'react';
import { BOATTRADER_BRAND_SHOWCASE, BOATTRADER_DEALER_SRP, BOATTRADER_SRP } from '../../../constants/boats';
import { addLead, trackContactFormSubmit, addFinanceAdvantageEmailLead, trackPhoneCall } from '../../../store/actions/dataLayer';
import * as leadHelper from '../../../utils/api/leadHelper';
import * as captchaHelper from '../../../utils/api/verifyRecaptchaHelper';
import { BRANCH_EVENT, BRANCHIO_CONTENT_TYPES, branchIoEventsManager } from '../../../utils/branchIoEventsManager';
import useBranchIoMetaTag from '../../../utils/hooks/useBranchIoMetaTag';
import { getTitle, isFSBOContact } from '../../../utils/listingHelper';
import { createInquiryFinanceLead, sendFinanceLead } from '../../../utils/trident';
import { executeRecaptcha, ReCaptchaButtonWrapper } from '../../../components/ReCaptchaButtonWrapper';
import { interestedInFinanceCheckboxExperiment } from '../../../utils/trident/Experiments';
import ReCaptchaV2 from '../../../components/RecaptchaV2';
import '../../../components/ContactForm/details.css';
import '../../../components/ContactForm/styles.css';
import { BOAT_DETAIL_CTA_LABEL } from '../../../constants/boatDetails';
import validate from '../../../utils/dataValidation';
import { nil } from '../../../utils/runOnceHelper';
import { withABTest } from '../../../server/abTest/withABTest';
import { useDispatch } from 'react-redux';

const recaptchaV2Key = process.env.REACT_APP_GOOGLE_RECAPTCHAV2_KEY_ANTISPAM;
const captchaAction = 'BDP_LEAD_SUBMIT';

const SRPEmailLeadForm = (props) => {

  const {
    id = 'srp-contact-form',
    defaultValues = {},
    modal = 'default',
    listing,
    onClose,
    onSuccess = nil,
    onError = nil,
    buttonAttributes} = props;

  const { BranchIoMetaTagComponent, fireBranchioMetaTag } = useBranchIoMetaTag();
  const privateSellerMessage = 'This boat is listed by a private seller. Watch out for really cheap deals - they may be too good to be true. Make sure you see the boat for yourself and know who you\'re dealing with.';
  const listingTitle = getTitle(listing);
  const [clickStartedInBackground, setClickStartedInBackground] = useState(false);
  const [invalidFields, setInvalidFields] = useState([]);
  const [name, setName] = useState(defaultValues?.name || '');
  const [leadData, setLeadData] = useState({});
  const [email, setEmail] = useState(defaultValues?.email || '');
  const [phone, setPhone] = useState(defaultValues?.phone || '');
  const [zip, setZip] = useState(defaultValues?.zip || '');
  const [interestedInFinance, setInterestInFinance] = useState(defaultValues?.interestedInFinance || true);
  const [message, setMessage] = useState(`I'm interested in getting more information about your ${listingTitle}. Please contact me.`);
  const [showReCaptchaV2, setShowReCaptchaV2] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const isFinanceableBrokerDealerFinanceAdvantageLead = interestedInFinanceCheckboxExperiment.isSegmentCandidate(listing);
  const backgroundRef = useRef();
  const dispatch = useDispatch();

  const handleMouseDown = event => {
    setClickStartedInBackground(event.target === backgroundRef.current);
  };

  const handleMouseUp = event => {
    if (clickStartedInBackground && event.target === backgroundRef.current) {
      onClose();
    }
    setClickStartedInBackground(false);
  };

  const handleContentClick = event => {
    setClickStartedInBackground(false);
    event.stopPropagation();
  };

  const renderFinanceCheckbox = ({ interestedInFinance, setInterestInFinance }) => {
    return (
      <div className="financing-checkbox">
        <input
          type="checkbox"
          id="financing"
          name="financing"
          onChange={(e) => setInterestInFinance(e.target.checked)}
          checked={interestedInFinance}
        />
        <label htmlFor="financing">{'I want information on financing.'}</label>
      </div>
    );
  };

  const address = listing.contact?.address || {};
  const addressString = `${address.street || ''}, ${address.city || ''}, ${address.state || ''}, ${address.zip || ''}`;
  const contactFormPosition = 'one-';

  const setDefaultFormStatus = () => {
    setName('');
    setEmail('');
    setPhone('');
    setZip('');
    setInterestInFinance(true);
    setLeadData({});
    setMessage(`I'm interested in getting more information about your ${listingTitle}. Please contact me.`);
    setInvalidFields([]);
    setShowError(false);
    setShowReCaptchaV2(false);
    setSubmitButtonDisabled(false);
  };

  const handlePhoneCall = () => {
    dispatch(trackPhoneCall());
    dispatch(addLead(listing.Id, 'phone call'));
  };

  const updateInvalidFields = (fieldType, isValid) => {
    if (isValid) {
      setInvalidFields(invalidFields.filter(field => field !== fieldType));

    } else {
      if (!invalidFields.includes(fieldType)) {
        const newInvalidFields = [...invalidFields, fieldType];
        setInvalidFields(newInvalidFields);
      }
    }
  };

  const validateAndUpdateField = (fieldType, fieldValue, isRequired = false) => {
    const isValid = validate(fieldValue, fieldType, isRequired);
    updateInvalidFields(fieldType, isValid);
  };

  const getInvalidKey = (val, key, required)  => {
    return validate(val, key, required) ? '' : key;
  };

  const validateAllFields = (listing) => {

    const fieldsToValidate = [
      { value: name, type: 'name', required: true },
      { value: email, type: 'email', required: true },
      { value: message, type: 'text', required: true }
    ];

    if (listing?.owner?.leadValidation) {
      // only include when enabled in provisioning
      if (listing.owner.leadValidation.phoneValidation) {
        fieldsToValidate.push({ value: phone, type: 'phone', required: true });
      }
    } else {
      console.log('Lead validation not enabled for this listing');
      // include by default
      fieldsToValidate.push({ value: phone, type: 'phone', required: true });
    }

    if (listing.isOemModel) {
      fieldsToValidate.push({ value: zip, type: 'zip' });
    }

    return fieldsToValidate.map(({ value, type, required }) => getInvalidKey(value, type, required)).filter(val => val !== '');
  };

  const setLeadSuccessState = (ld = leadData) => {
    setDefaultFormStatus();
    setShowSuccess(true);
    onSuccess(ld);
  };

  const setLeadErrorState = () => {
    setShowError(true);
    onError();
  };

  const addEmailLeadData = (listingId) => {
    dispatch(addLead(listingId, 'email lead'));
  };

  const trackContactFormSubmitData = (resp) => {
    dispatch(trackContactFormSubmit(
      {'leadId': get(resp, 'data.id'),
        ...(isFinanceableBrokerDealerFinanceAdvantageLead ? {'financing_flag': interestedInFinance} : {})
      }));
  };

  const sendLeadSucceed = (leadData) => {
    const { id: listingId } = leadData;
    return leadHelper.sendLead(leadData).then(resp => {
      if (resp.status === 200) {
        addEmailLeadData(listingId);
        trackContactFormSubmitData(resp);
        fireBranchioMetaTag(BRANCHIO_CONTENT_TYPES.CONTACT_SUBMITTED);
        branchIoEventsManager(BRANCH_EVENT.CONTACT_SUBMIT, {
          sku: get(listing, 'id', ''),
          product_name: get(listing, 'model', ''),
          product_brand: get(listing, 'validMake', ''),
        });
        return true;
      }
      return false;
    }).catch(() => {
      return false;
    });
  };

  const handleRecaptchaV2Change = async (success) => {
    if (success) {
      const isLeadSent = await sendLeadSucceed(leadData);
      if (isLeadSent) {
        setLeadSuccessState();
      } else {
        setLeadErrorState();
      }
    }
  };

  const handleSubmit = async () => {
    const checkedInvalidFields = validateAllFields(listing);
    const isValidForm = checkedInvalidFields.length === 0;
    if (!isValidForm) {
      setInvalidFields(checkedInvalidFields);
      return;
    }
    setSubmitButtonDisabled(true);
    fireBranchioMetaTag(BRANCHIO_CONTENT_TYPES.CONTACT_SELLER);
    const formFields = {
      name,
      email,
      phone,
      zip,
      comments: message
    };
    const isFSBO = isFSBOContact(listing.contact);
    const leadSource =  listing.isOemModel ?
      BOATTRADER_BRAND_SHOWCASE :
      isFSBO ?
        BOATTRADER_SRP :
        BOATTRADER_DEALER_SRP;
    const ld = leadHelper.prepareLeadData(
      listing,
      formFields,
      leadSource);
    setLeadData(ld);
    const isCaptchaSucceed = await captchaHelper.passCaptchaChallenge(ld, captchaAction, executeRecaptcha);
    if (!isCaptchaSucceed) {
      setShowReCaptchaV2(true);
      return;
    }
    const isLeadSent = await sendLeadSucceed(ld);
    setSubmitButtonDisabled(false);
    if (isLeadSent) {
      setLeadSuccessState(ld);
      if (phone !== undefined && phone.length > 0) {
        window.kameleoonQueue.push(['Goals.processConversion', 'custom-email-lead-has-phone-number']);
      }
    } else {
      setLeadErrorState();
    }

    if (isFinanceableBrokerDealerFinanceAdvantageLead) {
      addFinanceAdvantageEmailLead();
    }

    if (isFinanceableBrokerDealerFinanceAdvantageLead && interestedInFinance) {
      const inquiryFinanceLead = await createInquiryFinanceLead(formFields, listing);
      try {
        await sendFinanceLead(inquiryFinanceLead);
      } catch {
        // Error is logged in the console already
      }
    }
  };

  return (
    <>
      <div id="backgroundModal" ref={backgroundRef} className={`contact-form-container-${modal}`} onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} data-testid="backgroundModal">
        <div className="srp-email-lead-form" onMouseDown={handleContentClick} id={id} data-testid="srp-contact-form">
          <BranchIoMetaTagComponent />
          <ReCaptchaButtonWrapper>
            <button className="contact-close" onClick={onClose}>
              <img width="18" height="18" alt="close icon" src={`${process.env.REACT_APP_CDN_URL}/img/icons/modal-close-button.svg`}/>
            </button>
            <EmailLeadForm
              portal="bt"
              sellerName={{
                name: isFSBOContact(listing.contact) ? BOAT_DETAIL_CTA_LABEL : 'Contact ' + listing.contact?.name,
                address: isFSBOContact(listing.contact) ? get(listing.contact, 'name') : addressString,
                phoneNumber: listing.contact?.phone || '',
                onClick: (e) => handlePhoneCall(false, e)
              }}
              name={{
                id: `${contactFormPosition}name`,
                name: 'name',
                value: name,
                onChange: (e) => setName(e.target.value),
                onBlur: (e) => validateAndUpdateField('name', e.target.value),
              }}
              email={{
                id: `${contactFormPosition}email`,
                name: 'email',
                value: email,
                onChange: (e) => setEmail(e.target.value),
                onBlur: (e) => validateAndUpdateField('email', e.target.value),
              }}
              phone={{
                id: `${contactFormPosition}phone`,
                label: 'Your Phone',
                name: 'phone',
                value: phone,
                onChange: (e) => setPhone(e.target.value),
                onBlur: (e) => validateAndUpdateField('phone', e.target.value, get(listing, 'owner.leadValidation.phoneValidation', true)),
              }}
              zip={{
                id: listing.isOemModel ? `${contactFormPosition}zip` : '',
                name: listing.isOemModel ? 'zip' : '',
                value: listing.isOemModel ? zip : '',
                onChange: listing.isOemModel ? (e) => setZip(e.target.value) : '',
                onBlur: listing.isOemModel ? (e) => validateAndUpdateField('zip', e.target.value) : '',
              }}
              textarea={{
                name: 'text',
                value: isFSBOContact(listing.contact) ? '' : message,
                id: `${contactFormPosition}emailLeadForm`,
                onChange: (e) => setMessage(e.target.value),
                onBlur: (e) => validateAndUpdateField('text', e.target.value),
              }}
              invalidFields={invalidFields}
              successMessage={(isFSBOContact(listing.contact) && privateSellerMessage)}
              showSuccessMessage={showSuccess}
              showErrorMessage={showError}
              buttonDisabled={submitButtonDisabled}
              buttonAttributes={buttonAttributes}
              onSubmit={handleSubmit}>
              { showReCaptchaV2 && (
                <div data-testid="recaptcha-wrapper" className="recaptcha-wrapper">
                  <ReCaptchaV2
                    sitekey={recaptchaV2Key}
                    onChange={handleRecaptchaV2Change}
                  />
                </div>
              ) }
            </EmailLeadForm>
            { isFinanceableBrokerDealerFinanceAdvantageLead && !showSuccess && renderFinanceCheckbox({ interestedInFinance, setInterestInFinance }) }
          </ReCaptchaButtonWrapper>
        </div>
      </div>
    </>
  );
};

export default withABTest(SRPEmailLeadForm);
