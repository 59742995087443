import { LEAD_MARINE_TYPE } from '@dmm/lib-common/lib/mates/matesBmMessageGenerator';
import { Action } from '@dmm/lib-react-ui-components';
import classnames from 'classnames';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import React, { Component, lazy, Suspense, createRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AD_PORTAL_LEAD_SOURCE, BOATTRADER_BRAND_SHOWCASE, BOATTRADER_DEALER_GALERY, BOATTRADER_DEALER_SRP } from '../../constants/boats';
import { hidePostLeadModal, setContactFormMessage, showAdditionalLeadsModal, togglePostLeadModal } from '../../store/actions';
import { addLead, addProduct, addProductClick, trackContactFormSubmit, addFinanceAdvantageEmailLead } from '../../store/actions/dataLayer';
import * as leadHelper from '../../utils/api/leadHelper';
import * as verifyRecaptchaHelper from '../../utils/api/verifyRecaptchaHelper';
import { BRANCH_EVENT, BRANCHIO_CONTENT_TYPES, branchIoEventsManager } from '../../utils/branchIoEventsManager';
import { cookiesLib } from '../../utils/cookies';
import useBranchIoMetaTag from '../../utils/hooks/useBranchIoMetaTag';
import { getSanitisedDealerName, isFSBOContact } from '../../utils/listingHelper';
import { createInquiryFinanceLead, sendFinanceLead } from '../../utils/trident';
import * as storage from '../../utils/storage';
import { executeRecaptcha, ReCaptchaButtonWrapper } from '../ReCaptchaButtonWrapper';
import { interestedInFinanceCheckboxExperiment } from '../../utils/trident/Experiments';
import ReCaptchaV2 from '../RecaptchaV2';
import SellerInformation from '../SellerInformation';
import Link from '../SEO/Link';
import ValidatedInput from '../ValidatedInput';
import './details.css';
import './styles.css';

const PostLeadModal = lazy(() => import('./PostLeadModal'));

const recaptchaV2Key = process.env.REACT_APP_GOOGLE_RECAPTCHAV2_KEY_ANTISPAM;

const USER_STATEMENTS = {
  termsOfUse: 'Terms of Use',
  privacyPolicies: 'Privacy Policy'
};

let leadData;

const captchaAction = 'BDP_LEAD_SUBMIT';
const MANUFACTUER_MSG = 'We have sent your request directly to the manufacturer.';
const DEALER_MSG = 'We have sent your request directly to the dealer.';
const SELLER_MSG = 'We have sent your information directly to the seller.';
const PRIVATE_SELLER_MSG = 'This boat is listed by a private seller. Here are some helpful tips to protect yourself while buying from a private seller.';

class ContactForm extends Component {
  backgroundRef = createRef();
  clickStartedInBackground = false;

  constructor(props) {
    super(props);
    this.togglePostLeadModal = this.togglePostLeadModal.bind(this);
    this.hidePostLeadModal = this.hidePostLeadModal.bind(this);
    this.comments = isFSBOContact(this.props.listingContact) ? '' : this.props.prePopulatedText || '';
    this.state = {
      formValid: {
        // if it's readonly it cannot be validate
        name: get(this.props, 'readOnlyInputs', []).includes('name'),
        email: get(this.props, 'readOnlyInputs', []).includes('email'),
        phone: true,
        comments: !!this.comments,
        zip: !this.props.showZipCode
      },
      formFields: {
        name: get(this.props, 'defaultValues.name', ''),
        email: get(this.props, 'defaultValues.email', ''),
        phone: get(this.props, 'defaultValues.phone', ''),
        comments: this.comments,
        zip: ''
      },
      imtId: this.props.listingId,
      validationErrors: false,
      showSuccess: false,
      submitButtonDisabled: false,
      showError: false,
      isFSBO: false,
      isOemManufactuer: this.props.isOemManufactuer || false,
      isOemDealer: this.props.isOemDealer || false,
      reCaptchaV2: false,
      interestedInFinance: true,
      isFinanceableBrokerDealerFinanceAdvantageLead: false,
      isSBP: false
    };
  }

  static defaultProps = {
    id: 'results-contact-form',
    title: 'Contact Seller',
    contactButtonText: 'Send Email',
    labelText: {},
    placeholder: {
      name: 'First & Last Name',
      email: 'Email',
      phone: 'Phone',
      comments: 'Questions/Comments',
      firstName: 'First Name',
      zip: 'ZIP Code'
    },
    emailLead: true
  }

  async componentDidMount() {
    const { listing } = this.props;
    const isFinanceableBrokerDealerFinanceAdvantageLead = interestedInFinanceCheckboxExperiment.isSegmentCandidate(listing);
    this.setState({ isFinanceableBrokerDealerFinanceAdvantageLead });
  }

  updateField(field, valid, value) {
    if (valid) {
      this.setState({
        formValid: {
          ...this.state.formValid,
          [field]: valid
        },
        formFields: {
          ...this.state.formFields,
          [field]: value
        }
      });
    } else {
      this.setState({formValid: {
        ...this.state.formValid,
        [field]: valid
      } });
    }
  }

  UNSAFE_componentWillUpdate() {
    if ((this.state.showSuccess || this.state.showError) && this.props.open)  {
      this.setState({
        showSuccess: false,
        showError: false
      });
    }
  }

  formIsInvalid() {
    const invalid = Object.values(this.state.formValid).filter( (value) => {
      return !value;
    });
    return invalid.length > 0;
  }

  onRecaptchaV2Change = (success) => {
    if (success) {
      this.sendLead(leadData);
    }
  };

  async captchaChallenge(leadData) {
    let captchaScore;
    let captchaToken;
    try {
      captchaToken = await executeRecaptcha(captchaAction);
      leadData.captchaData = { token: captchaToken, expectedAction: captchaAction };
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('There was an error retrieving a recaptcha token');
    }
    if (!captchaToken) {
      this.sendLead(leadData);
      return;
    }
    try {
      let captchaResponse = await verifyRecaptchaHelper.verify(captchaToken, captchaAction, leadData.formFields.email);
      if (captchaResponse.status === 200) {
        const { useRecaptcha } = this.props;
        captchaScore = get(captchaResponse, 'data.score');
        const captchaDomain = get(captchaResponse, 'data.domainCheck');
        captchaToken = await executeRecaptcha(captchaAction);
        leadData.captchaData = { token: captchaToken, expectedAction: captchaAction };
        if (useRecaptcha && (captchaScore <= 0.7 || captchaDomain)) {
          this.setState({reCaptchaV2: true});
        } else {
          this.sendLead(leadData);
        }
      } else {
        this.sendLead(leadData);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('There was an error verifying and scoring the recaptcha token');
      this.sendLead(leadData);
    }
  }

  sendLead(leadData) {
    let { listingId, emailLead, branchIoClick} = this.props;
    branchIoClick?.(BRANCHIO_CONTENT_TYPES.CONTACT_SELLER);
    leadHelper.sendLead(leadData).then(resp => {
      if (resp.status === 200) {
        if (emailLead) {
          this.props.addLead(listingId, 'email lead');
          if (this.props.isSBP) {
            window.kameleoonQueue.push(['Goals.processConversion', 'sbp-email-lead-phone-required']);
          }

          if (this.props.contactSubmitTrack) {
            this.props.trackContactFormSubmit(
              {'leadId': get(resp, 'data.id'),
                ...(this.state.isFinanceableBrokerDealerFinanceAdvantageLead ? {'financing_flag': this.state.interestedInFinance} : {})
              });
          }
        } else {
          this.props.addLead(listingId, 'video tour submit');
        }
        this.setState({validationErrors: false, showSuccess: true, showError: false}, () => {
          let formFieldsJSON = JSON.stringify({
            name: leadData.formFields.name,
            email: leadData.formFields.email,
            phone: leadData.formFields.phone
          });

          storage.setSessionItem('ANA-1515', formFieldsJSON);
          storage.setSessionArrayItem('SUBMITTED_IMT_IDS', listingId);

          if (this.props.onSuccess){
            this.props.onSuccess();
          }
        });

        if ((!leadData.type || leadData.type === LEAD_MARINE_TYPE.lineItem) && this.props.additionalLeadsModal) {
          this.setContactFormSuccessMessage();
          this.props.onClose();
          this.props.showAdditionalLeadsModal(leadData);
        }
      } else {
        this.setState({validationErrors: false, showSuccess: false, showError: true, submitButtonDisabled: false});
      }
    }).catch(() => {
      this.setState({validationErrors: false, showSuccess: false, showError: true, submitButtonDisabled: false});
    });
    window?.permutiveHelper?.submitLead && window?.permutiveHelper?.submitLead(emailLead ? 'emailLead' : 'socialLead', listingId);
  }

  async doSubmit(event, cta) {

    let {listingId, emailLead, listingContact, partyId, leadSourceOverride, modal, isDealerSRP, dealerId, branchIoClick} = this.props;
    event.preventDefault();
    if (this.formIsInvalid()) {
      this.setState({validationErrors: true});
    } else {
      this.setState({
        submitButtonDisabled: true,
        ...(isFSBOContact(listingContact) && { isFSBO: true })
      });
      let leadSource = (this.state.isOemDealer || this.state.isOemManufactuer) ? BOATTRADER_BRAND_SHOWCASE : (leadSourceOverride || AD_PORTAL_LEAD_SOURCE);
      leadData = {
        imtId: this.state.imtId,
        source: leadSource,
        formFields: this.state.formFields,
        id: this.props.partyId,
        cta
      };

      if (partyId) {
        leadData.type = this.state.imtId && partyId ? LEAD_MARINE_TYPE.dealerLocatorLineItem : LEAD_MARINE_TYPE.serviceProvider;
      } else {
        // When listingId is the partyId and we want to send a lead from a modal dialog
        if (listingId && modal) {
          leadData.type = LEAD_MARINE_TYPE.serviceProvider;
          leadData.id = listingId;
          leadData.source = BOATTRADER_DEALER_GALERY;
        }
        if (isDealerSRP) {
          leadData.type = LEAD_MARINE_TYPE.serviceProvider;
          leadData.id = dealerId;
          leadData.source = BOATTRADER_DEALER_SRP;
        }
      }
      if (this.props.copyOem) {
        leadData.copyOem = this.props.copyOem;
      }
      if (this.props.oemId) {
        leadData.oemId = this.props.oemId;
      }
      if (this.props.brandId) {
        leadData.brandId = this.props.brandId;
      }
      leadData.gaClientID = cookiesLib.get('_ga');
      if (emailLead) {
        await this.captchaChallenge(leadData);
      } else {
        this.sendLead(leadData);
      }
      branchIoClick?.(BRANCHIO_CONTENT_TYPES.CONTACT_SUBMITTED);
      branchIoEventsManager(BRANCH_EVENT.CONTACT_SUBMIT, {
        sku: get(this.props.listing, 'id', ''),
        product_name: get(this.props.listing, 'model', ''),
        product_brand: get(this.props.listing, 'validMake', ''),
      });
      if (this.state.isFinanceableBrokerDealerFinanceAdvantageLead) {
        addFinanceAdvantageEmailLead();
      }
      if (this.state.isFinanceableBrokerDealerFinanceAdvantageLead && this.state.interestedInFinance) {
        const inquiryFinanceLead = await createInquiryFinanceLead(this.state.formFields, this.props.listing, 'SRP-ContactForm');
        try {
          await sendFinanceLead(inquiryFinanceLead);
        } catch {
          // Error is logged in the console already
        }
      }
    }
  }

  togglePostLeadModal() {
    this.props.togglePostLeadModal(true);
  }

  hidePostLeadModal() {
    this.props.hidePostLeadModal();
  }

  handleMouseDown = event => {
    this.clickStartedInBackground = event.target === this.backgroundRef.current;
  };

  handleMouseUp = event => {
    if (this.clickStartedInBackground && event.target === this.backgroundRef.current) {
      this.props.onClose();
    }
    this.clickStartedInBackground = false;
  };

  handleContentClick = event => {
    this.clickStartedInBackground = false;
    event.stopPropagation();
  };

  setContactFormSuccessMessage() {
    const data = { success: true, type: '', message: '' };

    if (this.state.isFSBO) {
      data.type = 'FSBO';
      data.message = PRIVATE_SELLER_MSG;
    } else if (this.state.isOemManufactuer) {
      data.message = MANUFACTUER_MSG;
    } else if (this.state.isOemDealer ) {
      data.message = DEALER_MSG;
    } else {
      data.message = SELLER_MSG;
    }

    this.props.setContactFormMessage(data);
  }

  setInterestInFinance(value) {
    this.setState({interestedInFinance: value});
  }

  renderFinanceCheckbox() {
    return (
      <div className="financing-checkbox">
        <input
          type="checkbox"
          id="financing"
          name="financing"
          onChange={(e) => this.setInterestInFinance(e.target.checked)}
          checked={this.state.interestedInFinance}
        />
        <label htmlFor="financing">I want information on financing.</label>
      </div>
    );
  }

  renderLoader = () => <div className="preloader"></div>;

  render() {
    const { showPostLeadModal, inlineTitle } = this.props;
    const modal = get(this.props, 'modal', 'default');
    const type = get(this.props, 'type', 'contact');
    const title = this.props.data?.listingType === 'enhanced listing' ? '' : this.props.title;
    const contactButtonText = this.props.data?.listingType === 'enhanced listing' ? 'Contact Seller' : this.props.contactButtonText;

    let sellerInformation;
    if (this.props.listing && !this.props.listing.contact && this.props.listing.location && this.props.listing.owner) {
      sellerInformation = <SellerInformation
        contact={{
          address: this.props.listing.location.address
        }}
        name={getSanitisedDealerName(this.props.listing.owner)}
        listingId={this.props.listing.id}
      />;
    }

    return (
      <>
        <div id="backgroundModal" ref={this.backgroundRef} className={`contact-form-container-${modal}`} onMouseDown={this.handleMouseDown} onMouseUp={this.handleMouseUp} data-testid="backgroundModal">
          <div className={classnames('contact-form', `contact-form-${modal}`, {'open': this.props.open}, `form-type-${type}`)} onMouseDown={this.handleContentClick} id={this.props.id} data-testid="contactForm">
            <div className="title-and-close-container">
              {inlineTitle && <span className="form-inline-title">{inlineTitle}</span>}
              <button ref="contactClose" data-e2e="contact-form-close" className="contact-close" onClick={this.props.onClose}>
                <span className="visuallyhidden">Close</span>
                <img alt="close button" src={`${process.env.REACT_APP_CDN_URL}/img/icons/close-icon-black.svg`} />
              </button>
            </div>
            <div className={classnames('main-form', { 'hidden': this.state.showSuccess || (this.props.onlyShowError && this.state.showError)})}  data-e2e="main-form">
              {title && <div className={classnames('title', { 'underlined': this.props.showTitleSeparator && !this.state.validationErrors, 'centered': this.props.centeredTitle })}>{title}</div>}
              {this.props.subtitle && <div className="subtitle">{this.props.subtitle}</div>}
              {sellerInformation}
              <div data-e2e="message-error" className={classnames('message-error', {'hidden': !this.state.validationErrors})}>
                * Please correct highlighted errors.
              </div>
              <form onSubmit={event => this.doSubmit(event, this.props.cta)} _lpchecked="1" className={classnames('form', {'error': this.state.validationErrors})}>
                <input type="hidden" id="listing-id" name="listing-id" value="" />
                <input type="hidden" id="imt-id" name="imt-id" value="" />
                <input type="hidden" id="zipCode" name="zipCode" value="" />
                {this.renderInputs()}
                <div className="footer">
                  {this.state.reCaptchaV2 && <ReCaptchaV2
                    sitekey={recaptchaV2Key}
                    onChange={this.onRecaptchaV2Change}
                  />
                  }
                  <label htmlFor={'g-recaptcha-response-100000'} style={{ display: 'none' }} data-testid={'g-rc-label-100000'}>
                    reCAPTCHA
                  </label>
                  <ReCaptchaButtonWrapper>
                    <Action stretch disabled={this.state.submitButtonDisabled} type="submit" data-e2e="btn-send-email" label={contactButtonText} />
                  </ReCaptchaButtonWrapper>
                  {this.renderUserPolicies()}
                </div>
                {this.state.isFinanceableBrokerDealerFinanceAdvantageLead && this.renderFinanceCheckbox()}
              </form>
            </div>
            <div className={classnames('message-sent', {'hidden': !this.state.showSuccess})}>
              <div data-e2e="success-alert" className="success-alert">
                {this.state.isFSBO ? (
                  <div data-e2e="message-box" className="message-box">
                    <p data-e2e="message-title" className="message-title">Congratulations</p>
                    <p>{ PRIVATE_SELLER_MSG }</p>
                    <ul data-e2e="message-list" className="message-list">
                      <li>Be wary of bargains. Deals too good to be true often are.</li>
                      <li>Check the condition of the boat in person.</li>
                      <li>Verify the seller’s identity.</li>
                      <li><a className="postlead-modal-link" href="javascript:void(0);" onClick={() => this.togglePostLeadModal()}>More helpful tips</a></li>
                    </ul>
                  </div>
                ) : this.state.isOemManufactuer ? (
                  <div data-e2e="message-box" className="message-box">
                    <p data-e2e="message-title" className="message-title">Congratulations!</p>
                    <p>{ MANUFACTUER_MSG }</p>
                  </div>
                ) : this.state.isOemDealer ? (
                  <div data-e2e="message-box" className="message-box">
                    <p data-e2e="message-title" className="message-title">Congratulations!</p>
                    <p>{ DEALER_MSG }</p>
                  </div>
                ) : (
                  <div data-e2e="message-box" className="message-box">
                    <p data-e2e="message-title" className="message-title">Congratulations!</p>
                    <p>{ SELLER_MSG }</p>
                  </div>
                )}
              </div>
            </div>
            <div className={classnames('message-error', { 'hidden': !this.state.showError })}>
              <div data-e2e="error-alert" className="error-alert">
                <div data-e2e="message-box" className="message-box">
                  <p data-e2e="message-title" className="message-title">Request cannot be completed</p>
                  <p>You may have entered incorrect information or server is temporarily down. Please reload this page and try again later.</p>
                </div>
              </div>
            </div>
          </div>
          {
            showPostLeadModal &&
            <Suspense fallback={this.renderLoader()}>
              <PostLeadModal
                show={this.props.showPostLeadModal}
                close={this.hidePostLeadModal}
              />
            </Suspense>
          }
        </div>
      </>
    );
  }

  renderInputs() {
    let inputs = ['name', 'email', 'phone'].map((field) => {

      return {
        inputName: field,
        render: (index) =>
          <ValidatedInput
            type="text"
            name={field}
            id={field}
            key={index}
            tabindex={index + 1}
            displayLabel={false}
            labelText={this.props.labelText[field]}
            placeholder={this.props.placeholder[field]}
            onChange={(field, valid, value) => this.updateField(field, valid, value)}
            validation={field}
            defaultValue={get(this.props, `defaultValues.${field}`)}
            readOnly={get(this.props, 'readOnlyInputs', []).includes(field)}
          />
      };
    });
    inputs.push({ inputName: 'firstName', render: (index) => <ValidatedInput type="text" name="fname" id="fname" key={index} tabindex={index + 1} displayLabel={false} labelText={this.props.labelText.firstName} placeholder={this.props.placeholder.firstName} hidden={true} onChange={(field, valid, value) => this.updateField(field, valid, value)} /> });

    if (!this.props.hideComments) {
      inputs.push({
        inputName: 'comments', render: (index) => {
          return <ValidatedInput type="textarea" name="comments" id="comments" key={index} tabindex={index + 1}
            displayLabel={false} labelText={this.props.labelText.comments} placeholder={this.props.placeholder.comments}
            onChange={(field, valid, value) => this.updateField(field, valid, value)} validation="text"
            defaultValue={this.comments}
            forceTyping={isFSBOContact(this.props.listingContact)} />;
        }
      });
    }

    if (this.props.showZipCode) {
      inputs.push({ inputName: 'zip', render: (index) => <ValidatedInput type="text" name="zip" id="zip" key={index} tabindex={index + 1} displayLabel={false} labelText={this.props.labelText.zip} placeholder={this.props.placeholder.zip} onChange={(field, valid, value) => this.updateField(field, valid, value)} validation="zip" required/> });
    }

    if (this.props.orderInputs){
      inputs = sortBy(inputs, ({ inputName }) => this.props.orderInputs.indexOf(inputName));
    }

    return inputs.map((input, index) => input.render(index));
  }

  renderUserPolicies() {
    const { termsOfUseUrl, privacyPolicyUrl } = this.props;

    let policiesLinks;
    let termsOfUse;
    if (termsOfUseUrl) {
      termsOfUse = <Link target="_blank" href={termsOfUseUrl}>{USER_STATEMENTS.termsOfUse}</Link>;
      policiesLinks = termsOfUse;
    }

    let privacyPolicy;
    if (privacyPolicyUrl) {
      privacyPolicy = <Link target="_blank" href={privacyPolicyUrl}>{USER_STATEMENTS.privacyPolicies}</Link>;
      if (termsOfUse) {
        policiesLinks = <>{termsOfUse}&nbsp;and&nbsp;{privacyPolicy}</>;
      } else {
        policiesLinks = privacyPolicy;
      }
    }

    if (policiesLinks) {
      return <span className="policies">I agree to the&nbsp;{policiesLinks}.</span>;
    }
  }
}

const mapStateToProps = (state) => ({
  showPostLeadModal: get(state, 'app.showPostLeadModal', false),
});

const ContactFormContainer = (props) => {
  const { BranchIoMetaTagComponent, fireBranchioMetaTag } = useBranchIoMetaTag();
  return (
    <>
      <BranchIoMetaTagComponent />
      <ContactForm {...props} branchIoClick={fireBranchioMetaTag} />
    </>
  );
};

export default connect(
  mapStateToProps,
  dispatch => bindActionCreators({
    addProduct,
    addProductClick,
    addLead,
    trackContactFormSubmit,
    showAdditionalLeadsModal,
    setContactFormMessage,
    togglePostLeadModal,
    hidePostLeadModal,
  }, dispatch)
)(ContactFormContainer);
