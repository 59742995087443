import React from 'react';

import './styles.css';
import LegacyListingResult from '../LegacyListingResult';
import { setPosition } from '../../utils/paginationHelper';
import { removeAdsForBrandedSrp } from '../../utils/listingHelper';
import get from 'lodash/get';

/*This component is only used for Engines SRP */
class LegacyListingList extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      contactForm: {
        type: '',
        form: undefined
      },
      window: false
    };
  }

  componentDidMount() {
    this.setState({
      window: window
    });
  }

  addContactForm(contactForm) {
    this.setState({
      contactForm: contactForm
    });
  }

  getContactForm(listingId) {
    if (get(this.state.contactForm.form, 'props.children.props.listingId', 0) === listingId) {
      return this.state.contactForm;
    }

    return {
      type: '',
      form: undefined
    };
  }

  render() {
    let itemCount = 0;
    let {
      myboats,
      cookies,
      url,
      pos,
      dealerId,
      tridentTeaserRate,
      ficoScores,
      isLeadSubmitted
    } = this.props;
    let listingArr;
    const listings = removeAdsForBrandedSrp(this.props.listings, this.props.isBrandedSrp);
    return (
      <ol className="boat-list">
        {this.state.contactForm.form}
        {
          listings.map((listing, i) => {
            itemCount += 1;
            let position = setPosition(pos, i + 1);
            const listingResultProperties = {
              listing: listing,
              position,
              myboats,
              cookies,
              url,
              itemCount,
              dealerId,
              dataLayer: this.props?.dataLayer
            };
            if (this.props.isBrandedSrp) {
              listingResultProperties.oemDetails = this.props.oemDetails;
              listingResultProperties.handleContactDealer = this.props.handleFindDealerButtonClick;
              listingResultProperties.isBrandedSrp = this.props.isBrandedSrp;
            }
            if (this.props.isEngine) {
              listingResultProperties.isEngine = this.props.isEngine;
            }
            listingArr = [
              <LegacyListingResult
                key={listing.id}
                addContactForm={(contactForm) => this.addContactForm(contactForm)}
                contactForm={this.getContactForm(listing.id)}
                tridentTeaserRate={tridentTeaserRate}
                ficoScores={ficoScores}
                isLeadSubmitted={isLeadSubmitted}
                {...listingResultProperties}
              />
            ];

            return listingArr;
          }).reduce((prev, next) => [prev, ...next], [])
        }
      </ol>
    );
  }
}

export default LegacyListingList;
