import React from 'react';

import DefaultLayout from '../../../layouts/Default';
import BreadCrumb from '../../../components/BreadCrumb';

import { SERVICES_URL } from '../../../constants/services';

import './styles.css';

const Layout = (props) => {
  const FormattedText = props.formattedText;

  return (
    <>
      <DefaultLayout {...props} pageType={props.pageType}>
        <div className="service-content">
          <BreadCrumb items={[{
            title: 'Home',
            link: '/'
          }, {
            title: 'Services',
            link: SERVICES_URL
          }, {
            title: props.title,
          }]} />
        </div>
        <div className="resourceContainer">
          <div className="resourceBody">
            <h1 className="hdrA100">{ props.upperTitle }</h1>
            {(() => {
              if (FormattedText) {
                return <FormattedText/>;
              }
              return (
                <p className="resourcesTopPad">
                  <strong>
                    { props.paragraph }
                  </strong>
                </p>
              );
            })()}
          </div>
          <div className="adverTxt">
              If you would like to advertise on this page, please contact<br/>1-877-354-4069, or email <a href="mailto:dsg@boats.com">dsg@boats.com</a>.	</div>
          <br className="clear"/>
        </div>
      </DefaultLayout>
    </>
  );
};

export default Layout;
