import join from 'lodash/join';
import keys from 'lodash/keys';
import map from 'lodash/map';
let states = require('usa-states').UsaStates;
export const LISTING_STANDARD = 'standard listing';
export const LISTING_ENHANCED = 'enhanced listing';
export const LISTING_SPONSORED = 'sponsored listing';
export const LISTING_FEATURED = 'featured listing';
export const SPONSORED_TRACKING = 'sponsored';
export const LISTING_DEALER_TRACKING = 'broker-dealer';
export const LISTING_SAME_DEALER = 'same seller listing';
export const LISTING_DEALER = 'broker-dealer listing';
export const LISTING_OEM = 'oem brand listing';
export const LISTING_MANUFACTURER = 'manufacturer listing';
export const LISTING_MULTICONTACT = 'multicontact listing';
export const DEFAULT_NO_BOAT_IMAGE = `${process.env.REACT_APP_CDN_URL}/img/default/thumbnail.gif`;
export const REACT_APP_FACEBOOK_URL = 'https://www.facebook.com/BoatTrader';
export const REACT_APP_TWITTER_URL = 'https://twitter.com/Boattrader/';
export const REACT_APP_YOUTUBE_URL = 'https://www.youtube.com/user/BoatTraderVideo';
export const REACT_APP_INSTAGRAM_URL = 'https://www.instagram.com/boattrader/';
export const REACT_APP_COOKIES_POLICY_URL = 'https://www.boatsgroup.com/cookies-policy/';
export const REACT_APP_COPYRIGHT_URL = 'https://www.boatsgroup.com/copyright/';
export const REACT_APP_ADVERTISER_AGREEMENT_URL = 'https://www.boatsgroup.com/advertiser-agreement/';
export const REACT_APP_PRIVACY_POLICY_URL = 'https://www.boatsgroup.com/privacy/';
export const REACT_APP_TERMS_OF_USE_URL = 'https://www.boatsgroup.com/terms-of-use/';
export const REACT_APP_AD_CHOICES_URL = 'https://www.networkadvertising.org/choices';
export const BOATS_SRP_PATH = '/boats/';
export const HULL_TYPE_FIBERGLASS_REINFORCED_OLD = '/boats/hull-fiberglass+reinforced/';
export const HULL_TYPE_FIBERGLASS_REINFORCED_NEW = '/boats/hull-fiberglass-reinforced/';
export const HULL_TYPE_WOOD_OLD = '/boats/hull-wood/';
export const HULL_TYPE_WOOD_NEW = '/boats/hull-wooden/';
export const LOCAL_DELIVERY = 'LOCAL_DELIVERY';
export const LIVE_VIDEO_TOUR = 'LIVE_VIDEO_TOUR';
export const BANNER_ATTRIBUTES = ['AVAILABLE_SOON', 'IN_STOCK', 'SALE_PENDING', 'NEW_ARRIVAL'];
export const BANNER_CALLS_TO_ACTION = {
  'AVAILABLE_SOON': 'Pre-Order',
  'IN_STOCK': 'Contact Seller',
  'SALE_PENDING': 'Contact Seller',
  'NEW_ARRIVAL': 'One-click Contact'
};

export const BANNER_TEXTS = {
  'AVAILABLE_SOON': 'Available Soon',
  'IN_STOCK': 'In-Stock',
  'SALE_PENDING': 'Sale Pending',
  'NEW_ARRIVAL': 'New Arrival'
};

export const SEARCH_MAX_BOATS = 9999;

export const GROUPS_CLASSES_MAPPING = {
  'pleasure-boats': {
    type: 'power',
    classes: 'power-aft+power-bay+power-bowrider+power-pcatamaran+power-cruiser+power-cuddy+power-dive+power-downeast+power-skifish+power-runabout+power-walk'
  },
  'fishing-boats': {
    type: 'power',
    classes: 'power-saltfish+power-fresh'
  },
  'angler': {
    type: 'power',
    classes: 'power-saltfish'
  },
  'duck-boat': {
    type: 'power',
    classes: 'power-jon'
  },
  'weekender': {
    type: 'power',
    classes: 'power-cruiser'
  },
  'submersible': {
    type: 'power',
    classes: 'power-submersible'
  },
  'yachts-for-sale': {
    type: 'power+sail',
    classes: 'power-aft+power-antique+power-center+power-convertible+power-cruiser+power-downeast+power-expresscruiser+power-flybridge+power-highperf+power-mega+power-motor+power-motorsailer+power-pcatamaran+power-saltfish+power-sportfish+power-sportcruiser+power-trawler+sail-antique+sail-catamaran+sail-centercockpit+sail-cruiser+sail-cutter+sail-deck+sail-ketch+sail-motor+sail-multihull+sail-performance+sail-pilot+sail-racercruiser+sail-racer+sail-schooner+sail-sloop+sail-trimaran+sail-yawl'
  }
};

const stringGroupClasses = join(map(keys(GROUPS_CLASSES_MAPPING), (groupName) => { return 'group-' + groupName; }), '|');

export const SEARCH_URL_PATTERN = '/boats/:group(' + stringGroupClasses + ')?/:ownerId(dealer-[^/]+)?/:types(type-[a-z|0-9|+|%|,|-]+)?/:classes(class-[a-z0-9+%, -]+)?/:oem(oem-[^/]+)?/:make(make-[^/]+)?/:modelRange(model-range-[^/]+)?/:model(model-[^/]+)?/:state(state-[a-z|0-9|%]+)?/:city(city-[^/]+)?/:zip(zip-[0-9]+)?/:by(by-[a-z|0-9|%]+)?/:radius(radius-[0-9|exact|any]+)?/:makeModel(makemodel-[^/]+)?/:fuelType(fuel-[a-z|,]+)?/:hullMaterial(hull-[a-z0-9+%, -]+)?/:engine(engine-[a-z|0-9|%| ,+,-]+)?/:length(length-[0-9|,]+)?/:price(price-[0-9|,]+)?/:priceRevisedSince(price-drop)?/:year(year-[0-9|,]+)?/:condition(condition-[a-z]+)?/:page(page-[0-9]+)?/:sort(sort-[a-z]+:[a-z]+)?/:modal(modal-[a-z|+]+)?/:keyword(keyword-[A-Za-z0-9+%,. -]+)?/';
export const SEARCH_URL_BRAND_ONLY = '/brands/';
export const SEARCH_URL_PATTERN_BRAND = '/brands/:oem([^/]+)?/:group(' + stringGroupClasses + ')?/:ownerId(dealer-[^/]+)?/:types(type-[a-z|0-9|+|%|,|-]+)?/:classes(class-[a-z0-9+%, -]+)?/:make(make-[^/]+)?/:modelRange(model-range-[^/]+)?/:model(model-[^/]+)?/:state(state-[a-z|0-9|%]+)?/:city(city-[^/]+)?/:zip(zip-[0-9]+)?/:by(by-[a-z|0-9|%]+)?/:radius(radius-[0-9|exact|any]+)?/:makeModel(makemodel-[^/]+)?/:fuelType(fuel-[a-z|,]+)?/:hullMaterial(hull-[a-z0-9+%, -]+)?/:engine(engine-[a-z|0-9|%| ,+,-]+)?/:length(length-[0-9|,]+)?/:price(price-[0-9|,]+)?/:priceRevisedSince(price-drop)?/:year(year-[0-9|,]+)?/:condition(condition-[a-z]+)?/:page(page-[0-9]+)?/:sort(sort-[a-z]+:[a-z]+)?/:modal(modal-[a-z|+]+)?/:keyword(keyword-[A-Za-z0-9+%,. -]+)?/';
export const SEARCH_URL_TYPE_PATTERN = /type-([A-Z|a-z|+|%|,|-]+)/;
export const SEARCH_URL_CLASS_PATTERN = /class-([A-Za-z0-9+%, -]+)/;
export const SEARCH_URL_CLASS_GROUP_PATTERN = /group-([a-z-]+)/;
export const SEARCH_URL_MAKE_PATTERN = /make-([^/]+)/;
export const SEARCH_URL_MODEL_PATTERN = /model-([^/]+)/;
export const SEARCH_URL_MODEL_RANGE_PATTERN = /model-range-([^/]+)/;
export const SEARCH_URL_STATE_PATTERN = /state-([a-z]+)/;
export const SEARCH_URL_CITY_PATTERN = /city-([^/]+)/;
export const SEARCH_URL_ZIP_PATTERN = /zip-([0-9]+)/;
export const SEARCH_URL_BY_PATTERN = /by-([A-Z|a-z|0-9|%]+)/;
export const SEARCH_URL_RADIUS_PATTERN = /radius-([0-9|exact|any]+)/;
export const SEARCH_URL_MAKE_MODEL_PATTERN = /makemodel-([^/]+)/;
export const SEARCH_URL_FUEL_PATTERN = /fuel-([A-Z|a-z|,]+)/;
export const SEARCH_URL_HULL_PATTERN = /hull-([A-Za-z0-9+%, -]+)/;
export const SEARCH_URL_ENGINETYPE_PATTERN = /engine-([A-Z|a-z|0-9| |+|-]+)/;
export const SEARCH_URL_LENGTH_PATTERN = /length-([0-9]+),?([0-9]+)?/;
export const SEARCH_URL_PRICE_PATTERN =  /price-([0-9]+),?([0-9]+)?/;
export const SEARCH_URL_PRICE_REDUCED_PATTERN = /price-drop/;
export const SEARCH_URL_YEAR_PATTERN = /year-([0-9]+)?,?([0-9]+)?/;
export const SEARCH_URL_CONDITION_PATTERN = /condition-([a-z]+)/;
export const SEARCH_URL_KEYWORD_PATTERN = /keyword-([A-Za-z0-9+%,. -]+)/;
export const SEARCH_URL_PAGE_PATTERN = /page-([0-9]+)/;
export const SEARCH_URL_SORT_PATTERN = /sort-([a-z]+:[a-z]+)/;
export const SEARCH_URL_MODAL_PATTERN = /modal-([a-z|+]+)/;
export const SEARCH_URL_OWNER_PATTERN = /dealer-([^/]+)/;
export const SEARCH_URL_OEM_PATTERN = /oem-([^/]+)/;

export const DEFAULT_REFINE_SEARCH = {
  type: 'any',
  radius: '25',
  make: '',
  price: {},
  year: {},
  makeModel: {},
  modelRange: undefined,
  condition: 'any',
  state: '',
  city: [],
  zip: '',
  'for-sale-by': '',
  length: {},
  model: '',
  fuelType: '',
  hullMaterial: '',
  engine: '',
  oem: {}
};

export const FACET_META_MAP = {
  radius: {
    suffix: 'mi',
    format: 'number'
  },
  price: {
    prefix: '$',
    format: 'number'
  },
  length: {
    suffix: 'ft',
    format: 'number'
  },
  fuelType: {
    formattedKey: 'Fuel Type'
  },
  multiFacetedBoatTypeClass: {
    formattedKey: 'Class'
  },
  makeModel: {
    formattedKey: 'Make / Model'
  },
  modelRange: {
    formattedKey: 'Make / modelRange'
  },
  hullMaterial: {
    formattedKey: 'Hull Material'
  },
  ownerID: {
    formattedKey: 'Owner ID'
  },
  oem: {
    formattedKey: 'OEM'
  },
  powerHp: {
    suffix: 'hp',
    format: 'number'
  }
};

export const US_STATES = new states().format(
  {
    $value: 'abbr',
    $name: 'name'
  }
);

export const US_COUNTRIES = [
  {
    value: 'US',
    name: 'United States'
  }
];

export const AD_PORTAL_LEAD_SOURCE = 'BoatTrader PORTAL AD';
export const BOATTRADER_BRAND_SHOWCASE = 'BoatTrader Brand Showcase';
export const BOATTRADER_DEALER_GALERY = 'Boat Trader-Dealer Gallery';
export const BOATTRADER_DEALER_SRP = 'Boat Trader-Dealer SRP';
export const BOATTRADER_SRP = 'Boat Trader SRP';
export const DEFAULT_NO_MAX_LENGTH = '101';
export const BOATS_PAGE_SIZE = 31;
export const TOP_SPONSORED_SRP_SIZE = 3;
export const CAROUSEL_SPONSORED_SRP_SIZE = 8;

export const DEFAULT_MIN_YEAR = 1970;
export const DEFAULT_MAX_YEAR = new Date().getFullYear() + 1;
export const MYBOATS_USER_COOKIE = 'username';
export const MYBOATS_COOKIE = 'api-session';
export const allTypes = ['power-all','sail-all','small-all'];

export const DEFAULT_NO_MAX_PRICE = '100000';
export const SLIDER_MAX_VALUE = 'MAX';

export const ADS_TIMEOUT = 1000;
export const DEBOUNCE_TIMEOUT = 1000;

export const MAX_MAKE_COUNT = 10;
export const MAX_MODEL_COUNT = 10;

export const INDEXABLE_FACETS = [
  'multiFacetedBoatTypeClass',
  'makeModel',
  'modelRange',
  'state',
  'zip',
  'page',
  'city',
  'group',
  'forSale',
  'fuelType',
  'category',
  'hullMaterial'
];

export const INDEXABLE_PARAMS = [
  'types',
  'classes',
  'make',
  'model',
  'state',
  'city',
  'zip',
  'oem'
];

export const INDEXABLE_FUELS = [
  'electric'
];
export const LISTING_OEM_CAROUSEL = 'manufacturer listing';

export const OEM_CONTACT_MESSAGE = 'Tell us what you are interested in. Pricing? Test drive? Brochures? Type of vessel? Model name?';

export const BRAND_BANNER_IMPRESSION = 'brand banner';

export const MAKE_MODEL_EXEMPTED_SPECIAL_CHARACTERS = '.,!\'$àáèéìíòóúùñäëïöüâêîôû';

export const ADDITIONAL_LEADS_ALERT_CONTENT = {
  success: {
    title: 'Info requested!',
    message: 'The seller will be reaching out to you soon.'
  },
  failure: {
    title: 'Request cannot be completed',
    message: 'The server may be temporarily unavailable.'
  }
};

export const BRANDS_ID = 'brands';
