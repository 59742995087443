import { Action } from '@dmm/lib-react-ui-components';
import classnames from 'classnames';
import debounce from 'lodash/debounce';
import React, { Children, useEffect, useState } from 'react';
import { ToolSetRadioGroup, ToolSetRadioItem } from './ToolSetRadio';
import './styles.css';

const ToolSet = (props) => {

  let { open, children, id } = props;

  return (
    <div id={id} className={classnames('tool-set', { open })}>
      {children}
    </div>
  );
};

const  ToolSetInner = (props) => {

  let { id, children } = props;

  return (
    <div id={id} className="inner">
      {children}
    </div>
  );
};

const ToolSetOptions = (props) => {

  let { children, filterText = '' } = props;
  return (
    <ul className="opts">
      {filterText ? Children.map(children, child => {
        //if there is filter text passed down as a prop
        //pass the filter value down to the ToolSetOptionsItems
        if (child.type.displayName === 'ToolSetOptionsItem') {
          let filtered = !child.props.value.toLowerCase().includes(filterText.toLowerCase());
          return React.cloneElement(child, {
            filtered
          });
        }
        return child;

      }) : children}
    </ul>
  );
};

ToolSetOptions.displayName = 'ToolSetOptions';

const ToolSetOptionsItem = (props) => {

  let { children, id, name, value, trackingData, trackingDataExtra, selected, filtered, type = 'radio', onClick, disabled, optionClass = '' } = props;
  return (
    <li className={classnames({ filtered, selected }, optionClass)}>
      <label htmlFor={id} className="visuallyhidden">
        <span className="txt">{name}</span>
      </label>
      <input id={id} type={type} disabled={disabled} checked={selected}
        value={value} name={name} data-tracking-data={trackingData}
        data-tracking-extra-data={trackingDataExtra} onChange={() => onClick(value)} />
      <div data-custom-option={id} className={classnames('custom-option', { selected })} tabIndex="-1" onClick={() => onClick(value)}>{children}</div>
    </li>
  );
};

ToolSetOptionsItem.displayName = 'ToolSetOptionsItem';

const ToolSetTypeAhead = (props) => {
  const [text, setText ] = useState('');

  const handleChange = (text) =>{
    setText(text);
  };

  let { children } = props;
  return (
    <div className="type-ahead">{Children.map(children, child => {
      if (child.type.displayName === 'ToolSetOptions') {
        //if the child is a ToolSetOptions component,
        //pass down the filter text
        return React.cloneElement(child, {
          filterText: text
        });
      } else if (child.type.displayName === 'ToolSetTextInput') {
        //if the child is a ToolSetTextInput component,
        //add an onChange prop to detect the value of the filter text
        return React.cloneElement(child, {
          onChange: handleChange
        });
      }
      return child;

    })}</div>
  );
};

const ToolSetTextInput = (props) => {
  const { value } = props;
  const [textValue, setTextValue] = useState('');
  const debouncedOnDataChange = debounce((data) => onChange(data), 500);

  const handleDataChange = (text) => {
    if (props.debounce){
      debouncedOnDataChange(text);
    } else {
      onChange(text);
    }
  };

  const onChange = (text)  => {
    props.onChange(text);
    setTextValue(text);
  };

  useEffect(() => {

    if (value !== undefined) {
      setTextValue(value);
    }
  }, [value]);

  const onChangeFilter = (e) => {
    handleDataChange(e.target.value);
  };

  let { id, name, type = 'text', min, max, placeholder = '', icon } = props;
  return (
    <div className={classnames('filter-txt', { 'filter-txt-icon': icon })}>
      <input title={placeholder} data-e2e="srp-filter-input" className="filter-input" type={type} id={id} placeholder={placeholder} min={min} max={max} name={name} value={textValue} onChange={onChangeFilter} />
    </div>
  );
};











ToolSetTextInput.displayName = 'ToolSetTextInput';

const ToolSetButtonTray = (props) =>  {
  let { children, hidden } = props;
  return (
    <div className={classnames('buttons', { hidden })}>
      <div className="btn-container">
        {children}
      </div>
    </div>
  );
};

const ToolSetHeader = (props) => {
  const {onClick } = props;
  return (
    <>
      <div className="tool-set-header">
        <h2 className="tool-set-header-title">Filters</h2>
        <button className="tool-set-btn-close" type="button" aria-label="tool-set-header" onClick={onClick}></button>
      </div>
    </>
  );
};

const ToolSetSearchButton = (props) => {
  const { onClick, isBranded } = props;

  let buttonText = 'Search';
  /* istanbul ignore next */
  if (typeof window !== 'undefined') {
    const currentPath = window.location.pathname;
    if (currentPath.startsWith('/boats')) {
      buttonText = 'View boats';
    } else if (currentPath.startsWith('/engines')) {
      buttonText = 'View engines';
    }
  }

  return (
    <Action
      className={ classnames('tool-set-btn-search', {'tool-set-btn-search-branded': isBranded}) }
      onClick={onClick}
      label={buttonText}
    />
  );
};

export {
  ToolSet, ToolSetButtonTray, ToolSetHeader, ToolSetInner,
  ToolSetOptions,
  ToolSetOptionsItem, ToolSetRadioGroup,
  ToolSetRadioItem, ToolSetSearchButton, ToolSetTextInput, ToolSetTypeAhead
};

