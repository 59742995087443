import React, { useEffect } from 'react';
import { PrivateSellerConnect } from '../../../components/PrivateSellerConnect';
import { makeFinanceableSummaryTrackingEvent } from '../../../utils/trident';
import { getSessionItem } from '../../../utils/storage';
import { handleSignInOrContactForm } from '../../../utils/contactHandler';

const CONTACT_SELLER_SESSION = 'fsbo.contactSellerSession';

export const BDPPrivateConnect = ({
  userEmail,
  userRef,
  disableRequestInfo,
  handleOpenContactForm,
  isConnectSimplifiedExperiment,
  isMessageBeforeLoginExperiment
}) => {

  useEffect(() => {
    const storedContactSession = getSessionItem(CONTACT_SELLER_SESSION);
    storedContactSession && userEmail && handleOpenContactForm('', 'contact seller');
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const trackEvent = (event, actionLabel) => {
    event.preventDefault();
    const contactTracking = makeFinanceableSummaryTrackingEvent(actionLabel, 'click');
    window.dataLayer.push(contactTracking);
  };

  const handleLoginContactButtonClick = (event, actionLabel) => {
    trackEvent(event, actionLabel);

    handleSignInOrContactForm(
      { userEmail, userRef, handleOpenContactForm },
      event,
      isMessageBeforeLoginExperiment
    );
  };


  return (
    <>
      <div id="prequalified-and-contact-seller" className="info-container">
        <PrivateSellerConnect
          handleLoginContactButtonClick={handleLoginContactButtonClick}
          disableRequestInfo={disableRequestInfo}
          isConnectSimplifiedExperiment={isConnectSimplifiedExperiment}
        />
      </div>
    </>
  );
};
