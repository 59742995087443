import { Ad, NativeAd } from '@dmm/lib-react-ui-components';
import React from 'react';

const mapImageAd = (ad) => {
  return {
    imageUrl: ad.image_url,
    altText: ad.alt_text,
    redirectUrl: ad.redirect_url,
    eligibleUrl: ad.eligible_url,
    accupixelUrl: ad.accupixel_url,
    viewableUrl: ad.viewable_url,
    htmlBody: ad.body,
    target: ad.target,
    maxHeight: ad.height,
    maxWidth: ad.width
  };
};

const ServerAd = ({ad, className, closeSelector}) => {
  let adData = {};
  if (!ad?.placement_id) {
    return <></>;
  }
  adData = mapImageAd(ad);
  return (
    <>
      {adData.htmlBody ?
        <NativeAd {...adData} closeSelector={closeSelector} /> :
        <Ad {...adData} maxWidth={ad.width} maxHeight={ad.height} className={className} closeSelector={closeSelector} />
      }
    </>
  );
};

export default ServerAd;
