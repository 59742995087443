import React from 'react';
import { BOAT_LOANS_RATES_INFO_1, BOAT_LOANS_RATES_INFO_2, BOAT_LOANS_RATES_INFO_3, CDN_RATES_URL } from '../../../../constants/BoatLoans';
import { ApplyNowButton } from '../ApplyNowButton';
import './styles.css';


const backgroundImageUrl1 = `${CDN_RATES_URL}${BOAT_LOANS_RATES_INFO_1}`;
const backgroundImageUrl2 = `${CDN_RATES_URL}${BOAT_LOANS_RATES_INFO_2}`;
const backgroundImageUrl3 = `${CDN_RATES_URL}${BOAT_LOANS_RATES_INFO_3}`;

export const InfoSection = ({ dataTestId = 'boat-loans-rates-info-section-component' }) => {
  return (
    <div className="boat-loans-rates-info-component" data-testid={dataTestId}>
      <div className="boat-loans-rates-info-container bg-dark-blue" >
        <div className="rates-info-content">
          <img className="rates-info-image" alt="Factors affecting boat loan interest rates" src={backgroundImageUrl1} />
          <div>
            <h2 className="title">
              Factors That Influence Boat Loan Interest Rates
            </h2>
            <div className="info-text">
              Boat loan interest rates can vary widely based on several key elements:
              <ol>
                <li>
                  <span className="bullet-title">Credit Score</span>: Your credit score is one of the most critical determinants of the interest rate you&apos;ll receive.
                  Higher credit scores typically result in lower interest rates as lenders view you as a lower risk. A lower score can lead to higher rates or more challenging approval conditions.
                </li>
                <li>
                  <span className="bullet-title">Loan Amount and Loan-to-Value (LTV) Ratio</span>: The size of the loan and the boat&apos;s value also play a role. Lenders often look at the loan-to-value (LTV),
                  which measures the loan amount relative to the boat&apos;s value. Most lenders prefer an LTV ratio of 85% or lower.
                </li>
                <li>
                  <span className="bullet-title">Loan Term</span>: The length of the loan, whether it&apos;s 5, 10, or even 20 years, will significantly influence the monthly payments and the total interest cost.
                  Shorter loan terms usually come with lower interest rates but higher monthly payments, while longer terms offer lower monthly payments but can lead to higher
                  overall interest expenses.
                </li>
                <li>
                  <span className="bullet-title">Age of Boat</span>: Newer boats often qualify for lower interest rates, while older boats can attract higher rates due to perceived risk in their condition and resale value.
                  Please contact us for rates on boats over 20 years of age.
                </li>
                <li>
                  <span className="bullet-title">Lender</span>: Different lenders may offer varying interest rates based on their expertise and lending criteria. Specialized boat loan lenders may offer more competitive
                  rates than general financial institutions.
                </li>
                <li>
                  <span className="bullet-title">Market Conditions</span>: Economic factors such as inflation, recession, and interest rate changes can also impact boat loan rates.
                </li>
              </ol>

              <div className="paragraph">
                To get the best rate possible, consider improving your credit score, saving for a larger down payment, and comparing offers from multiple lenders.
              </div>

              <div className="button-container right">
                <ApplyNowButton
                  text="Get Pre-Qualified"
                  type="button"
                  utmCampaign="bt-rates-calc-pg"
                  utmContent="bt-rates-calc-pg"
                  trackingEvent={{
                    'action_label': 'factors that influence boat loan interest rates'
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="boat-loans-rates-info-container" >
        <div className="rates-info-content">
          <div className="info-text">
            <h2 className="title">
              Current Boat Loan Rate Examples
            </h2>
            <div className="info-text">
              Below are some recent boat loan rate examples based on different credit score ranges and loan terms:
              <ul className="rate-examples-list">
                <li>20-Year Boat Loan Rate Examples:</li>
                <li>Credit Score 800-850: Approximately 7.87%</li>
                <li>Credit Score 760-790: Approximately 8.12%</li>
                <li>10-Year Boat Loan Rate Examples:</li>
                <li>Credit Score 800-850: Approximately 6.25%</li>
                <li>Credit Score 760-790: Approximately 6.50%</li>
              </ul>
              Rates above are as of September 2023. These indicative rates may vary depending on the lender and market conditions.
            </div>
          </div>
        </div>
      </div>


      <div className="boat-loans-rates-info-container bg-light-blue" >
        <div className="rates-info-content">
          <img className="rates-info-image" alt="Boat loan rates" src={backgroundImageUrl2} />
          <div className="info-text">
            <h2 className="title">
              Comparing Boat Loan Rates - The Importance of Shopping Around for Rates
            </h2>
            <div className="info-text">
              <div className="paragraph">
                Given the variations in loan rates across lenders, shopping around is essential.
                Some lenders specialize in marine financing and may have more attractive rates and flexible terms for boat purchases.
                Comparing multiple offers can help you secure the most favorable financing option that suits your needs.
              </div>
              <div className="paragraph">
                To illustrate, current boat loan interest rates can range from 4% to 12% or more, depending on the above mentioned factors.
                Rates may be lower for borrowers with excellent credit and substantial down payments.
                Conversely, those with weaker credit profiles or seeking higher LTV ratios may face higher interest rates.
              </div>
              <div className="button-container">
                <ApplyNowButton
                  text="Get Pre-Qualified"
                  type="button"
                  utmCampaign="bt-rates-calc-pg"
                  utmContent="bt-rates-calc-pg"
                  trackingEvent={{
                    'action_label': 'comparing boat loan rates'
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="boat-loans-rates-info-container" >
        <div className="rates-info-content">
          <img className="rates-info-image" alt="Tips securing best loan rate" src={backgroundImageUrl3} />
          <div className="info-text">
            <h2 className="title">
              Tips for Securing the Best Boat Loan Rate
            </h2>
            <div className="info-text">
              To improve your chances of getting the best rates, consider the following strategies:              <ol>
                <li>
                  <span className="bullet-title">Maintain a High Credit Score</span>: Check your credit report and correct any inaccuracies. Aim to keep your credit score in the excellent range (generally 740 and above).
                </li>
                <li>
                  <span className="bullet-title">Reduce Your Debt-to-Income Ratio</span>: Paying down existing debts can improve your DTI ratio, making you a more attractive borrower.
                </li>
                <li>
                  <span className="bullet-title">Save for a Larger Down Payment</span>: A down payment of 20% or more can significantly improve your LTV ratio, qualifying you for better rates.
                </li>
                <li>
                  <span className="bullet-title">Compare Offers from Multiple Lenders</span>: Each lender has unique criteria and offers. Look beyond traditional banks and consider credit unions
                  or specialized marine lenders for more competitive terms.
                </li>
                <li>
                  <span className="bullet-title">Consider the Total Cost of the Loan</span>: When evaluating offers, be sure to account for any additional fees or charges.
                  Sometimes, a slightly higher interest rate may come with fewer fees, lowering the total cost.
                </li>
              </ol>
              <div className="button-container right">
                <ApplyNowButton
                  text="Get Pre-Qualified"
                  type="button"
                  utmCampaign="bt-rates-calc-pg"
                  utmContent="bt-rates-calc-pg"
                  trackingEvent={{
                    'action_label': 'tips for securing the best boat loan rate'
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};
