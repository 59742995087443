export const redirectAuthenticatedUser = (isAuthenticated) => {
  let redirectUrl = window.sessionStorage.getItem('userflow_redirectUrl');

  if (!redirectUrl) {
    redirectUrl = window.location.origin; //redirect to homepage
  }

  if (isAuthenticated) {
    window.sessionStorage.removeItem('userflow_redirectUrl');
    window.location.href = redirectUrl;
  }
};
