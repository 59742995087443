import React, {
  useState,
  useEffect,
  useCallback,
  memo
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import {trackProductImpression} from '../utils/sbpHelper';
import SocialShare from '../../../components/SocialShare';
import SocialContactForm from '../SocialContactForm';
import { setListingView, setProduct } from '../../../store/actions/dataLayer';
import { buildProduct } from '../../../utils/dataLayerHelper';
import SocialCarouselConveyor from '../SocialCarouselConveyor';
import SBPEmailLeadForm from '../SBPEmailLeadForm';
import { useFeatureFlags } from '../../../utils/experiment/kameleoonHelper';
import './styles.css';


const SocialPageContent = ({ sbpListings }) => {
  const dispatch = useDispatch();
  const isPageViewSent = useSelector((state) => get(state, 'dataLayer.events', []).find((item) => item.eventType === 'pageview') );
  const [, setImpressions] = useState([]);
  const [shareListingIndex, setShareListingIndex] = useState();
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [, setAction] = useState([]);
  const [isOpenShare, setIsOpenShare] = useState(false);

  const { featureFlagSBPNewLeadFormPhoneRequired } = useFeatureFlags();

  useEffect(() => {
    if (isPageViewSent){
      handleUserEvent(get(sbpListings, '0'));
    }
  }, [isPageViewSent]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const preventLeave = (event) => {
      event.preventDefault();
      event.returnValue = 'Are you sure?';
    };

    window.addEventListener('beforeunload', preventLeave, { passive: false });

    return () => {
      window.removeEventListener('beforeunload', preventLeave);
    };
  }, []);

  const handleUserEvent = useCallback((listing) => {
    if (!listing) {
      return;
    }
    setAction((currentEvents) => {
      if (currentEvents.includes(listing.id)) {
        return currentEvents;
      }
      dispatch(setProduct(buildProduct(listing, 'boat')));
      dispatch(setListingView(listing.id));
      currentEvents.push(listing.id);
      return currentEvents;
    });
  }, [sbpListings]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleProductImpression = (index) => {
    trackProductImpression(index, setImpressions, sbpListings, dispatch);
  };

  const openShareModal = useCallback((index, listing) => {
    setShareListingIndex(index);
    setIsOpenShare(true);
    handleUserEvent(listing);
  }, [sbpListings]); // eslint-disable-line react-hooks/exhaustive-deps

  const closeShareModal = () => {
    setIsOpenShare(false);
    setShareListingIndex(null);
  };


  const handleCarouselSpin = useCallback((index) => {
    handleProductImpression(index);
  }, [sbpListings]); // eslint-disable-line react-hooks/exhaustive-deps

  const openContactForm = useCallback((e, index, listing) => {
    e.preventDefault();
    setShareListingIndex(index);
    setIsOpenForm(true);
    handleUserEvent(listing);
  }, [sbpListings]); // eslint-disable-line react-hooks/exhaustive-deps

  const closeContactForm = () => {
    setShareListingIndex(null);
    setIsOpenForm(false);
  };

  const selectedListing = get(sbpListings, `${shareListingIndex}`, false);

  return (
    <>
      <SocialCarouselConveyor
        listings={sbpListings}
        openContactForm={openContactForm}
        handleUserEvent={handleUserEvent}
        openShareModal={openShareModal}
        handleCarouselSpin={handleCarouselSpin}
      />

      { selectedListing && <SocialShare
        isBdp={false}
        openShareModal={isOpenShare}
        handleShareButton={closeShareModal}
        type="overlay"
        listing={selectedListing}
        showMoreBoats={false}
        breakpoint={'mobile'}
        handleShareToggle={ closeShareModal }
      /> }

      { selectedListing && !featureFlagSBPNewLeadFormPhoneRequired && <SocialContactForm listing={selectedListing} isOpen={isOpenForm} closeForm={closeContactForm} isSBP/> }
      {selectedListing && isOpenForm && featureFlagSBPNewLeadFormPhoneRequired &&
        <div className="sbp-email-lead-modal">
          <SBPEmailLeadForm  listing={selectedListing} onClose={closeContactForm}  buttonAttributes={{ 'data-testing-class': 'form-submission-mobile-sbp-email-lead-phone-required' }}/>
        </div>
      }
    </>
  );
};

export default memo(SocialPageContent, isEqual);
