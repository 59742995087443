import { setSessionItem } from './storage';
import * as buyerAccountLoginUtils from './fsbo/buyerAccountLogin';

export const handleSignInOrContactForm = (
  {
    userEmail,
    userRef,
    handleOpenContactForm,
  },
  event,
  isMessageBeforeLoginExperiment
) => {
  const contactFormOptions = { dataTestingClass: { suffix: 'contact-modal' } };

  if (!userEmail && !isMessageBeforeLoginExperiment) {
    userRef?.current?.onOpenModal();
    setSessionItem(buyerAccountLoginUtils.getSessionKey(), true);
  } else {
    handleOpenContactForm(event, contactFormOptions);
  }
};
