import React, { PureComponent } from 'react';
import get from 'lodash/get';
import CarouselDisplayer from '../../../components/CarouselDisplayer';
import BoatSummary from '../../../components/BoatSummary';
import DetailsContact from '../DetailsContact';
import { getBreakpoint } from '../../../utils/commonHelper';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setProduct } from '../../../store/actions/dataLayer';
import { buildProduct } from '../../../utils/dataLayerHelper';
import ScrollManager from '../../../components/ScrollManager';
import Sticky from '../../../components/Sticky';

class DatalinkContent extends PureComponent {
  state = {
    openDetailsSection: false,
    breakpoint: 'desktop'
  }

  handleSummaryPaymentLinkClicked = (componentCounter) => {
    this.setState({
      openPaymentComponent: 'payment-calculator',
      openPaymentComponentCounter: componentCounter
    });
  }

  handleSummaryLocationLinkClicked = (componentCounter) => {
    this.setState({
      openLocationComponent: 'location-map',
      openLocationComponentCounter: componentCounter
    });
  }

  handleBoatSummaryScroll = (scrolledPastBoatSummary) => {
    this.setState({
      scrolledPastBoatSummary: scrolledPastBoatSummary
    });
  }

  resizeHandler = () => {
    this.setState({
      breakpoint: getBreakpoint()
    });
  }

  componentDidMount() {
    window.addEventListener('resize', this.resizeHandler);
    this.resizeHandler();
    let {listing} = this.props;
    if (listing && listing.id) {
      this.props.setProduct(buildProduct(listing, 'boat'));
    }
  }

  componentDidUpdate(prevProps) {
    let { listing } = this.props;
    let prevListingId = get(prevProps, 'listing.id');
    if (listing && listing.id && prevListingId !== listing.id) {
      this.props.setProduct(buildProduct(listing, 'boat'));
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeHandler);
  }

  render() {
    const { listing, isWorking } = this.props;

    return (
      <>
        <ScrollManager />
        <div className="boat-details">
          <div className="top">
            <div className="carousel-wrapper">
              <CarouselDisplayer isLoading={isWorking} listing={listing} />
            </div>
            { this.state.breakpoint === 'desktop' && <DetailsContact isLoading={isWorking} listing={listing}
              disableSticky={true} initialState="open" disableSellerInfo={true} /> }
          </div>
          <div className="body">
            <div className="content">
              { this.state.breakpoint === 'mobile' && <DetailsContact isLoading={isWorking}
                listing={listing} disableSellerInfo={true} /> }
              <BoatSummary
                isLoading={isWorking}
                listing={listing}
                onClickPaymentLink={this.handleSummaryPaymentLinkClicked}
                onClickLocationLink={this.handleSummaryLocationLinkClicked} />
              <Sticky position="top" offset={51} onChange={this.handleBoatSummaryScroll} />
            </div>
          </div>
          { this.state.breakpoint === 'tablet' &&
            <DetailsContact isLoading={isWorking} listing={listing} disableSellerInfo={true} /> }
        </div>
      </>
    );
  }
}

export default connect(
  null,
  dispatch => bindActionCreators({
    setProduct
  }, dispatch)
)(DatalinkContent);
