import React, {useRef, useEffect} from 'react';
import ServerAd from '../../../components/ServerAd';
import isEmpty from 'lodash/isEmpty';

const updateRightAdHeight = (rightAd) => {
  const leftSide = document.querySelector('.broker-bdp-left .content');
  if (leftSide) {
    const rectLeft = leftSide.getBoundingClientRect();
    const rectAd = rightAd.getBoundingClientRect();
    const offset = rectAd.top - rectLeft.top;
    const newHeight = rectLeft.height - offset;
    rightAd.style.height = newHeight + 'px';
  }
};

const ServerAds = React.memo(({ serverAds }) => {
  const { ['boat-details_box-1']: boxOneValue, ['boat-details_box-2']: boxTwoValue, ['boat-details_box-3']: boxThreeValue } = serverAds;
  return (
    <>
      <div><ServerAd ad={boxOneValue} className="box-ad" /></div>
      <div className="sticky-ads">
        <div><ServerAd ad={boxTwoValue} className="box-ad" /></div>
        <div><ServerAd ad={boxThreeValue} className="box-ad" /></div>
      </div>
    </>
  );
});

ServerAds.displayName = 'ServerAds';



const BDPDesktopAds = ({addParams, show, noAdsParam, serverAds}) => {
  const {
    ['boat-details_box-1']: ad1,
    ['boat-details_box-2']: ad2,
    ['boat-details_box-3']: ad3,
  } = serverAds || {};
  const emptyAds = (isEmpty(ad1) && isEmpty(ad2) && isEmpty(ad3));
  if (!show || noAdsParam || !addParams || emptyAds) {
    return null;
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const adsRef = useRef(null);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    updateRightAdHeight(adsRef.current);
  }, [addParams]);


  return (
    <div className="right-ad" ref={adsRef}>
      {serverAds && <ServerAds serverAds={serverAds}/>}
    </div>
  );
};

export default BDPDesktopAds;
