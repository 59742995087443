import classnames from 'classnames';
import get from 'lodash/get';
import React, { Children, useEffect, useRef, useState } from 'react';
import { isOuterClick } from '../../utils';
import './details.css';
import './styles.css';

const CollapsibleContent = (props) => {
  const [open, setOpen] = useState(props.initialState === 'open');
  const [openCounter, setOpenCounter] = useState(0);
  const ref = useRef(null);

  useEffect(() =>{
    document.addEventListener('click', handleDocumentClick);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  useEffect(()=> {
    if (props.open === 'open' && open !== 'open' && openCounter !== props.openCounter){
      setOpen(props.open);
      setOpenCounter(props.openCounter);
      if (props.onOpen){
        props.onOpen();
      }
    }
  }, [props, open, openCounter]);

  const handleDocumentClick = (e) => {
    let { closeOnOuterClick } = props;
    if (!closeOnOuterClick) {
      return;
    }
    const outerClicked = isOuterClick(e.target, ref.current);
    if (outerClicked) {
      setOpen(false);
    }
  };

  const isInnerClick = (element) => {
    let target = element;
    while (target){
      if (target.classList.contains('collapsible')) {
        return true;
      }
      if (target === ref.current) {
        return false;
      }
      target = target.parentNode;
    }
  };

  const handleInnerClick = (e) => {
    let { closeOnInnerClick } = props;
    if (!closeOnInnerClick) {
      return;
    }
    const innerClicked = isInnerClick(e.target);
    if (innerClicked) {
      setOpen(false);
    }
  };

  const handleToggle = (e, child) => {
    if (child.props.onClick){
      child.props.onClick(e, !open);
    }
    setOpen(!open);
    if (!open && props.onOpen){
      props.onOpen();
    }
  };

  let { children, id, extraClasses, testid, testsp } = props;
  let mainClassName = 'collapse-content';
  if (props.classOverride) {
    mainClassName += `-${props.classOverride}`;
  }

  return (
    <div ref={ref} className={classnames(mainClassName, extraClasses, { open, closed: !open })} id={id} data-testid={testid} data-test-sp={testsp}  onClick={handleInnerClick}>
      {Children.map(children, child => {
        //pass down an onClick function to children that are
        //of type CollapsibleHeader
        if (get(child, 'type.displayName') === 'CollapsibleHeader') {
          return React.cloneElement(child, {
            onClick: (e) => handleToggle(e, child)
          });
        }
        return child;

      })}
    </div>
  );
};

const CollapsibleHeader = (props) => {

  let { children, type, empty, onClick } = props;
  return (
    <div className={classnames('header', { [type]: type, empty })} onClick={onClick}>
      { empty ? children :
        <h3>
          <a className="toggle-btn" data-e2e="srp-toggle-btn">{children}<span className="icon"></span></a>
        </h3>
      }
    </div>
  );
};

CollapsibleHeader.displayName = 'CollapsibleHeader';

const Collapsible = (props) => {
  let { children, type, doubleHeight } = props;
  return (
    <div className={classnames('collapsible', {'double-height': doubleHeight}, {[type]: type })}>
      {children}
    </div>
  );
};

export {
  Collapsible, CollapsibleContent,
  CollapsibleHeader
};

