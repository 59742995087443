import { EmailLeadForm } from '@dmm/lib-react-ui-components';
import classNames from 'classnames';
import { get } from 'lodash';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ReCaptchaButtonWrapper, executeRecaptcha } from '../../../components/ReCaptchaButtonWrapper';
import ReCaptchaV2 from '../../../components/RecaptchaV2';
import { withABTest } from '../../../server/abTest/withABTest';
import { addLead, trackPhoneCall, trackContactFormSubmit } from '../../../store/actions/dataLayer';
import { prepareLeadData, sendLead } from '../../../utils/api/leadHelper';
import * as captchaHelper from '../../../utils/api/verifyRecaptchaHelper';
import { BRANCHIO_CONTENT_TYPES, BRANCH_EVENT, branchIoEventsManager } from '../../../utils/branchIoEventsManager';
import validate from '../../../utils/dataValidation';
import useBranchIoMetaTag from '../../../utils/hooks/useBranchIoMetaTag';
import { getTitle, isFSBOContact } from '../../../utils/listingHelper';
import { nil } from '../../../utils/runOnceHelper';
import { handleKameleoonGoal } from '../../../utils/experiment/kameleoonHelper';
import { GOAL_ALL_SBP_EMAIL_LEAD_FORM } from '../../../constants/goals';
import { BOAT_DETAIL_CTA_LABEL } from '../../../constants/boatDetails';



//TODO: DO I need captcha
const recaptchaV2Key = process.env.REACT_APP_GOOGLE_RECAPTCHAV2_KEY_ANTISPAM;
const captchaAction = 'BDP_LEAD_SUBMIT';

const SBPEmailLeadForm = ({
  contactFormPosition = 'sbp-form-',
  listing,
  initialValues,
  onSuccess = nil,
  onError = nil,
  hideForm = false,
  onClose,
  buttonAttributes = {},
}) => {

  const { BranchIoMetaTagComponent, fireBranchioMetaTag } = useBranchIoMetaTag();



  const listingTitle = getTitle(listing);
  const [name, setName] = useState(initialValues?.name || '');
  const [email, setEmail] = useState(initialValues?.email || '');
  const [phone, setPhone] = useState(initialValues?.phone || '');
  const [zip, setZip] = useState(initialValues?.zip || '');
  const [message, setMessage] = useState(`I\'m interested in getting more information about your ${listingTitle}. Please contact me.`);
  const [invalidFields, setInvalidFields] = useState([]);
  const [showReCaptchaV2, setShowReCaptchaV2] = useState(false);
  const [leadData, setLeadData] = useState({});
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);

  const dispatch = useDispatch();

  const handlePhoneCall = () => {
    dispatch(trackPhoneCall());
    dispatch(addLead(listing.Id, 'phone call'));
  };


  const addEmailLeadData = (listingId) => {
    dispatch(addLead(listingId, 'email lead'));
  };


  const updateInvalidFields = (fieldType, isValid) => {
    if (isValid) {
      setInvalidFields(invalidFields.filter(field => field !== fieldType));

    } else {
      if (!invalidFields.includes(fieldType)) {
        const newInvalidFields = [...invalidFields, fieldType];
        setInvalidFields(newInvalidFields);
      }
    }
  };

  const getInvalidKey = (val, key, required)  => {
    return validate(val, key, required) ? '' : key;
  };


  const validateAllFields = (listing) => {

    const fieldsToValidate = [
      { value: name, type: 'name', required: true },
      { value: email, type: 'email', required: true },
      { value: message, type: 'text', required: true }
    ];

    if (listing?.owner?.leadValidation) {
      // only include when enabled in provisioning
      if (listing.owner.leadValidation.phoneValidation) {
        fieldsToValidate.push({ value: phone, type: 'phone', required: true });
      }
    } else {
      console.log('Lead validation not enabled for this listing');
      // include by default
      fieldsToValidate.push({ value: phone, type: 'phone', required: true });
    }

    if (listing.isOemModel) {
      fieldsToValidate.push({ value: zip, type: 'zip', required: true });
    }

    return fieldsToValidate.map(({ value, type, required }) => getInvalidKey(value, type, required)).filter(val => val !== '');
  };

  const setDefaultFormStatus = () => {
    setName('');
    setEmail('');
    setPhone('');
    setZip('');
    setLeadData({});
    setMessage(`I\'m interested in getting more information about your ${listingTitle}. Please contact me.`);
    setInvalidFields([]);
    setShowError(false);
    setShowReCaptchaV2(false);
    setSubmitButtonDisabled(false);
  };

  const setLeadSuccessState = (ld = leadData) => {
    setDefaultFormStatus();
    setShowSuccess(true);
    onSuccess(ld);
  };

  const setLeadErrorState = () => {
    setShowError(true);
    onError();
  };

  const handleSubmit = async () => {
    const checkedInvalidFields = validateAllFields(listing);
    const isValidForm = checkedInvalidFields.length === 0;
    if (!isValidForm) {
      setInvalidFields(checkedInvalidFields);
      return;
    }
    setSubmitButtonDisabled(true);
    fireBranchioMetaTag(BRANCHIO_CONTENT_TYPES.CONTACT_SELLER);
    const formFields = {
      name,
      email,
      phone,
      zip,
      comments: message
    };
    const ld = prepareLeadData(listing, formFields, 'BoatTrader Social Browse');
    setLeadData(ld);
    const isCaptchaSucceed = await captchaHelper.passCaptchaChallenge(ld, captchaAction, executeRecaptcha);
    if (!isCaptchaSucceed) {
      setShowReCaptchaV2(true);
      return;
    }
    const isLeadSent = await sendLeadSucceed(ld);
    setSubmitButtonDisabled(false);
    if (isLeadSent) {
      const dataTestingClass = buttonAttributes['data-testing-class'];
      handleKameleoonGoal(GOAL_ALL_SBP_EMAIL_LEAD_FORM, dataTestingClass);
      setLeadSuccessState(ld);
      window.kameleoonQueue.push(['Goals.processConversion', 'sbp-email-lead-phone-required']);
    } else {
      setLeadErrorState();
    }
  };

  const validateAndUpdateField = (fieldType, fieldValue, isRequired = false) => {
    const isValid = validate(fieldValue, fieldType, isRequired);
    updateInvalidFields(fieldType, isValid);
  };

  const handleRecaptchaV2Change = async (success) => {
    if (success) {
      const isLeadSent = await sendLeadSucceed(leadData);
      if (isLeadSent) {
        setLeadSuccessState();
      } else {
        setLeadErrorState();
      }
    }
  };

  const trackContactFormSubmitData = (resp) => {
    dispatch(trackContactFormSubmit(
      {'leadId': get(resp, 'data.id') }
    ));
  };

  const sendLeadSucceed = (leadData) => {
    const { id: listingId } = leadData;
    return sendLead(leadData).then(resp => {
      if (resp.status === 200) {
        addEmailLeadData(listingId);
        trackContactFormSubmitData(resp);
        fireBranchioMetaTag(BRANCHIO_CONTENT_TYPES.CONTACT_SUBMITTED);
        branchIoEventsManager(BRANCH_EVENT.CONTACT_SUBMIT, {
          sku: get(listing, 'id', ''),
          product_name: get(listing, 'model', ''),
          product_brand: get(listing, 'validMake', ''),
        });
        return true;
      }
      return false;
    }).catch(() => {
      return false;
    });
  };


  const address = listing.contact?.address || {};
  const addressString = `${address.street || ''}, ${address.city || ''}, ${address.state || ''}, ${address.zip || ''}`;
  return (
    <div data-testid="sbp-email-lead-form"  data-e2e="sbp-email-lead-form" className={classNames('email-lead-form', {'hidden': hideForm})}>
      <button className="close-modal" onClick={onClose} />
      <BranchIoMetaTagComponent />
      <ReCaptchaButtonWrapper>
        <EmailLeadForm
          portal="bt"
          sellerName={{
            name: isFSBOContact(listing.contact) ? BOAT_DETAIL_CTA_LABEL : 'Contact ' + listing.contact?.name,
            address: isFSBOContact(listing.contact) ? get(listing.contact, 'name') : addressString,
            phoneNumber: listing.contact?.phone || '',
            onClick: (e) => handlePhoneCall(false, e)
          }}
          name={{
            id: `${contactFormPosition}name`,
            name: 'name',
            value: name,
            onChange: (e) => setName(e.target.value),
            onBlur: (e) => validateAndUpdateField('name', e.target.value),
          }}
          email={{
            id: `${contactFormPosition}email`,
            name: 'email',
            value: email,
            onChange: (e) => setEmail(e.target.value),
            onBlur: (e) => validateAndUpdateField('email', e.target.value),
          }}
          phone={{
            id: `${contactFormPosition}phone`,
            label: 'Your Phone',
            name: 'phone',
            value: phone,
            onChange: (e) => setPhone(e.target.value),
            onBlur: (e) => validateAndUpdateField('phone', e.target.value, get(listing, 'owner.leadValidation.phoneValidation', true)),
          }}
          zip={{
            id: listing.isOemModel ? `${contactFormPosition}zip` : '',
            name: listing.isOemModel ? 'zip' : '',
            value: listing.isOemModel ? zip : '',
            onChange: listing.isOemModel ? (e) => setZip(e.target.value) : '',
            onBlur: listing.isOemModel ? (e) => validateAndUpdateField('zip', e.target.value) : '',
          }}
          textarea={{
            name: 'text',
            value: message,
            id: `${contactFormPosition}emailLeadForm`,
            onChange: (e) => setMessage(e.target.value),
            onBlur: (e) => validateAndUpdateField('text', e.target.value),
          }}
          invalidFields={invalidFields}
          successMessage="Congratulations! We have sent your information directly to the seller."
          showSuccessMessage={showSuccess}
          showErrorMessage={showError}
          buttonDisabled={submitButtonDisabled}
          buttonAttributes={buttonAttributes}
          onSubmit={handleSubmit}>
          { showReCaptchaV2 && (
            <div data-testid="recaptcha-wrapper" className="recaptcha-wrapper">
              <ReCaptchaV2
                sitekey={recaptchaV2Key}
                onChange={handleRecaptchaV2Change}
              />
            </div>
          ) }
        </EmailLeadForm>
      </ReCaptchaButtonWrapper>
    </div>
  );
};


export default withABTest(SBPEmailLeadForm);
