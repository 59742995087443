import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import find from 'lodash/find';
import { useSelector } from 'react-redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import {
  getLocation,
  getPrice,
  getTitle,
  getThumbnailUrl,
  calculateBestResizeParams
} from '../../../utils/listingHelper';
import { BOATS_NEAR_YOU_LISTING_TYPE } from '../../../constants/home';
import LikedBoat from '../../../components/LikedBoat';
import './styles.css';
import {
  setProductImpression,
  addProductClick,
  registerProductImpressions,
  addSaveBoat
} from '../../../store/actions/dataLayer';
import useBranchIoMetaTag from '../../../utils/hooks/useBranchIoMetaTag';
import { BRANCHIO_CONTENT_TYPES, BRANCH_EVENT, branchIoEventsManager } from '../../../utils/branchIoEventsManager';
import { addLead } from '../../../store/actions/dataLayer';
import { likeBoat, unlikeBoat } from '../../../store/actions';
import * as utils from '../../../store/utils';

const BoatNearYou = ({ cookies, listing, position, prioritizeImageDownload}) => {
  const dispatch = useDispatch();
  const { BranchIoMetaTagComponent, fireBranchioMetaTag } = useBranchIoMetaTag();
  const [key, setKey] = useState(`ssr-${listing.id}`);
  const [liked, setLiked] = useState(null);
  const myboats = useSelector((state) => state.app.myboats);
  const userRef = useSelector((state) => state.app.userRef);

  useEffect(() => {
    dispatch(setProductImpression(String(listing.id), BOATS_NEAR_YOU_LISTING_TYPE, position, undefined, undefined, undefined, undefined, undefined, getTitle(listing), listing.make));
    dispatch(registerProductImpressions());
    setKey(`csr-${listing.id}`);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => updateLikedState(), [myboats]);

  const image = find(listing.media, { mediaType: 'image' }) || {};
  const resizeParams = calculateBestResizeParams(get(window,'innerWidth'));
  const thumbnailUrl = getThumbnailUrl(image.url, get(listing, 'date.modified'), resizeParams);
  const title = getTitle(listing);
  const listingId = listing.aliases ? listing.aliases['boat-trader'] : undefined;

  const handleClick = () => {
    dispatch(addProductClick(listing.id, BOATS_NEAR_YOU_LISTING_TYPE, '', '', '', '', title, position, listing.make));
  };

  const onClickLike = () => {
    const imtId = listing.aliases?.imt;
    if (!liked) {
      fireBranchioMetaTag(BRANCHIO_CONTENT_TYPES.SAVE_BOAT);
    }
    if (myboats.requireLogin) {
      queueLikedBoat(listingId);
      forwardToLogin();
    } else {
      if (!liked) {
        setLiked(true);
        addBoatToMyBoats(listingId);
        dispatch(addLead(imtId, 'save boat'));
        addSaveBoat();
      } else {
        setLiked(false);
        removeBoatFromMyBoats(listingId);
      }
    }
    return false;
  };

  const getLikedState = ()=> {
    if (utils.isServer()) {
      return false;
    }
    if (myboats.requireLogin) {
      const queuedBoats = getQueuedBoatsFromLocalStorage();
      return queuedBoats.includes(listingId);
    }
    return myboats.listings.some(
      (boat) => parseInt(boat.listingId) === parseInt(listingId)
    );
  };

  const getQueuedBoatsFromLocalStorage = () => JSON.parse(localStorage.getItem('QUEUED_LIKED_BOATS')) || [];

  const updateLikedState = ()=> setLiked(getLikedState());

  const queueLikedBoat = (listingId) => {
    const queuedBoats = getQueuedBoatsFromLocalStorage();
    if (!queuedBoats.includes(listingId)) {
      queuedBoats.push(listingId);
      setLiked(true);
    } else {
      queuedBoats.splice(queuedBoats.indexOf(listingId), 1);
      setLiked(false);
    }
    localStorage.setItem('QUEUED_LIKED_BOATS', JSON.stringify(queuedBoats));
  };

  const forwardToLogin = () => {
    userRef.current.onOpenModal();
  };

  const addBoatToMyBoats = (listingId) => {
    let data = {listingId: listingId};
    dispatch(likeBoat(data));
    branchIoEventsManager(BRANCH_EVENT.LIKE_BOAT, {
      sku: get(listing, 'id', ''),
      'product_name': get(listing, 'model', ''),
      'product_brand': get(listing, 'validMake', ''),
    });
  };

  const removeBoatFromMyBoats = (listingId) => {
    dispatch(unlikeBoat(listingId));
  };

  return (<>
    <BranchIoMetaTagComponent />
    <div className="inner" key={key} onClick={handleClick}>
      <LikedBoat
        listingId={ get(listing, 'aliases.boat-trader') }
        listing={listing}
        myboats={ myboats }
        imtId={ listing.id }
        cookies={ cookies }
        liked={liked}
        onClickLike={onClickLike}
      />
      <div className="image-container">
        <div className="dummy"></div>
        <div className="image">
          <a
            title={ title }
            aria-label={ title }
            data-reporting-click-product-id={ listing.id }
            data-reporting-click-listing-type={ BOATS_NEAR_YOU_LISTING_TYPE }
            href={ listing.portalLink }
          >
            <div className="preloader"></div>
            <LazyLoadImage
              className="b-lazy image-results b-loaded"
              data-src={ thumbnailUrl }
              src={ thumbnailUrl }
              alt={ title }
              threshold={100}
              fetchPriority={prioritizeImageDownload ? 'high' : 'low'}
            />
            <noscript>
              <img
                className="logo"
                src={ thumbnailUrl }
                alt={ title }
              />
            </noscript>
          </a>
        </div>
      </div>
      <a
        data-boattrader-id={ get(listing, 'aliases.boat-trader') }
        data-reporting-click-product-id={ listing.id }
        data-reporting-click-listing-type={ BOATS_NEAR_YOU_LISTING_TYPE }
        href={ listing.portalLink }
      >
        <div className="media-info" data-e2e="media-info">
          <div className="photo" data-e2e="photo">
            <span className="centred">
              <span className="icon"></span>
              <span className="txt" data-e2e="txt-photo"> Photos</span>
            </span>
          </div>
          <div className="video" data-e2e="video">
            <span className="centred">
              <span className="icon"></span>
              <span className="txt" data-e2e="txt-video"> Video</span>
            </span>
          </div>
        </div>

        <div className="description" data-e2e="description">
          <div className="dummy" data-e2e="dummy"></div>
          <div className="info" data-e2e="info">
            <div className="name" data-e2e="name">{ title }</div>
            <div className="price" data-e2e="price">
              <div className="txt" data-e2e="txt-price">
                <div className="field-name" data-e2e="field-name-price">Price</div>
                <div className="data" data-e2e="data-price">{ getPrice(listing) }</div>
              </div>
            </div>
            <div className="location" data-e2e="location">
              <div className="txt" data-e2e="txt-location">
                <div className="field-name" data-e2e="field-name-location">Location</div>
                <div className="data" data-e2e="data-location">{ getLocation(listing, false, false, true) }</div>
              </div>
            </div>
            <div className="offered-by" data-e2e="offered-by">
              <div className="txt" data-e2e="txt-offered-by">
                <div className="field-name" data-e2e="field-name-offered-by">Offered By</div>
                <div className="data" data-e2e="data-offered-by">
                  { get(listing, 'owner.name') }
                </div>
              </div>
            </div>
            <div className="contact" data-e2e="contact">
              <div className="txt" data-e2e="txt-contact">Contact Seller</div>
            </div>
          </div>
        </div>
      </a>
    </div>
  </>);
};

BoatNearYou.propTypes = {
  cookies: PropTypes.object.isRequired,
  listing: PropTypes.object.isRequired,
};

export default BoatNearYou;
