import axios from 'axios';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import size from 'lodash/size';
import * as utils from '../store/utils';
import * as httpClient from '../utils/httpClient';
import * as types from './constants';

import { Amplify } from '@dmm/lib-react-userflow-components';
import get from 'lodash/get';
import { ENGINES_PAGE_SIZE } from '../constants/Engines';
import { POST_LEAD_SIMILAR_BOATS_MODAL, POST_SUBMISSION_SUCCESS_ALERT_MODAL } from '../constants/boatDetails';
import { BOATS_PAGE_SIZE, MYBOATS_USER_COOKIE, TOP_SPONSORED_SRP_SIZE } from '../constants/boats';
import { TYPE_PORTAL_HEADER, TYPE_PORTAL_HEADER_VALUE, TARGET_ADS_HEADER } from '../constants/services';
import { buildRelatedListingParamsFromListing } from '../utils/api/relatedListings';
import { parseClassNameFromUrl } from '../utils/commonHelper';
import { addAbTestExperimentForGaTracking, FEATURE_KEYS, findFeatureFlagActive, getAdvantageSortVariant, removeAbTestExperimentForGaTracking, updateAbTestFeatureFlagForClientSide } from '../utils/experiment/kameleoonHelper';
import { showSeoContent } from '../utils/seoContentHelper';
import { parseBoatDetailParams } from '../utils/urlHelpers/boat';
import { parseBoatDealersParams } from '../utils/urlHelpers/boatDealers';
import {
  parseSearchEnginesParams,
  parseSearchParams
} from '../utils/urlHelpers/listings';
import { readGroupedUrlParams } from '../utils/urlHelpers/searchResults';
import { addLead, setModalEvent } from './actions/dataLayer';
// eslint-disable-next-line no-unused-vars
import cache from 'memory-cache';
import { cookiesLib } from '../utils/cookies';

const getDataFailure = (err, statusCode) => ({
  type: types.GET_DATA_FAILURE,
  success: false,
  errors: true,
  statusCode: statusCode,
  message: err,
  isWorking: false,
  data: []
});

const getDataSuccess = (data, statusCode) => {
  return {
    type: types.GET_DATA_SUCCESS,
    success: true,
    errors: false,
    statusCode: statusCode,
    message: 'got response of things',
    isWorking: false,
    data: data
  };
};

const getFacetSuccess = (data) => {
  return {
    type: types.GET_FACET_SUCCESS,
    data: data
  };
};

export const handleUserLogin = (cookies, userState) => async dispatch => {
  let userName = get(cookies, 'cookies.' + MYBOATS_USER_COOKIE);
  if (userName && !userState.loggedIn) {
    dispatch(loginUser({userName: userName}));
  } else if (!userName && userState.loggedIn) {
    dispatch(logoutUser());
  }
};

export const loginUser = (data) => {
  return {
    type: types.LOGIN_USER,
    user: data
  };
};

const logoutUser = (data) => {
  return {
    type: types.LOGOUT_USER,
    user: data
  };
};

const setUserRefValue = (userRef) => {
  return {
    type: types.SET_USER_REF_VALUE,
    userRef
  };
};

const userListingsSuccess = (data) => {
  return {
    type: types.MYBOATS_LISTINGS_SUCCESS,
    myboats: data
  };
};

const userListingsFailure = (data) => {
  return {
    type: types.MYBOATS_LISTINGS_FAILURE,
    myboats: data
  };
};

const requireMyboatsLogin = (data) => {
  return {
    type: types.MYBOATS_REQUIRE_LOGIN,
    myboatsLogin: data
  };
};

const getDataRequest = (params) => ({
  type: types.GET_DATA_REQUEST,
  params: params
});

const getFacetRequest = (params) => ({
  type: types.GET_FACET_REQUEST,
  params: params
});

const getEngineFacetsRequest = (params) => ({
  type: types.GET_ENGINES_FACETS_REQUEST,
  params: params
});

const getEngineFacetSuccess = (data) => {
  return {
    type: types.GET_ENGINES_FACETS_SUCCESS,
    data: data
  };
};

const _shouldGetData = () => {
  let lastLocation;
  return (location) => {
    //assume server side render
    if (!lastLocation) {
      lastLocation = location;
      return false;
    }
    let getData = !isEqual(location, lastLocation);
    lastLocation = location;
    return getData;
  };
};

export const shouldGetData = _shouldGetData();

const getSortParam = (value) => ({
  type: types.GET_SORT_PARAM,
  sortBy: value
});

export const getSortByParam = (value) => dispatch => {
  return dispatch(getSortParam(value));
};

export const bypassingUrl = (bypassUrl) => {
  return bypassUrl ? {type: types.BYPASSING_URL, url: bypassUrl} : {type: types.BYPASSED_URL};
};

export const getData = (url, cookies, otherParams, abTestConfiguration) => async (dispatch, getState, http) => {
  otherParams = {
    useServerAds: 'true',
    ...otherParams
  };
  let search = '/app/search/boat';
  let urlParams = parseSearchParams(url);
  const isBrandedSearch = urlParams['oem-model'] === true && urlParams.shouldRemoveOemListing === false;
  urlParams = readGroupedUrlParams(urlParams);
  let variant;
  if (abTestConfiguration){
    //this validation is to only check for the feature flag when the user
    //uses make or type in the filtering, this is required for the current AB test
    if (urlParams.multiFacetedMakeModel || urlParams.multiFacetedBoatTypeClass) {
      //Updating the value of the feature flag, in case the test wasn't triggrered because
      //the user used the filters (client side rendering)
      updateAbTestFeatureFlagForClientSide(FEATURE_KEYS.BOATS_SRP_PRICE_SORT, abTestConfiguration);
      addAbTestExperimentForGaTracking();
      //Getting the correct variant (client or server side)
      const advantageSortFeatureFlag = findFeatureFlagActive(abTestConfiguration, FEATURE_KEYS.BOATS_SRP_PRICE_SORT);
      variant = getAdvantageSortVariant(advantageSortFeatureFlag);
    }
    else {
      removeAbTestExperimentForGaTracking();
    }
  }

  if (urlParams.multiFacetedMakeModel) {
    urlParams.exactMakeMatch = true;
  }

  let params = {
    pageSize: BOATS_PAGE_SIZE,
    page: 1,
    country: 'US',
    facets: [
      'country',
      'state',
      'make',
      'makeModel',
      'class',
      'fuelType',
      'hullMaterial',
      'stateCity',
      'priceRevisedSince',
      'minMaxPercentilPrices',
      'condition',
      ...(isBrandedSearch ? ['model', 'minYear', 'maxYear', 'hullShape', 'enginesConfiguration', 'activities'] : [])
    ].join(','),
    fields: [
      'id',
      'make',
      'model',
      'year',
      'validMake',
      'featureType',
      'isOemModel',
      'showSaveButton',
      'type',
      'class',
      'condition',
      'contact',
      'specifications.dimensions.lengths.nominal.ft',
      'location.address.state',
      'location.address.country',
      'location.address.city',
      'location.address.zip',
      'location.distanceToFilteredLocation',
      'aliases',
      'owner.logos.enhanced',
      'owner.name',
      'owner.rootName',
      'owner.id',
      'owner.hideTridentFeatures',
      'owner.isFinanceAdvantage',
      'price.hidden',
      'price.type.amount.USD',
      'price.discount.amount.USD',
      'price.discount.revisedDate',
      'portalLink',
      'media.0.title',
      'media.0.date.modified',
      'media.0.mediaType',
      'media.0.width',
      'media.0.videoEmbedUrl',
      'media.0.videoVideoThumbnailUri',
      'media.0.url',
      'media.0.original.url',
      'attributes',
      'bestimate'
    ].join(','),
    useMultiFacetedFacets: true,
    sponsoredSize: TOP_SPONSORED_SRP_SIZE,
    ...otherParams,
    ...urlParams
  };

  if (variant){
    params.variant = variant;
  }

  if (urlParams.city && urlParams.city.split(',').length === 1) {
    params.facets += ',cityPostalCode';
  }

  const cleanedUrlParams = omit(urlParams, ['distance', 'page', 'pageSize', 'sort', 'keyword']);
  if (size(cleanedUrlParams) === 2 && urlParams.multiFacetedMakeModel) {
    params.facets += ',model,minYear,maxYear,hullShape,enginesConfiguration,activities';
  }

  params = showSeoContent(url, params);

  let apiClient = http || httpClient.getSecureProxyHttpClient();
  dispatch(getDataRequest(params));
  await getUser()(dispatch);

  try {
    const trackingAllowed = cookiesLib.canCreateCookies('advertising', cookies);
    const res = await apiClient.get(search, {
      params,
      headers: {
        [TYPE_PORTAL_HEADER]: TYPE_PORTAL_HEADER_VALUE,
        [TARGET_ADS_HEADER]: trackingAllowed,
      }
    });
    return dispatch(getDataSuccess(res.data));
  } catch (err) {
    return dispatch(getDataFailure(err.toString(), get(err, 'response.status', '')));
  }
};

export const getNextPreviousData = async (urlParams) => {
  let search = '/app/search/boat';
  let params = {
    pageSize: BOATS_PAGE_SIZE,
    page: 1,
    country: 'US',
    facets: [
      'fuelType'
    ].join(','),
    fields: [
      'id',
      'make',
      'model',
      'year',
      'portalLink'
    ].join(','),
    useMultiFacetedFacets: true,
    ...urlParams
  };
  try {
    return await httpClient.getSecureProxyHttpClient().get(search, {params});
  } catch (e) {
    return {};
  }
};


export const getBoatsNearYou = async (params, http) => {
  let apiClient = http || httpClient.getSecureProxyHttpClient();
  let url = '/app/search/boats-near-you';
  try {
    const res = await apiClient.get(url, { params } );
    return res.data;
  } catch (err) {
    return err;
  }
};

export const getRelatedNextPreviousData = async (listing, page = 1) => {
  let searchRelated = '/app/search/boat/related';
  let params = {
    type: get(listing, 'type'),
    class: get(listing, 'class'),
    make: get(listing, 'make'),
    model: get(listing, 'model'),
    length: get(listing, 'length'),
    page: page,
    pageSize: BOATS_PAGE_SIZE,
    'notId': get(listing, 'id')
  };
  try {
    return await httpClient.getSecureProxyHttpClient().get(searchRelated, {params});
  } catch (e) {
    return {};
  }
};

export const getFacets = (url, otherParams) => async dispatch => {
  let search = '/app/search/boat';
  let urlParams = readGroupedUrlParams(parseSearchParams(url));

  let params = {
    ...otherParams,
    ...urlParams,
    useMultiFacetedFacets: true,
    carouselSponsoredSize: 0,
    sponsoredSize: 0,
    pageSize: 0,
    page: 1,
    country: 'US',
    facets: 'country,state,make,makeModel,class,fuelType,hullMaterial,stateCity,priceRevisedSince'
  };
  if (urlParams.city && urlParams.city.split(',').length === 1) {
    params.facets += ',cityPostalCode';
  }

  dispatch(getFacetRequest(params));
  const apiClient = httpClient.getSecureProxyHttpClient();
  try {
    const res = await apiClient.get(search, {params, headers: { [TYPE_PORTAL_HEADER]: TYPE_PORTAL_HEADER_VALUE }});
    return dispatch(getFacetSuccess(res.data));
  } catch (err) {
    return dispatch(getDataFailure(err.toString()));
  }
};

export const getBoatData = (url, cookies, queryParams) => async (dispatch, getState, http) => {
  const params = {
    ...queryParams,
    otherDealerBoats: true,
    videoType: 'model'
  };
  let boatParams = parseBoatDetailParams(url);
  let boatListing = `/app/boat/${boatParams.id}`;
  await getUser()(dispatch);
  let apiClient = http || httpClient.getSecureProxyHttpClient();
  dispatch(getDataRequest(boatParams));
  try {
    const cachedData = cache.get(boatListing);
    const cacheTime = 1000 * 10; // 10 seconds
    if (cachedData) {
      return dispatch(getDataSuccess(cachedData));
    }
    const trackingAllowed = cookiesLib.canCreateCookies('advertising', cookies);
    const res = await apiClient.get(boatListing, {params, headers: {[TYPE_PORTAL_HEADER]: TYPE_PORTAL_HEADER_VALUE, [TARGET_ADS_HEADER]: trackingAllowed}});
    cache.put(boatListing, res.data, cacheTime);
    return dispatch(getDataSuccess(res.data));
  } catch (err) {
    const statusCode = get(err, 'response.status', '');
    const data = get(err, 'response.data');
    if (statusCode === 301 && data) {
      return dispatch(getDataSuccess(data, statusCode));
    }
    return dispatch(getDataFailure(err.toString(), statusCode));
  }
};

export const setUserRef = (userRef) => dispatch => {
  dispatch(setUserRefValue(userRef));
};

export const getUser = () => async dispatch => {
  if (!utils.isServer()) {
    const currentSession = await Amplify.getCurrentSession();
    if (currentSession !== null) {
      return getMyboatsListings(currentSession)(dispatch);
    }
    dispatch(requireMyboatsLogin('No auth token present'));
  }
};

export const getUserEmail = () => async (dispatch) => {
  if (!utils.isServer()) {
    let email;

    const currentSession = await Amplify.getCurrentSession();
    if (currentSession !== null) {
      const user = await Amplify.getCurrentAuthenticatedUser();
      email = user?.attributes?.email;
    }

    if (!email) {
      email = null;
    }

    dispatch(setUserEmail(email));
  }
};

export const likeQueuedBoats = () => async (dispatch) => {
  const queuedBoats = JSON.parse(localStorage.getItem('QUEUED_LIKED_BOATS')) || [];
  if (queuedBoats && queuedBoats.length > 0) {
    await likeBoats(queuedBoats)(dispatch);
  }
};

export const getMyboatsListings = (currentSession) => async (dispatch) => {
  await likeQueuedBoats()(dispatch);
  let endPoint = `${process.env.REACT_APP_MYBOATS_API}/listing?siteId=boattrader&onlyIds=true`;
  const apiClient = httpClient.getSecureHttpClient();

  apiClient.get(
    endPoint,
    {
      headers: {
        Authorization: currentSession.idToken.jwtToken
      }
    }
  ).then(res => {
    if (res.headers['content-type'].split(';')[0] === 'application/json') {
      dispatch(userListingsSuccess(res.data));
    } else {
      dispatch(requireMyboatsLogin(res.headers['content-type']));
    }
  }).catch(err => {
    dispatch(userListingsFailure(JSON.stringify(err)));
    dispatch(requireMyboatsLogin('No auth token present'));
  });
};

export const likeBoats = (data) => async dispatch => {
  const currentSession = await Amplify.getCurrentSession();
  if (currentSession !== null) {
    let endPoint = `${process.env.REACT_APP_MYBOATS_API}/listings`;
    const apiClient = httpClient.getSecureHttpClient();
    try {
      await apiClient.post(
        endPoint,
        data,
        {
          headers: {
            Authorization: currentSession.idToken.jwtToken
          }
        }
      );
      for (const listingId of data) {
        dispatch(addLead(listingId, 'save boat'));
      }
      localStorage.removeItem('QUEUED_LIKED_BOATS');
    } catch (e) {
      return;
    }
  }
};

export const likeBoat = (data) => async (dispatch, getState) => {
  const currentSession = await Amplify.getCurrentSession();
  if (currentSession !== null) {
    const listings = getState().app.myboats.listings;
    listings.push(data);
    dispatch(userListingsSuccess([...listings]));
    let endPoint = `${process.env.REACT_APP_MYBOATS_API}/listing`;
    const apiClient = httpClient.getSecureHttpClient();
    try {
      await apiClient.post(
        endPoint,
        data,
        {
          headers: {
            Authorization: currentSession.idToken.jwtToken
          }
        }
      );
    } catch (e) {
      getMyboatsListings(currentSession)(dispatch);
    }
  }
};

export const unlikeBoat = (listingId) => async dispatch => {
  const currentSession = await Amplify.getCurrentSession();
  if (currentSession !== null) {
    let endPoint = `${process.env.REACT_APP_MYBOATS_API}/listing/${listingId}`;
    const apiClient = httpClient.getSecureHttpClient();
    try {
      const res = await apiClient.delete(
        endPoint,
        {
          headers: {
            Authorization: currentSession.idToken.jwtToken
          }
        }
      );
      if (res.headers['content-type'].split(';')[0] === 'application/json') {
        dispatch(userListingsSuccess(res.data));
      }
    } catch (e) {
      getMyboatsListings(currentSession)(dispatch);
    }
  }
};

export const pricePerMonth = (data) => {
  return {
    type: types.PRICE_PER_MONTH,
    data: data
  };
};

export const carouselButtonClick = (carouselClickCount) => {
  return {
    type: types.INCREMENT_CAROUSEL_CLICKCOUNT,
    carouselClickCount: carouselClickCount
  };
};

export const carouselModalButtonClick = (carouselClickCountModal) => {
  return {
    type: types.INCREMENT_CAROUSEL_MODAL_CLICKCOUNT,
    carouselClickCountModal: carouselClickCountModal
  };
};

export const setUserCountryCode = (data) => ({
  type: types.SET_USER_COUNTRY_CODE,
  data
});

export const setUserCountryCodeError = (data) => ({
  type: types.SET_USER_COUNTRY_CODE_ERROR,
  data
});

export const setUserMarket = (data) => ({
  type: types.SET_USER_MARKET,
  data
});

export const setUserRadiusMiles = (data) => ({
  type: types.SET_USER_RADIUS_MILES,
  data
});

export const setUserZipCode = (data) => ({
  type: types.SET_USER_ZIPCODE,
  data
});

export const setUserEmail = (data) => ({
  type: types.SET_USER_EMAIL,
  data
});

export const getUserCountryCode = (params) => async (dispatch, getState, apiClient) => {
  const userLocationUrl = '/app/user/location';

  var locationData = {};

  if (getState().app.userCountryCode === '') {
    try {
      locationData = await apiClient.get(userLocationUrl, {params});
      const countryCode = locationData.data.countryCode;
      const market = locationData.data.market;
      const radiusMiles = locationData.data.radiusMiles;
      const zipCode = locationData.data.zipcode;

      if (countryCode === '') {
        dispatch(setUserCountryCodeError('Couldn\'t determine user\'s countryCode'));
      } else {
        dispatch(setUserCountryCode(countryCode));
        dispatch(setUserMarket(market));
        dispatch(setUserCountryCodeError(null));
        if (countryCode === 'US') {
          dispatch(setUserRadiusMiles(radiusMiles));
          dispatch(setUserZipCode(zipCode));
        }
      }
    } catch (err) {
      dispatch(setUserCountryCodeError(err.toString()));
    }
  }

  return locationData;
};

export const getDealersData = (url, cookies) => async (dispatch, getState, http) => {
  const paramsUrl = parseBoatDealersParams(url);
  const apiClient = http || httpClient.getSecureProxyHttpClient();
  const endPoint = '/app/boat-dealers/dealers';
  const params = {
    pageSize: 8,
    page: 1,
    ...paramsUrl
  };

  try {
    const trackingAllowed = cookiesLib.canCreateCookies('advertising', cookies);
    dispatch(getDataRequest(params));
    const res = await apiClient.get(endPoint, {params, headers: {[TYPE_PORTAL_HEADER]: TYPE_PORTAL_HEADER_VALUE, [TARGET_ADS_HEADER]: trackingAllowed}});
    return dispatch(getDataSuccess(res.data));
  } catch (err) {
    const statusCode = get(err, 'response.status', '');
    const data = get(err, 'response.data');
    if (statusCode === 301 && data) {
      return dispatch(getDataSuccess(data, statusCode));
    }
    return dispatch(getDataFailure(err.toString(), statusCode));
  }
};

export const getDealerModalSRP = async (url) => {
  let request = {
    url: url,
    method: 'get',
    timeout: 10000
  };
  return axios.request(request)
    .then((res) => {
      return getDataSuccess(res.data);
    })
    .catch((err) => {
      const statusCode = get(err, 'response.status', '');
      return getDataFailure(err.toString(), statusCode);
    });
};

// TODO: Deal with default sort in the sort ticket.
export const getEnginesData = (url, cookies) => async (dispatch, getState, http) => {
  const apiClient = http || httpClient.getSecureProxyHttpClient();
  const paramsUrl = parseSearchEnginesParams(url);
  const endPoint = '/app/search/engine';

  const params = {
    pageSize: ENGINES_PAGE_SIZE,
    multiSearch: true,
    country: 'US',
    ...paramsUrl
  };

  try {
    const trackingAllowed = cookiesLib.canCreateCookies('advertising', cookies);
    dispatch(getDataRequest(params));
    const res = await apiClient.get(endPoint, {params, headers: {[TYPE_PORTAL_HEADER]: TYPE_PORTAL_HEADER_VALUE, [TARGET_ADS_HEADER]: trackingAllowed}});
    return dispatch(getDataSuccess(res.data));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn('err', err);
    const statusCode = get(err, 'response.status', '');
    const data = get(err, 'response.data');
    if (statusCode === 301 && data) {
      return dispatch(getDataSuccess(data, statusCode));
    }
    return dispatch(getDataFailure(err.toString(), statusCode));
  }
};

// TODO: Deal with default sort in the sort ticket.
export const getEnginesFacets = (url) => async (dispatch, getState, http) => {
  const endPoint = '/app/search/engine';
  const urlParams = parseSearchEnginesParams(url);

  const params = {
    multiSearch: true,
    ...urlParams,
    country: 'US',
    pageSize: 0,
    page: 1
  };

  const apiClient = http || httpClient.getSecureProxyHttpClient();
  try {
    dispatch(getEngineFacetsRequest(params));
    const res = await apiClient.get(endPoint, { params, headers: { [TYPE_PORTAL_HEADER]: TYPE_PORTAL_HEADER_VALUE } });
    return dispatch(getEngineFacetSuccess(res.data));
  } catch (err) {
    return dispatch(getDataFailure(err.toString()));
  }
};

export const subscribeMarketingMyBoats = async (userEmail) => {
  let endPoint = `${process.env.REACT_APP_MARKETING_PATH}/subscriber?portal=boattrader`;
  const apiClient = httpClient.getHttpClient();
  const data = {
    username: userEmail,
    creationmethod: 'optin',
    portal: 'BT',
    marketing: true
  };
  try {
    await apiClient.post(
      endPoint,
      data
    );
    return 201;
  } catch (err) {
    return get(err, 'response.status', 400);
  }
};


export const getHomeData = (params, cookies) => async (dispatch, getState, http) => {
  const apiClient = http || httpClient.getSecureProxyHttpClient();
  const endPoint = 'app/home';
  params = {
    useServerAds: 'true',
    ...params
  };

  try {
    const trackingAllowed = cookiesLib.canCreateCookies('advertising', cookies);
    dispatch(getDataRequest(params));
    const res = await apiClient.get(endPoint, {params, headers: {[TYPE_PORTAL_HEADER]: TYPE_PORTAL_HEADER_VALUE, [TARGET_ADS_HEADER]: trackingAllowed}});
    return dispatch(getDataSuccess(res.data));
  } catch (err) {
    /* eslint-disable no-console */
    console.warn('err', err);
    const statusCode = get(err, 'response.status', '');
    const data = get(err, 'response.data');
    if (statusCode === 301 && data) {
      return dispatch(getDataSuccess(data, statusCode));
    }
    return dispatch(getDataFailure(err.toString(), statusCode));
  }
};

export const getSbpData = (params, imtID, abTestConfiguration, cookies) => async (dispatch, getState, http) => {
  const apiClient = http || httpClient.getSecureProxyHttpClient();
  const endPoint = `app/search/boat/related/${imtID}`;

  if (abTestConfiguration){
    const variantVisibilityLevelThreshold = findFeatureFlagActive(abTestConfiguration, FEATURE_KEYS.BOATS_SBP_VISIBILITY_LEVEL_THRESHOLD);
    if (variantVisibilityLevelThreshold){
      params.variantVisibilityLevelThreshold = 1;
    }
  }

  try {
    const trackingAllowed = cookiesLib.canCreateCookies('advertising', cookies);
    dispatch(getDataRequest(params));
    const res = await apiClient.get(endPoint, {params, headers: {[TYPE_PORTAL_HEADER]: TYPE_PORTAL_HEADER_VALUE, [TARGET_ADS_HEADER]: trackingAllowed}});
    return dispatch(getDataSuccess({sbpListings: res.data}));
  } catch (err) {
    /* eslint-disable no-console */
    console.warn('err', err);
    const statusCode = get(err, 'response.status', '');
    const data = get(err, 'response.data');
    if (statusCode === 301 && data) {
      return dispatch(getDataSuccess(data, statusCode));
    }
    return dispatch(getDataFailure(err.toString(), statusCode));
  }
};
export const getRelatedListings = (params) => {
  const search = '/app/search/boat/related/additionalLeads';
  return httpClient.getSecureProxyHttpClient().get(search, { params });
};

export const showAdditionalLeadsModal = (leadData) => async (dispatch, getState) => {
  try {
    const listing = get(getState(), 'app.data');
    let paramList = ['location', 'make', 'model', 'type'];
    let params = buildRelatedListingParamsFromListing(paramList, listing);
    let relatedListings = await getRelatedListings(params);

    if (listing.isOemModel) {
      dispatch(openSubmissionSuccessAlert(true));
      dispatch(setModalEvent('open', POST_SUBMISSION_SUCCESS_ALERT_MODAL));
    } else if (relatedListings.data) {
      const data = {
        leadData,
        listings: get(relatedListings, 'data', {})
      };
      dispatch(setAdditionalLeadsData(data));
      dispatch(openAdditionalLeadsModal());
      dispatch(setModalEvent('open', POST_LEAD_SIMILAR_BOATS_MODAL));
    } else {
      dispatch(setAdditionalLeadsData({ leadData: {}, listings: [] }));
      dispatch(closeAdditionalLeadsModal());
      dispatch(setModalEvent('close', POST_LEAD_SIMILAR_BOATS_MODAL));
    }
  } catch (e) {
    dispatch(setAdditionalLeadsData({ leadData: {}, listings: [] }));
    dispatch(closeAdditionalLeadsModal());
    dispatch(setModalEvent('close', POST_LEAD_SIMILAR_BOATS_MODAL));
  }
};

export const openAdditionalLeadsModal = () => ({
  type: types.TOGGLE_ADDITIONAL_LEADS_MODAL,
  data: true
});

export const closeAdditionalLeadsModal = () => ({
  type: types.TOGGLE_ADDITIONAL_LEADS_MODAL,
  data: false
});


export const setAdditionalLeadsData = data => ({
  type: types.SET_ADDITIONAL_LEADS_DATA,
  data
});

export const openAdditionalLeadsAlert = (success) => {
  return {
    type: types.TOGGLE_ADDITIONAL_LEADS_ALERT,
    data: {
      open: true,
      success: success
    }
  };
};

export const closeAdditionalLeadsAlert = () => {
  return {
    type: types.TOGGLE_ADDITIONAL_LEADS_ALERT,
    data: {
      open: false,
      success: undefined
    }
  };
};

export const openSubmissionSuccessAlert = (success) => {
  return {
    type: types.TOGGLE_SUBMISSION_SUCCESS_ALERT,
    data: {
      open: true,
      success: success,
      isOem: true
    }
  };
};

export const setContactFormMessage = data => ({
  type: types.SET_CONTACT_FORM_MESSAGE,
  data
});

export const togglePostLeadModal = data => {
  return {
    type: types.SHOW_POSTLEAD_MODAL,
    data
  };
};

export const hidePostLeadModal = () => {
  return {
    type: types.SHOW_POSTLEAD_MODAL,
    data: false
  };
};


export const setLeadSuccess = (status) => ({
  type: types.LEAD_SUCCESS,
  data: status
});

export const getBoatTypes = (cookies) => async (dispatch, getState, http) => {
  const apiClient = http || httpClient.getSecureProxyHttpClient();
  const endPoint = '/app/boat-types';
  const params = {};

  try {
    const trackingAllowed = cookiesLib.canCreateCookies('advertising', cookies);
    const res = await apiClient.get(endPoint, {params, headers: {[TYPE_PORTAL_HEADER]: TYPE_PORTAL_HEADER_VALUE, [TARGET_ADS_HEADER]: trackingAllowed}});
    return dispatch(getDataSuccess(res.data));
  } catch (err) {
    const statusCode = get(err, 'response.status', '');
    return dispatch(getDataFailure(err.toString(), statusCode));
  }
};

export const getEditorialContent = (url) => async (dispatch, getState, http) => {
  const permalink = `/boattrader${url}`.replace(/\/?$/, '/'); // Add trailing slash to URL so it matches the exact permalink value
  const queryParams = `permalink=${encodeURIComponent(permalink)}`;
  const editorialLink = `/app/editorial/articles?${queryParams}`;
  let apiClient = http || httpClient.getSecureProxyHttpClient();
  try {
    dispatch(getDataRequest(editorialLink));
    const res = await apiClient.get(editorialLink);
    return dispatch(getDataSuccess(res.data));
  } catch (err) {
    /* eslint-disable no-console */
    console.warn('error', err);
    const statusCode = get(err, 'statusCode', 500);
    return dispatch(getDataFailure(err.toString(), statusCode));
  }
};

export const getBoatClass = (url) => async (dispatch) => {
  // quick and dirty for a test
  // {boatClass, displayName}
  const parsedClass = parseClassNameFromUrl(url);
  dispatch(getDataRequest(parsedClass));
  return dispatch(getDataSuccess({
    records: [],
    errors: undefined,
    message: undefined,
    statusCode: 200,
  }));
};
