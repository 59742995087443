import { Details } from '@dmm/lib-react-ui-components';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';
import MapOnly from '../../../components/LocationMap/components/MapOnly';
import {
  formatMoreDetailsDescriptions,
  getFeaturesData,
  getLocation,
  getMeasurementsData,
  getPriceData,
  getPropulsionData
} from '../../../utils/listingHelper';
import DataDetails from './components/DataDetails';
import DataHtml from './components/DataHtml';

const accordionDetailsItemsClass = 'accordion-details-items';

const ConditionalRender = ({
  data,
  title,
  onClick,
  isHtml = false,
  isOpen = false,
  isPricing = false,
  isMultiItem = false,
  extraRenderStatement = true,
  setCollapseContentTracking
}) => {
  if (isEmpty(data) || data?.length === 0 || !extraRenderStatement) {
    return null;
  }

  const hasSublevel = typeof data[0]?.value !== 'string';

  return (
    <Details heading={<h3>{title}</h3>} isOpen={isOpen} onClick={onClick}>
      {isHtml
        ? <DataHtml data={data} isMultiItem={isMultiItem} widthSelector={`.${accordionDetailsItemsClass}`} setCollapseContentTracking={setCollapseContentTracking} />
        : <DataDetails data={data} isPricing={isPricing} isSubLevel={hasSublevel} />
      }
    </Details>
  );
};

const AccordionDetails = ({ isPrivateSeller = false, listing, setCollapseContentTracking }) => {
  const pricingData = getPriceData(listing);
  const locationAddress = getLocation(listing);
  const featuresData = getFeaturesData(listing);
  const propulsionData = getPropulsionData(listing);
  const measurementsData = getMeasurementsData(listing);

  const description = get(listing, 'description');
  const oemName = get(listing, 'oemDetails.name');
  const oemIntro = get(listing, 'oemDetails.intro');
  const [lng, lat] = get(listing, 'location.coordinates', []);

  const moreDetails = formatMoreDetailsDescriptions(get(listing, 'descriptions', []));

  const getIsOpen = (key) => openStates[key] ? 'close' : 'open';
  const toggleOpenState = (key) => {
    setOpenStates((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };
  const [openStates, setOpenStates] = useState({
    'boat-description': true,
    'oem-intro': false,
    'pricing-details': false,
    'measurements': false,
    'propulsion': false,
    'features': false,
    'more-details': false,
    'map-view': false,
  });
  const collapseTracking = (content) => {
    return {
      content,
      action: getIsOpen(content),
      label: ('accordion ' + (getIsOpen(content)))
    };
  };

  return (
    <div className="accordion-details-wrapper">
      <section className="accordion-details-section">
        <h2>Boat Details</h2>
        <div className={accordionDetailsItemsClass}>
          <ConditionalRender
            data={description}
            title={'Description'}
            isHtml
            isOpen
            setCollapseContentTracking={setCollapseContentTracking}
            onClick={() => {
              setCollapseContentTracking(collapseTracking('boat-description'));
              toggleOpenState('boat-description');
            }}
          />
          <ConditionalRender
            data={oemIntro}
            title={`About ${oemName}`}
            isHtml
            setCollapseContentTracking={setCollapseContentTracking}
            onClick={() => {
              setCollapseContentTracking(collapseTracking('oem-intro'));
              toggleOpenState('oem-intro');
            }}
          />
          <ConditionalRender
            data={pricingData}
            title={'Pricing Details'}
            isPricing
            setCollapseContentTracking={setCollapseContentTracking}
            onClick={() => {
              setCollapseContentTracking(collapseTracking('pricing-details'));
              toggleOpenState('pricing-details');
            }}
            extraRenderStatement={listing.price && !listing.price.hidden && !isEmpty(listing.price.detail)}
          />
          <ConditionalRender
            data={measurementsData}
            title={'Measurements' }
            setCollapseContentTracking={setCollapseContentTracking}
            onClick={() => {
              setCollapseContentTracking(collapseTracking('measurements'));
              toggleOpenState('measurements');
            }}
          />
          <ConditionalRender
            data={propulsionData}
            title={'Propulsion' }
            setCollapseContentTracking={setCollapseContentTracking}
            onClick={() => {
              setCollapseContentTracking(collapseTracking('propulsion'));
              toggleOpenState('propulsion');
            }}
          />
          <ConditionalRender
            data={featuresData}
            title={'Features' }
            setCollapseContentTracking={setCollapseContentTracking}
            onClick={() => {
              setCollapseContentTracking(collapseTracking('features'));
              toggleOpenState('features');
            }}
          />
          <ConditionalRender
            data={moreDetails}
            title={'More Details'}
            isHtml
            isMultiItem
            setCollapseContentTracking={setCollapseContentTracking}
            onClick={() => {
              setCollapseContentTracking(collapseTracking('more-details'));
              toggleOpenState('more-details');
            }}
          />
          {lat && lng && !isPrivateSeller &&
            <Details heading={<h3>Location</h3>} onClick={() => {
              setCollapseContentTracking(collapseTracking('map-view'));
              toggleOpenState('map-view');
            }}>
              <MapOnly address={locationAddress} coordinates={{ lat, lng }} />
            </Details>
          }
        </div>
      </section>
    </div>
  );
};

export default AccordionDetails;
