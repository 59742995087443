import { SimpleInfoBox } from '@dmm/lib-react-ui-components';
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Collapsible, CollapsibleContent, CollapsibleHeader } from '../../../components/Collapsible';
import PaymentCalculator from '../../../components/PaymentCalculator';
import { withABTest } from '../../../server/abTest/withABTest';
import { addLead, setCollapseContentTracking, setGenericEvent, trackContactFormOpen } from '../../../store/actions/dataLayer';
import { detailsMapper } from '../../../utils/infoBoxButtonHelper';
import { formatReportFraudEmailWithParams } from '../../../utils/urlHelpers/email';
import DealerOtherBoats from '../DealerOtherBoats';
import Services from '../Services';
import AccordionDetails from './AccordionDetails';
import VideoReviews from './VideoReviews';
import { PrivateSellerInfo } from '../../../components/PrivateSellerInfo';
import { buyerAccountLoginExperiment } from '../../../utils/fsbo/experiments';
import { useFeatureFlags } from '../../../utils/experiment/kameleoonHelper';
import { LISTING_DEALER, LISTING_DEALER_TRACKING } from '../../../constants/boats';
import { ListingsCarousel } from '../../../components/ListingsCarousel';
import { normalizeString } from '@dmm/lib-common/lib/formatting';
import Link from '../../../components/SEO/Link';
import { isFSBOorOEMListing } from '../../../utils/listingHelper';
import { cleanExternalHREF } from '../../../utils/listingHelper';
import ServerAd from '../../../components/ServerAd';
import { isEmpty } from 'lodash';
import './styles.css';


const securityUrl = process.env.REACT_APP_SECURITY_URL;

const CollapsibleDetailsSection = (props) => {
  const {
    url,
    myboats,
    cookies,
    listing,
    isLoading,
    breakpoint,
    isLeadSubmitted,
    openPaymentSection,
    openPaymentCounter,
    tridentLoanAmount,
    tridentTeaserRate,
    tridentTermInMonths,
    onClickRequestInformation,
    setCollapseContentTracking,
    abTestConfiguration,
    serverAds
  } = props;

  const { featureFlagBDPPhase4 } = useFeatureFlags(); // eslint-disable-line
  const hideTridentFeatures = listing?.owner?.hideTridentFeatures ?? false;
  const isPrivateSeller = listing.contact?.name === 'Private Seller';
  const dealerListings = listing.dealerListings;
  const oemDetails = listing?.oemDetails;
  const oemName = listing?.oemDetails?.name;
  const showDealers = oemDetails && oemDetails?.oemDealerLocator;
  const paymentCalculatorOpenState = openPaymentSection && openPaymentSection !== 'payment-calculator' ? 'close' : 'open';
  const isUserLoginExperiment = buyerAccountLoginExperiment.isActive(abTestConfiguration, listing);

  const dispatch = useDispatch();
  const handleTracking = (id, trackingID) => {
    dispatch(addLead(id, trackingID));
  };

  const ServerAds = () => {
    const { ['boat-details_mobile-box-1']: boxOneValue, ['boat-details_mobile-box-2']: boxTwoValue } = serverAds;

    if (isEmpty(boxOneValue) && isEmpty(boxTwoValue)) {
      return null;
    }

    return (
      <>
        <ServerAd ad={{...boxOneValue}} className="mobile-box-ad" />
        { breakpoint === 'tablet' &&  <ServerAd ad={{...boxTwoValue}} className="mobile-box-ad" />}
      </>
    );
  };

  return (
    <>
      <AccordionDetails
        listing={listing}
        isPrivateSeller={isPrivateSeller}
        setCollapseContentTracking={setCollapseContentTracking}
      />

      { listing?.videos && listing?.videos.length > 0 &&
        <VideoReviews
          videos={listing?.videos}
          breakpoint={breakpoint}
        />
      }

      <PaymentCalculator
        open={paymentCalculatorOpenState}
        initialState={paymentCalculatorOpenState}
        counter={openPaymentCounter}
        listing={listing}
        isLoading={isLoading}
        tridentLoanAmount={tridentLoanAmount}
        tridentTeaserRate={tridentTeaserRate}
        tridentTermInMonths={tridentTermInMonths}
        utmCampaign="BDPcalculatorapply"
        isLeadSubmitted={isLeadSubmitted}
        isPrivateSeller={isPrivateSeller}
      />
      { breakpoint !== 'desktop' &&
        <div className="tablet-ads-wrapper">
          {serverAds && <ServerAds />}
        </div>
      }
      {!isPrivateSeller && dealerListings && dealerListings.length > 0 ? (
        featureFlagBDPPhase4 && !isFSBOorOEMListing(listing) ? (
          <div className="dealerListingsCarouselWrapper">
            <h3>More From This Dealer</h3>
            <ListingsCarousel
              page={1}
              key="dealerListingsCarousel"
              listingsType={LISTING_DEALER}
              listings={listing?.dealerListingsCarousel}
              listingTrackingClick={LISTING_DEALER_TRACKING}
              locationMarketingMessage
            />
            <div className="dealer-links">
              <Link className="more-boats-button" data-e2e="more-boats-button" href={`/boats/dealer-${normalizeString(listing?.owner?.name)}-${listing?.owner?.id}/`} onClick={() => handleTracking(listing?.id, 'view all inventory')}>
                More Boats from this Dealer
              </Link>
              {listing?.contact?.website && <a
                className="dealer-website"
                data-e2e="dealer-website"
                href={cleanExternalHREF(listing?.contact?.website)}
                onClick={() => handleTracking(listing?.id, 'website referral')}
                target="_blank"
                rel="noopener nofollow"
              >
                View Dealer Website
              </a> }
            </div>
          </div>
        ) : (
          <CollapsibleContent initialState="open" classOverride="details more-from-dealer-collapsible">
            <CollapsibleHeader>
              {oemName ? `More from ${oemName}` : 'More From This Dealer'}
            </CollapsibleHeader>
            <Collapsible type="no-pad">
              <DealerOtherBoats
                url={url}
                myboats={myboats}
                cookies={cookies}
                listing={listing}
                website={listing?.contact?.website}
                isLoading={isLoading}
                hideMoreLink={!!oemName}
                showMoreBoats={!oemDetails}
                owner={listing?.owner}
                listingId={listing?.id}
                dealerListings={dealerListings}
                mobile={breakpoint !== 'desktop'}
              />
            </Collapsible>
          </CollapsibleContent>
        )
      ) : null}

      {isUserLoginExperiment && <PrivateSellerInfo />}

      {!showDealers && (
        <div className="infopanel-wrapper">
          <SimpleInfoBox
            title={ detailsMapper.services.title }
            classNames={{ shadow: breakpoint === 'mobile' }}
            hyperlinkAttributes={{ 'data-testing-class': `contact-seller-${breakpoint}-conversational` }}
            link={{
              text: detailsMapper.services.content,
              onClick: (isUserLoginExperiment) ? () => { document.getElementById('contact-seller-button').click(); }
                : (event) => { onClickRequestInformation(event, { eventName: 'infobox', type: 'services', dataTestingClass: { suffix: 'conversational-modal' }}, detailsMapper.services.eventLabel); }
            }}>
            Get answers, schedule a visit to see the boat, or find a good time for a sea trial.
            <br />
            <br/>
            Take the next step and contact the seller.
          </SimpleInfoBox>
        </div>
      )}

      <Services listing={listing} serverAds={serverAds} />
      {!hideTridentFeatures || isPrivateSeller && (
        <div className="trident-funding-disclaimer-wrapper">
          <span className="disclaimer">
            Trident Funding LLC markets and sells extended warranties on behalf of 3rd party providers and your extended warranty will be between you and the provider.  Coverage, deductibles, and claims payment vary depending on vehicle age, mileage, pre-existing conditions and program selected. Repairs can be made by any licensed repair facility. Program(s) available in most states. Not available in California or Florida. You consent to be contacted by email, phone, and/or text by submitting your quote request. Texas and Wisconsin Residents: Contracts administered by Dealer Admin Services, 5810 W 78th Street, Suite 300, Bloomington, MN 55439, <span className="underline">877-746-4917</span>.  Vehicle names, logos, brands, & other trademarks featured or referred to within Trident Funding are property of their respective trademark holders. Extended warranties will not be sold where prohibited by law.
          </span>
        </div>
      )}

      {isPrivateSeller &&
        <div className="security-info">
          <div className="header">Something not right?</div>
          <p>Is the boat information inaccurate or suspicious?{' '}
            <a
              href={`mailto:${formatReportFraudEmailWithParams('fraudalert@boattrader.com', listing)}`}
            >
              Report It
            </a>
            <br/>
            Knowledge and awareness are the best defense to prevent fraud.{' '}
            <a href={securityUrl} target="_blank" rel="noopener">
              Please read our fraud awareness tips
            </a>{' '}
            to protect yourself and others!
          </p>
        </div>
      }
    </>
  );
};

export default withABTest(connect(null, dispatch => bindActionCreators({
  setCollapseContentTracking,
  trackContactFormOpen,
  setGenericEvent,
  addLead
}, dispatch))(CollapsibleDetailsSection));
