import { isFSBOContact } from '../listingHelper';
import { FEATURE_KEYS, findFeatureFlagActive } from '../experiment/kameleoonHelper';

const isSegmentCandidate = (listing) => {
  const isPrivateSeller = isFSBOContact(listing.contact);
  const { isOemModel } = listing;

  return isPrivateSeller && !isOemModel;
};

const isFeatureActive = (abTestConfig, listing, featureKey, variation) => {
  const featureFlag = findFeatureFlagActive(abTestConfig, featureKey);
  return featureFlag?.variation === variation && isSegmentCandidate(listing);
};

const isActive = (abTestConfig, listing) =>
  isFeatureActive(abTestConfig, listing, FEATURE_KEYS.BOATS_BDP_BUYER_ACCOUNT_LOGIN, 'v1');

const isConnectSimplifiedActive = (abTestConfig, listing) =>
  isFeatureActive(abTestConfig, listing, FEATURE_KEYS.BOATS_BDP_BUYER_ACCOUNT_LOGIN, 'v2');

const isMessageBeforeLoginExperiment = (abTestConfig, listing) =>
  isFeatureActive(abTestConfig, listing, FEATURE_KEYS.BOATS_BDP_BUYER_ACCOUNT_PROMPT, 'v2');

export const isUserInLoginExperiment = (config, listing) =>
  isActive(config, listing) ||
  isConnectSimplifiedActive(config, listing);


export const buyerAccountLoginExperiment = {
  isActive,
  isSegmentCandidate,
  isConnectSimplifiedActive,
  isMessageBeforeLoginExperiment
};
