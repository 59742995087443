import {matchPath} from 'react-router-dom';
import {
  BOAT_DEALERS_URL,
  BOAT_DEALER_BASE_URL,
  BOAT_DEALERS_URL_PATTERN,
  BOAT_DEALERS_MODAL_URL_PATTERN,
  BOAT_DEALERS_MODAL_URL,
  BOAT_DEALERS_MODAL_MAKE_URL_PATTERN, BOAT_DEALERS_MODAL_MAKE_URL
} from '../../constants/BoatDealers';
import {unhyphenateUrlComponents, unslugify} from './listings';
import {capitalizeEachWord, formatNumber, normalizeString} from '@dmm/lib-common/lib/formatting';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import {getProperDynamicText} from '../metaContentHelper';

const asString = a => typeof a === 'string' ? a.replace(/\//, '') : '';
export const splitFirstDash = (dashed) => asString(dashed).split(/-(.+)/).splice(0, 2);
export const urlParamToFormattedString = (string) => capitalizeEachWord(unhyphenateUrlComponents(unslugify(string)));

/**
 * Object that maps url params to api endpoint proper names
 * @returns {{zip: string, city: string, name: string, state: string, make: string}}
 */
const endpointMappedArgs = () => {
  return {
    name: 'name',
    make: 'make',
    city: 'city',
    state: 'countrySubDivisionCode',
    zip: 'postalCode',
    page: 'page'
  };
};

const urlMappedParams = () => {
  return {
    name: 'name',
    make: 'make',
    city: 'city',
    countrySubDivisionCode: 'state',
    postalCode: 'zip',
    page: 'page'
  };
};

const textDealersSRPHeader = () => {
  return {
    make: 'Boat Dealers for :make Boats',
    makecountrySubDivisionCode: 'Boat Dealers for :make Boats in :countrySubDivisionCode',
    makecountrySubDivisionCodecity: 'Boat Dealers for :make Boats in :city',
    makecountrySubDivisionCodecitypostalCode: 'Boat Dealers for :make Boats in :city, :postalCode',
    countrySubDivisionCode: 'Boat Dealers in :countrySubDivisionCode',
    countrySubDivisionCodecity: 'Boat Dealers in :city, :countrySubDivisionCode',
    countrySubDivisionCodecitypostalCode: 'Boat Dealers in :city, :postalCode'
  };
};

const textDealersSRPResultsHeader = (count) => {
  return {
    name: `${count} dealers`,
    make: `${count} dealers`,
    makecountrySubDivisionCode: `${count} dealers within :countrySubDivisionCode`,
    makecountrySubDivisionCodecity: `${count} dealers within :city`,
    makecountrySubDivisionCodecitypostalCode: `${count} dealers within :postalCode`,
    countrySubDivisionCode: `${count} dealers within :countrySubDivisionCode`,
    countrySubDivisionCodecity: `${count} dealers within :city`,
    countrySubDivisionCodecitypostalCode: `${count} dealers within :postalCode`
  };
};

export const getDealerPattern = (url) => {
  if (url.includes('/boat-dealers/modal-make')) {
    return BOAT_DEALERS_MODAL_MAKE_URL_PATTERN;
  }
  return url.includes('/boat-dealers/modal/') ? BOAT_DEALERS_MODAL_URL_PATTERN : BOAT_DEALERS_URL_PATTERN;
};

export const parseBoatDealersParams = (url) => {
  if (!url) {
    return {};
  }

  const args = matchPath(url, getDealerPattern(url));
  const urlParams = args && args.params || {};
  const mapper = endpointMappedArgs();
  const dealerParams = Object.keys(urlParams)
    .map(arg => splitFirstDash(urlParams[arg]))
    .reduce((args, splittedParams) => {
      if (splittedParams.length === 2) {
        const [key, val] = splittedParams;
        const mappedKey = mapper[key];
        args[mappedKey] = val;
      }
      return args;
    }, {});
  return dealerParams;
};

export const getDealerSearchUrl = (params = {}) => {
  let boatDealersUrl = params.modal ? BOAT_DEALERS_MODAL_URL :
    params.modalMakes ? BOAT_DEALERS_MODAL_MAKE_URL :
      BOAT_DEALERS_URL;
  const mapper = urlMappedParams();
  if (params.page === 1) {
    delete params.page;
  }
  Object.entries(params).forEach( item => {
    if (item[0] && item[1]) {
      boatDealersUrl = boatDealersUrl.replace(`:${mapper[item[0]]}`, `${mapper[item[0]]}-${item[1]}`);
    }
  });

  return boatDealersUrl.replace(/(:[a-z]+\/)/g, '').toLowerCase();
};

export const getDealersSRPHeader = (params) => {
  const headerTexts = textDealersSRPHeader();
  const defaultKeys = ['make', 'countrySubDivisionCode', 'city', 'postalCode'];
  const headerFilterToFormat = {make: urlParamToFormattedString, city: urlParamToFormattedString};
  if (params.seoMakeInfo && params.seoMakeInfo.seoMakeName) {
    params.make = params.seoMakeInfo.seoMakeName;
    delete headerFilterToFormat?.make;
  }
  const headerText = getProperDynamicText(params, null, headerFilterToFormat, defaultKeys, headerTexts);
  return headerText ? headerText : 'Boat Dealers';
};

export const getDealersSRPResultsText = (params, count) => {
  const formatedCount = formatNumber(count);
  const resultsTexts = textDealersSRPResultsHeader(formatedCount);
  const activeFilter = Object.keys(params).filter(
    key => params[key] && ['name', 'make', 'countrySubDivisionCode', 'city', 'postalCode'].includes(key)
  );
  if (activeFilter.length > 1 && activeFilter.includes('name')) {
    activeFilter.shift();
  }
  let headerText = resultsTexts[activeFilter.join('')];
  Object.entries(activeFilter).forEach(key => {
    const prop = key[1];
    let value = ['make', 'city'].includes(prop) ? urlParamToFormattedString(params[prop]) : params[prop];
    headerText = headerText.replace(`:${prop}`, `${value}`);
  });
  return headerText ? headerText :
    `With ${formatedCount} boat dealers you're sure to find the perfect dealer on Boat Trader today. ` +
    'Browse the widest choice of boat dealerships in the US.';
};

export const rewriteLegacyDealerSearchResultsUrl = props => {
  let params = get(props, 'match.params', {});
  if (!isEmpty(params) && params[0]) {
    let stateCode = params[0].split('-')[1];
    if (stateCode) {
      return `${BOAT_DEALER_BASE_URL}state-${stateCode}/`;
    }
  }
  return BOAT_DEALER_BASE_URL;
};

export const makeDealerUrl = (state = '', city = '', zip = '', make = '') => {
  state = state?.toLowerCase();
  const makePath = make ? `make-${normalizeString(make)}/` : '';
  if (zip) { return `/boat-dealers/${makePath}state-${state}/city-${normalizeString(String(city))}/zip-${zip}/`; }
  if (city) { return `/boat-dealers/${makePath}state-${state}/city-${normalizeString(String(city))}/`; }
  if (state) { return `/boat-dealers/${makePath}state-${state}/`; }
  return `/boat-dealers/${makePath}`;
};
