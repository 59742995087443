import React, { PureComponent } from 'react';
import BreadCrumb from '../../../components/BreadCrumb';
import './styles.css';
import { serviceGroupsConfig } from './resources/servicesConfig';
import ServiceGroup from '../ServiceGroup';
export default class MainContent extends PureComponent {
  render() {
    return (<>
      <BreadCrumb items={[{ title: 'Home', link: '/' }, { title: 'Services', link: '/services/' }]} />
      <div className="services-head">
        <h1>BOAT SERVICES</h1>
        <h2>Discover tips for buying, selling and owning a boat</h2>
      </div>
      <div className="services-content">
        <div className="left-content">
          {serviceGroupsConfig.map((servicesGroup, i) =>
            <ServiceGroup
              key={i}
              image={servicesGroup.image}
              title={servicesGroup.title}
              services={servicesGroup.services}
            />
          )}
        </div>
      </div>
    </>);
  }
}
