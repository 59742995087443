import { ContactCard, SummarySection } from '@dmm/lib-react-ui-components';
import get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import { withCookies } from 'react-cookie';
import { connect, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withABTest } from '../../server/abTest/withABTest';
import { DOWN_PAYMENT_PERCENTAGE } from '../../constants/BoatLoans';
import { addLead, setGenericEvent, trackPhoneCall } from '../../store/actions/dataLayer';
import {
  getLocation,
  getPrice,
  getPriceCut,
  getTitle,
  isFSBOContact,
  roundLength
} from '../../utils/listingHelper';
import { buyerAccountLoginExperiment } from '../../utils/fsbo/experiments';
import { scrollTo } from '../../utils/scrollTo';
import { calculateMonthlyPrice, isFinanceable } from '../../utils/trident';
import Loading from '../LoadingPlaceholder';
import './styles.css';
import { CustomizepaymentToolTip } from '../CustomizePaymentToolTip';
import { BDPPrivateConnect } from '../../containers/BoatDetails/BDPSections/BDPPrivateConnect';
import BDPEmailLeadForm from '../../containers/BoatDetails/BDPSections/BDPEmailLeadForm';

const BoatSummary = ({
  breakpoint,
  isLoading,
  listing,
  tridentTeaserRate,
  tridentLoanAmount,
  tridentTermInMonths,
  handleOpenContactForm,
  onClickPaymentLink,
  onClickLocationLink,
  bannerText,
  showSellerInfoCard,
  isPrivateSellerConnect,
  userEmail,
  userRef,
  openModalPostLead,
  abTestConfiguration
}) => {
  const [locationMapCounter, setLocationMapCounter] = useState(1);
  const isConnectSimplifiedExperiment = buyerAccountLoginExperiment.isConnectSimplifiedActive(abTestConfiguration, listing);
  const isMessageBeforeLoginExperiment = buyerAccountLoginExperiment.isMessageBeforeLoginExperiment(abTestConfiguration, listing);
  const handleLocationMapLinkClick = event => {
    event.preventDefault();
    window.requestAnimationFrame(() => {
      scrollTo('#location-map-anchor', { behavior: 'smooth' });
    });
    onClickLocationLink(locationMapCounter);
    setLocationMapCounter(prevCounter => prevCounter + 1);
  };

  const renderPriceTotal = () => {
    if (breakpoint === 'desktop') {
      return (<span>{getPrice(listing)}</span>);
    }
    return (<a onClick={handleOpenContactForm}>{getPrice(listing)}</a>);
  };

  const dispatch = useDispatch();
  const [showConfirmationMessage, setShowConfirmationMessage] = useState(false);
  const contactCardSrc = listing.owner?.logos?.default || '';
  const contactCardSellerName = listing.owner?.name;
  const contactCardPhoneNumber = listing.contact?.phone;
  const leadSubmitted = useSelector((state) => get(state, 'app.additionalLeads.open', false));

  const handleClickPhoneCall = () => {
    dispatch(trackPhoneCall());
    dispatch(addLead(listing.Id, 'phone call'));
  };

  useEffect(() => {
    if (leadSubmitted === true) { setShowConfirmationMessage(true); }
  }, [leadSubmitted]);

  const renderSummary = () => {
    const boatLength = `| ${roundLength(get(listing, 'specifications.dimensions.lengths.nominal.ft'))}'`;
    const isFSBO = isFSBOContact(listing.contact);
    const price = get(listing, 'price.type.amount.USD');
    const financeable = isFinanceable(price, listing?.year);
    const priceDrop = getPriceCut(listing);
    const downPayment = financeable && tridentLoanAmount * (DOWN_PAYMENT_PERCENTAGE / 100);
    const monthlyPrice = financeable && calculateMonthlyPrice(tridentTeaserRate, tridentTermInMonths, tridentLoanAmount - downPayment);


    return (
      <div className="broker-summary-section">
        <SummarySection
          status={bannerText}
          title={getTitle(listing, true)}
          price={renderPriceTotal()}
          priceDrop={priceDrop ? priceDrop : ''}
          location={getLocation(listing)}
          locationClassNames={{ 'content-16': true }}
          onClickLocation={getLocation(listing) && !isFSBO && handleLocationMapLinkClick}
          boatLength={boatLength}
        />
        {monthlyPrice && (
          <div className="finance-summary">
            <CustomizepaymentToolTip monthlyPrice={monthlyPrice} tridentTeaserRate={tridentTeaserRate} tridentTermInMonths={tridentTermInMonths} onClickPaymentLink={onClickPaymentLink}/>
          </div>
        )}
        {isPrivateSellerConnect &&
        <BDPPrivateConnect
          userEmail={userEmail} userRef={userRef}
          handleOpenContactForm={handleOpenContactForm}
          isConnectSimplifiedExperiment={isConnectSimplifiedExperiment}
          isMessageBeforeLoginExperiment={isMessageBeforeLoginExperiment}
        />
        }

        { !showSellerInfoCard && !isPrivateSellerConnect && breakpoint === 'desktop' &&
          <BDPEmailLeadForm
            listing={listing}
            breakpoint={breakpoint}
            onSuccess={openModalPostLead}
            buttonAttributes={{ 'data-testing-class': `form-submission-${breakpoint}-${isFSBO ? 'seller' : 'dealer'}` }}
            tridentTermInMonths={tridentTermInMonths}
            tridentLoanAmount={tridentLoanAmount}
            tridentTeaserRate={tridentTeaserRate}
          />
        }
        {showSellerInfoCard && !isPrivateSellerConnect &&
          <div className="contact-card-container">
            <ContactCard
              src={contactCardSrc}
              listedByText="Listed by"
              sellerName={contactCardSellerName}
              phoneNumber={contactCardPhoneNumber || ''}
              showConfirmationMessage={showConfirmationMessage}
              handleClick={(e) => handleOpenContactForm(e, { dataTestingClass: { suffix: 'contact-modal' }})}
              onClickPhoneNumber={handleClickPhoneCall}
              buttonLabel="Contact Seller"
              buttonAttributes={{ 'data-testing-class': `contact-seller-${breakpoint}-contact-card` }}
              successMessage= "Congratulations! We have sent your information directly to the seller."
            />
          </div>
        }
      </div>
    );
  };

  const renderLoading = () => {
    return (
      <>
        <Loading type="text" />
        <Loading type="text" />
        <Loading type="text" />
      </>
    );
  };

  return (
    <div className="summary" data-testid="boat-summary">
      {isLoading || !listing.id ?
        renderLoading()
        :
        renderSummary()
      }
    </div>
  );
};

export default withABTest(withCookies(connect(null, (dispatch) => bindActionCreators({
  setGenericEvent
}, dispatch))(BoatSummary)));
