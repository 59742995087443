import { normalizeString } from '@dmm/lib-common/lib/formatting';
import { FilterSearch } from '@dmm/lib-react-ui-components';
import debounce from 'lodash/debounce';
import find from 'lodash/find';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import React, { /*PureComponent, */ Suspense, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Collapsible, CollapsibleContent, CollapsibleHeader } from '../../../components/Collapsible';
import { SearchAlerts, SearchAlertsFilterTags } from '../../../components/RefineSearch';
import { DEBOUNCE_TIMEOUT } from '../../../constants/boats';
import { setGenericEvent, setSearchTracking } from '../../../store/actions/dataLayer';
import {
  memoizedFiberGlassGroup,
  sortTwoLevelFacets
} from '../../../utils/twoLevelGroupFacets';
import { currentUrlState, getActiveParams, getDefaultParams } from '../../../utils/urlHelpers/listings';

import './styles.css';

import loadable, { lazy } from '@loadable/component';
/* istanbul ignore next */
const FilterClass = loadable(() => import('../../../components/RefineSearch/FilterClass'));
/* istanbul ignore next */
import FilterCondition from '../../../components/RefineSearch/FilterCondition';
/* istanbul ignore next */
const FilterEngineType = loadable(() => import('../../../components/RefineSearch/FilterEngineType'));
/* istanbul ignore next */
const FilterForSaleBy = lazy(() => import('../../../components/RefineSearch/FilterForSaleBy'));
/* istanbul ignore next */
const FilterFuelType = loadable(() => import('../../../components/RefineSearch/FilterFuelType'));
/* istanbul ignore next */
const FilterHullType = loadable(() => import('../../../components/RefineSearch/FilterHullType'));
/* istanbul ignore next */
import FilterLength from '../../../components/RefineSearch/FilterLength';
/* istanbul ignore next */
const FilterLocation = loadable(() => import('../../../components/RefineSearch/FilterLocation'));
/* istanbul ignore next */
const FilterMake = loadable(() => import('../../../components/RefineSearch/FilterMakeModel'), {
  resolveComponent: (components) => components.FilterMake
});
/* istanbul ignore next */
const FilterModels = loadable(() => import('../../../components/RefineSearch/FilterMakeModel'), {
  resolveComponent: (components) => components.FilterModels
});
/* istanbul ignore next */
import FilterPrice from '../../../components/RefineSearch/FilterPrice';
/* istanbul ignore next */
import FilterYear from '../../../components/RefineSearch/FilterYear';

/* istanbul ignore next */
const FilterModelRanges = loadable(() => import('../../../components/RefineSearch/FilterModelRanges'));

import { parseBoatsSRPFilters } from '../../../utils/parsers/refineSearchParsers';
import { yieldToMain } from '../../../utils/inp';


const showModelRanges = (selectedMake, modelRanges, makeModel) => {
  const normalizedMake = normalizeString(selectedMake);
  const selectedModels = makeModel ? makeModel[normalizedMake]?.length : 0;
  if (selectedMake && modelRanges.length > 0 && !selectedModels && normalizedMake !== 'other') {
    return true;
  }
  return false;
};

// We have a logic to deal with groups of facets in two levels of nesting in src/utils/twoLevelGroupFacets.js
// If we want a new group of facets, we need to add it there.
// We do not have the logic to make it recursive for n levels of nesting.
const useHullTypesGroup = (facets = {}) => {
  const getFiberglassGroup = memoizedFiberGlassGroup();
  const [hulltypes, setHullTypes] = useState([]);
  const {hullMaterial} = facets;
  const getHullTypes = (facets) => {
    let hullMaterialTypes = facets.hullMaterial || [];
    const sortedAndGroupedHullTypes = sortTwoLevelFacets(hullMaterialTypes);
    return sortedAndGroupedHullTypes;
  };
  const getSelectedHullTypes = (selectedHullTypes) => {
    const group = getFiberglassGroup(hulltypes);
    if (!group) {
      return selectedHullTypes;
    }
    group.selectFromTypes(selectedHullTypes);
    return group.addFiberGlassToHullTypes(selectedHullTypes);
  };
  useEffect(() => {
    setHullTypes(getHullTypes(facets));
  }, [hullMaterial]); // eslint-disable-line react-hooks/exhaustive-deps
  return {hulltypes, getFiberglassGroup, getSelectedHullTypes};
};

export const BoatsRefineSearch = (props) => {
  const {position, mobileParams, match = {}} = props;
  // const [prevMatchParams, setPrevMatchParams] = useState(position?.params);
  const {getSelectedHullTypes, getFiberglassGroup, hulltypes} = useHullTypesGroup(props.facets);
  const dispatch = useDispatch();
  const defaultSrpParams = useMemo(() => getDefaultParams({}), []);
  const [isInteractive, setIsInteractive] = useState(false);
  const [lazyLoadFilter, setLazyLoadFilter] = useState(false);
  const [shouldClearTypeAhead, setShouldClearTypeAhead] = useState(false);
  const [state, setState] = useState({
    data: {}
  });

  useEffect(() => {
    setCurrentState();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /*useEffect(() => {
    const isMobile = position === 'mobile';
    if (!isMobile && get(match.params, {}) !== get(prevMatchParams, {})) {
      setCurrentState();
    }
    setPrevMatchParams(match.params);
  }, [position, match.params, prevMatchParams]);*/

  const setCurrentState = () => {
    const params = currentStateInURL;
    setGroupedState({
      data: params
    });
    setLazyLoadFilter(true);
  };

  const handleDataChange = async (type, value, triggerProps = true) => {
    let newState = {
      data: {
        ...state.data,
        [type]: value
      }
    };
    setIsInteractive(true);
    if (type === 'makeModel') {
      newState.data.modelRange = undefined;
    }
    if (type === 'state' || type === 'city' || type === 'zip') {
      newState.data.radius = defaultSrpParams.radius;
    }
    if (type === 'state' || type === 'city') {
      newState.data.zip = defaultSrpParams.zip;
    }
    if (type === 'state') {
      newState.data.city = defaultSrpParams.city;
    }

    await yieldToMain();

    setGroupedState(newState);

    if (triggerProps) {
      props.onDataChange(newState.data);
    }
  };

  const handleDataClear = (triggerProps) => {
    let newState = {
      data: defaultSrpParams
    };
    setIsInteractive(true);
    setShouldClearTypeAhead(true);
    dispatch(setGenericEvent('site search', 'guided search', 'clear filters'));
    setGroupedState(newState);
    if (triggerProps) {
      props.onDataChange(newState.data);
    }
  };


  const setGroupedState = (newState) => {
    const groupedHullMaterial = newState.data.hullMaterial;
    if (groupedHullMaterial) {
      newState.data.hullMaterial = getSelectedHullTypes(groupedHullMaterial);
    }
    setState(newState);
  };

  // TODO: review why we need this
  const handleMultiDataChange = (newChanges, debounce) => {
    let newState = {
      data: {
        ...state.data,
        ...newChanges
      }
    };
    setIsInteractive(true);
    setGroupedState(newState);
    if (debounce) {
      debouncedOnRangeChange(newState.data);
    } else {
      props.onDataChange(newState.data);
    }
  };

  // TODO: review why we need this
  const handleDebouncedDataChange = (type, value) => {
    let newState = {
      data: {
        ...state.data,
        [type]: value
      }
    };
    setGroupedState(newState);
    debouncedOnRangeChange(newState.data);
  };

  const debouncedOnRangeChange = debounce((data) => {
    return props.onDataChange(data);
  }, DEBOUNCE_TIMEOUT);

  const facetAddedTracker = (facet) => {
    dispatch(setSearchTracking(facet, 'single', 'guided search'));
  };

  const facetRemovedTracker = (facet) => {
    dispatch(setGenericEvent('site search', 'guided search', facet));
  };

  const renderLoader = () => {
    return isInteractive && <></>;
  };

  const {currentStateInURL, currentParams} = useMemo(() => {
    const currentStateInURL = currentUrlState(match);
    const currentParams = getActiveParams(currentStateInURL);
    return {currentStateInURL, currentParams};
  }, [match]);

  const data = state.data;

  const {fuelTypeProps, priceProps, pageProps,
    selectedEngines, selectedForSale, classProps,
    locationProps, makeModelProps, keywordProps} = parseBoatsSRPFilters(props, currentStateInURL, currentParams, state);
  const {fuelTypes, selectedFuelTypes} = fuelTypeProps;
  const {price, priceRevised, selectedPriceRevisedSince} = priceProps;
  const {isMobile, isBranded, basePath, filterTagsTriggerProps, year, length} = pageProps;
  const { classfacets, multiFacetedBoatTypeClass } = classProps;
  const {stateCity, cityZip, states, country, selectedState, selectedCities, selectedZip} = locationProps;
  const {makes, models, selectedMake, modelRanges, makeModel, makeModelsForFilters, paramsWithoutModelRange} = makeModelProps;
  const tracking = { facetAdded: facetAddedTracker, facetRemoved: facetRemovedTracker};
  const selectedHullMaterials = data?.hullMaterial || (mobileParams || currentStateInURL).hullMaterial || '';
  const selectedHullTypes = getSelectedHullTypes(selectedHullMaterials);
  return (
    <>
      {!isMobile && !isBranded && <SearchAlerts
        active={currentParams}
        makes={makes}
        makeModels={makeModel}
        {...props}
      />}
      <SearchAlertsFilterTags
        active={(mobileParams || currentParams)}
        onClear={() => handleDataClear(filterTagsTriggerProps)}
        params={defaultSrpParams}
        handleDataChange={(type, value) => handleDataChange(type, value, filterTagsTriggerProps, true)}
        makes={makes}
        makeModels={makeModelsForFilters}
        modelRange={data.modelRange}
        isMobile={isMobile}
        searchPage={ props.searchPage }
        basePath={ basePath }
      />

      {!isBranded && <CollapsibleContent initialState={selectedZip || selectedState ? 'open' : 'closed'} testid="srp-filter-location" testsp="srp-filter-location">
        <CollapsibleHeader>
            Location
        </CollapsibleHeader>
        <Collapsible type="no-pad" doubleHeight>
          <FilterLocation
            loading={props.loading}
            position={position}
            radius={data.radius}
            zip={data.zip}
            state={data.state}
            states={states}
            cityZip={cityZip}
            country={country}
            handleMultiDataChange={handleMultiDataChange}
            params={currentStateInURL}
            selectedState={find(states, ['value', selectedState])}
            selectedCities={selectedCities}
            stateCity={stateCity}
            tracking={tracking}
          />
        </Collapsible>
      </CollapsibleContent>}

      {!isBranded && <CollapsibleContent initialState="open" extraClasses={['condition']} testid="srp-filter-condition" testsp="srp-filter-condition">
        <CollapsibleHeader>
            Condition
        </CollapsibleHeader>
        <Collapsible>
          <FilterCondition
            position={position}
            condition={data.condition}
            handleDataChange={handleDataChange}
            tracking={tracking}
          />
        </Collapsible>
      </CollapsibleContent>}
      <div className="keyword-override" data-testid="srp-filter-keyword" data-test-sp="srp-filter-keyword" data-e2e="srp-filter-keyword">
        <FilterSearch
          e2e="keyword"
          portal="bt"
          id="keywordFilter"
          value={decodeURIComponent(keywordProps.value)}
          onApplyValue={(value) => {
            const cleanedValue = value.replace(/[^A-Za-z0-9+%,. -]+/g, '');
            if (cleanedValue) {
              handleDataChange('keyword', cleanedValue);
            } else {
              handleDataChange('keyword', undefined);
            }
          }}
        />
      </div>
      <CollapsibleContent initialState="open" extraClasses={['length']} testid="srp-filter-length" testsp="srp-filter-length" >
        <CollapsibleHeader>
            Length
        </CollapsibleHeader>
        <Collapsible>
          <FilterLength
            handleDataChange={handleDebouncedDataChange}
            min={length.min}
            max={length.max}
            tracking={tracking} />
        </Collapsible>
      </CollapsibleContent>
      {!isBranded && <CollapsibleContent initialState="open" extraClasses={['year']} testid="srp-filter-year" testsp="srp-filter-year">
        <CollapsibleHeader>
            Year
        </CollapsibleHeader>
        <Collapsible>
          <FilterYear
            min={year.min}
            max={year.max}
            handleDataChange={handleDebouncedDataChange}
            tracking={tracking}
          />
        </Collapsible>
      </CollapsibleContent>}

      {!isBranded && <CollapsibleContent initialState="open" extraClasses={['price']} testid="srp-filter-price" testsp="srp-filter-price">
        <CollapsibleHeader>
            Price
        </CollapsibleHeader>
        <Collapsible>
          <FilterPrice
            min={price.min}
            max={price.max}
            priceRevised={priceRevised}
            selectedPriceRevisedSince={selectedPriceRevisedSince}
            handleDataChange={handleDebouncedDataChange}
            tracking={tracking}
          />
        </Collapsible>
      </CollapsibleContent>}

      <CollapsibleContent initialState={(!isEmpty(multiFacetedBoatTypeClass) ? 'open' : 'closed')} testid="srp-filter-boatType" testsp="srp-filter-boatType">
        <CollapsibleHeader>
            Boat Type
        </CollapsibleHeader>
        <Collapsible>
          <FilterClass
            facets={classfacets}
            handleDataChange={handleDataChange}
            multiFacetedBoatTypeClass={multiFacetedBoatTypeClass}
            params={currentStateInURL}
            position={position}
            shouldClearTypeAhead={shouldClearTypeAhead}
            setShouldClearTypeAhead={setShouldClearTypeAhead}
            tracking={tracking}
          />
        </Collapsible>
      </CollapsibleContent>

      {!isBranded && <CollapsibleContent initialState={(!isEmpty(makeModel) ? 'open' : 'closed')} testid="srp-filter-make" testsp="srp-filter-make">
        <CollapsibleHeader>
            Make
        </CollapsibleHeader>
        <Collapsible>
          <FilterMake
            makes={makes}
            makeModels={makeModel}
            position={position}
            loading={includes(props.componentWorking, 'models')}
            handleDataChange={handleDataChange}
            handleMultiDataChange={handleMultiDataChange}
            params={paramsWithoutModelRange}
            tracking={tracking}
          />
        </Collapsible>
      </CollapsibleContent>}

      {showModelRanges(selectedMake, modelRanges, data.makeModel) &&
          <CollapsibleContent initialState="open" testid="srp-filter-ranges" testsp="srp-filter-ranges">
            <CollapsibleHeader>
              {selectedMake} Ranges
            </CollapsibleHeader>
            <Collapsible>
              <FilterModelRanges
                loading={includes(props.componentWorking, 'modelRanges')}
                make={selectedMake}
                modelRanges={modelRanges}
                modelRange={data.modelRange}
                position={position}
                handleDataChange={handleDataChange}
                params={currentStateInURL}
                tracking={tracking}
              />
            </Collapsible>
          </CollapsibleContent>
      }

      <CollapsibleContent initialState={(!isEmpty(makeModel) ? 'open' : 'closed')} testid="srp-filter-makeModel" testsp="srp-filter-makeModel">
        <FilterModels
          makes={makes}
          makeModels={makeModelsForFilters}
          models={models}
          position={position}
          loading={includes(props.componentWorking, 'models')}
          handleMultiDataChange={handleMultiDataChange}
          params={paramsWithoutModelRange}
          tracking={tracking}
        />
      </CollapsibleContent>

      <CollapsibleContent initialState={(selectedFuelTypes.length ? 'open' : 'closed')} testid="srp-filter-fuelType" testsp="srp-filter-fuelType">
        <CollapsibleHeader>
            Fuel Type
        </CollapsibleHeader>
        <Collapsible>
          <FilterFuelType
            fuelTypes={fuelTypes}
            handleDataChange={handleDataChange}
            selectedFuelTypes={selectedFuelTypes}
            params={currentStateInURL}
            tracking={tracking}
          />
        </Collapsible>
      </CollapsibleContent>

      <CollapsibleContent initialState={(selectedHullTypes.length ? 'open' : 'closed')} testid="srp-filter-hullType" testsp="srp-filter-hullType">
        <CollapsibleHeader>
            Hull Type
        </CollapsibleHeader>
        <Collapsible>
          <FilterHullType
            hullTypes={hulltypes}
            handleDataChange={handleDataChange}
            selectedHullTypes={selectedHullTypes}
            getFiberglassGroup={getFiberglassGroup}
            params={currentStateInURL}
            tracking={tracking}
          />
        </Collapsible>
      </CollapsibleContent>

      <CollapsibleContent initialState={(selectedEngines ? 'open' : 'closed')} testid="srp-filter-engineType" testsp="srp-filter-engineType">
        <CollapsibleHeader>
            Engine Type
        </CollapsibleHeader>
        <Collapsible>
          <FilterEngineType
            engine={data.engine}
            position={position}
            handleDataChange={handleDataChange}
            params={currentStateInURL}
            tracking={tracking}
          />
        </Collapsible>
      </CollapsibleContent>

      {!isBranded && <CollapsibleContent initialState={(selectedForSale && selectedForSale !== 'all' ? 'open' : 'closed')} testid="srp-filter-forSale" testsp="srp-filter-forSale">
        <CollapsibleHeader>
            For Sale By
        </CollapsibleHeader>
        <Collapsible>
          {lazyLoadFilter && <Suspense fallback={renderLoader()}>
            <FilterForSaleBy
              position={position}
              selectedForSale={selectedForSale}
              handleDataChange={handleDataChange}
              params={currentStateInURL}
              tracking={tracking}
            />
          </Suspense>}
        </Collapsible>
      </CollapsibleContent>}
    </>
  );
};
export default BoatsRefineSearch;
