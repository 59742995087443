import get from 'lodash/get';
import { getBoatUrl } from '../../utils/urlHelpers/boat';
import * as actions from '../actions';
import * as types from '../constants';

export const setNextPrevious = (data) => {
  return {
    type: types.NEXT_PREVIOUS_DATA,
    data: data
  };
};

export const checkGetNextUrls = async (storage, nextPreviousData, lowIndex, highIndexOffset, url, related) => {
  const urls = nextPreviousData.urls;
  const numberOfUrls = urls?.length;
  let currentIndex = urls?.indexOf(url);
  let lastIndex = urls?.indexOf(nextPreviousData.lastUrl);

  if (currentIndex === numberOfUrls - highIndexOffset && lastIndex < currentIndex) {
    let nextPage = nextPreviousData.pageHigh + 1 || nextPreviousData.pageLow + 1;
    nextPreviousData.searchParams.page = nextPage;
    let nextData;
    let records = [];
    if (!related) {
      nextData = await actions.getNextPreviousData(nextPreviousData.searchParams);
      records = get(nextData, 'data.search.records', []);
    } else {
      nextData = await actions.getRelatedNextPreviousData(nextPreviousData.relatedListing, nextPage);
      records = get(nextData, 'data', []);
    }
    let newUrls = records.map(listing => {
      return getBoatUrl(listing);
    });
    if (numberOfUrls <= 29) {
      nextPreviousData.urls = [...urls, ...newUrls];
      nextPreviousData.pageHigh = nextPage;
    } else {
      nextPreviousData.urls = [...urls.splice(28, 28), ...newUrls];
      nextPreviousData.pageHigh = nextPage;
      nextPreviousData.pageLow = nextPage - 1;
    }
  }
  if (currentIndex === lowIndex && (lastIndex > currentIndex || lastIndex === -1)) {
    let nextPage = nextPreviousData.pageLow - 1;
    nextPreviousData.searchParams.page = nextPage;
    let nextData;
    let records = [];
    if (!related) {
      nextData = await actions.getNextPreviousData(nextPreviousData.searchParams);
      records = get(nextData, 'data.search.records', []);
    } else if (nextPage > 0) {
      nextData = await actions.getRelatedNextPreviousData(nextPreviousData.relatedListing, nextPage);
      records = get(nextData, 'data', []);
    }
    let newUrls = records.map(listing => {
      return getBoatUrl(listing);
    });
    if (numberOfUrls < 29) {
      nextPreviousData.urls = [...newUrls, ...urls];
      nextPreviousData.pageHigh = nextPage + 1;
      nextPreviousData.pageLow = nextPage;
    } else {
      nextPreviousData.urls.length = 28;
      nextPreviousData.urls = [...newUrls, ...urls];
      nextPreviousData.pageHigh = nextPage + 1;
      nextPreviousData.pageLow = nextPage;
    }
  }
  nextPreviousData.lastUrl = url;
  storage.setSessionItem('nextPreviousData', JSON.stringify(nextPreviousData));
};

export const getNextRelatedUrls = async (storage, nextPreviousData, listing) => {
  let nextData = await actions.getRelatedNextPreviousData(listing);
  let records = get(nextData, 'data', []);
  let newUrls = records.map((individualListing) => getBoatUrl(individualListing));
  nextPreviousData.urls = [...nextPreviousData.urls, ...newUrls];
  storage.setSessionItem('nextPreviousData', JSON.stringify(nextPreviousData));
};
